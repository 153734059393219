export interface Blog {
  en: ContentBlog;
  ar: ContentBlog;
  writenBy: string[];
  categories: string[];
  metaTags: IMetaTags;
}

export interface ContentBlog {
  title: string;
  body: string;
  image: IImage;
  slug: string;
  id?: string;
  createdAt?: string;
}

export enum SocialType {
  Twitter = "twitter",
  Facebook = "facebook",
}

export interface Social {
  socialNetwork: SocialType;
  description: string;
  title: string;
  image: Image;
}
export interface Image {
  alt: string;
  caption: string;
  description: string;
  title: string;
  url: string;
}
