// import { HomePage, SocialType } from "@/types/website/home";

export const initialAssessmentsPage: IPsychometricAssessmentsPage = {
  EnPsychometricAssessmentsPage: {
    title: "",
    paragraph: "",
    button: {
      text: "",
      link: "",
    },
    brief: {
      title: "",
      paragraph: "",
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    benfits: {
      title: "",
      description: "",
      paragraph: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
    centralTest: {
      title: "",
      paragraph: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    assessments: {
      title: "",
      paragraph: "",
    },
  },
  ArPsychometricAssessmentsPage: {
    title: "",
    paragraph: "",
    button: {
      text: "",
      link: "",
    },
    brief: {
      title: "",
      paragraph: "",
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    benfits: {
      title: "",
      description: "",
      paragraph: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
    centralTest: {
      title: "",
      paragraph: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    assessments: {
      title: "",
      paragraph: "",
    },
  },
};

export default initialAssessmentsPage;
