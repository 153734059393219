<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import { GET_PARTNERS } from "@/lib/graphql/queries/partners";
import { useMutation, useLazyQuery } from "@vue/apollo-composable";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import { DELETE_PARTNER } from "@/lib/graphql/mutations/partners";
import { usePagination } from "@/lib/pagination";

import { useLocalize } from "@/locale/i18n";

import {
  columns,
  rows,
  actionsDropdown,
  filterFields,
  toolbar,
} from "./tableConfig";

// Localization
const { isRTL, i18n, language } = useLocalize();

type PartnerApi = {
  listPartners: {
    partner: IPartnersPage[];
    totalRecords: number;
  };
};

const {
  loading: fetchLoading,
  result,
  refetch,
  load,
} = useLazyQuery<PartnerApi>(GET_PARTNERS, null, {
  fetchPolicy: "no-cache",
  notifyOnNetworkStatusChange: true,
});

const { getPaginationVariables, pagination } = usePagination<PartnerApi>(
  result,
  "listPartners",
  refetch
);

load(null, { pagination: getPaginationVariables() });

const { mutate: deletePartner, loading: deleteLoading } =
  useMutation(DELETE_PARTNER);

const loading = computed<boolean>(
  () => fetchLoading.value || deleteLoading.value
);

const partners = computed<Object[]>(() => {
  const EnPartners = result.value?.listPartners.partner.map((partner) => {
    return { id: partner.id, ...partner.EnPartnersPage };
  });

  const ArPartners = result.value?.listPartners.partner.map((partner) => {
    return { id: partner.id, ...partner.ArPartnersPage };
  });

  if (language.value === "en") {
    return EnPartners;
  } else {
    return ArPartners;
  }
});

const handleDelete = async (id: string) => {
  await deletePartner({ id });
  await refetch();
};

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.website-partners");
});
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <!-- Begin Faqs -->
        <!--services Title En and Ar -->

        <data-list
          tableTitle="routes.partners"
          createLink="website-partners-new"
          :rows="partners"
          :columns="columns"
          :loading="loading"
          :withPagination="true"
          :pagination="pagination"
          class="!px-0 !mt-0"
        >
          <!-- <template #deleteAll="{ selected }">
            <div @click="selected" class="flex items-center gap-2">
              <Icon icon="solar:trash-bin-trash-broken" class="w-4 h-4" />{{
                $t("common.delete")
              }}
            </div>
          </template> -->
          <template #default="{ row }">
            <!-- <h1>{{ row?.id }} row id</h1> -->
            <router-link
              class="flex items-center gap-2 whitespace-nowrap"
              :to="`website-partners/${row?.id}`"
            >
              <Icon icon="solar:square-top-down-broken" class="h-5 w-5" />
              <!-- Open -->
            </router-link>

            <DeleteModal @delete="handleDelete(row?.id)" />
          </template>
        </data-list>

        <!-- End Faqs -->
      </div>

      <!-- END: Form Layout -->
    </div>
  </div>
</template>
