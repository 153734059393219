import gql from "graphql-tag";
// import { ClientsFragment } from "../fragments/client-fragments";
import { PartnerPageFragment } from "../fragments/partners-fragments";

//

export const UPDATE_PARTNER = gql`
  mutation UPDATE_PARTNER($data: PartnersPageInput!, $id: String) {
    updatePartnerPage(data: $data, id: $id) {
      ...PartnerPageFragment
    }
  }
  ${PartnerPageFragment}
`;

export const DELETE_PARTNER = gql`
  mutation DELETE_PARTNER($id: String!) {
    deletePartner(id: $id)
  }
`;
