<script setup lang="ts">
import Button from "@/base-components/Button";
import { useLocalize } from "@/locale/i18n";

import Tab from "@/base-components/Headless/Tab";
import LoadingSpinner from "@/components/Loading";
import ServiceRecruitmentHeroParagraph from "./Components/ServiceRecruitmentHeroParagraph.vue";
import ServiceRecruitmentLocal from "./Components/ServiceRecruitmentLocal.vue";
import ServiceRecruitmentOverSeas from "./Components/ServiceRecruitmentOverSeas.vue";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { GET_RECRUITMENT_PAGE } from "@/lib/graphql/queries/recruitment";
import { SET_RECRUITMENT } from "@/lib/graphql/mutations/recruitment";
import { initialRecruitmentPage } from "./initialServiceRecruitment";
import { toast } from "vue-sonner";
// Localization
const { isRTL, i18n, language } = useLocalize();

const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IRecruitmentObject>(() =>
  lang.value === "ar" ? "ArRecruitmentPage" : "EnRecruitmentPage"
);

type RecruitmentResult = {
  getRecruitmentPage: IRecruitmentPage;
};

// Fetch HomePage
const { result: recruitmentPage, loading: getRecruitmentLoading } =
  useQuery<RecruitmentResult>(GET_RECRUITMENT_PAGE, null, {
    fetchPolicy: "no-cache",
  });

const recruitmentPageData = ref<IRecruitmentPage>(initialRecruitmentPage);
watchEffect(() => {
  if (recruitmentPage.value) {
    recruitmentPageData.value = {
      ...recruitmentPageData.value,
      ...recruitmentPage.value.getRecruitmentPage,
    };
  }
});

provide("recruitmentPageData", recruitmentPageData);
provide("objAccessor", objAccessor);

// Submit Data
const { mutate: setRecruitmentPage, loading: setRecruitmentLoading } =
  useMutation(SET_RECRUITMENT);
const handleSubmit = async () => {
  const data = { ...recruitmentPageData.value };
  delete data.metaTags;
  window.scrollTo({
    top: 120,
    behavior: "smooth",
  });
  try {
    await setRecruitmentPage({ data });
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    toast.error(error.message);
  }
};

const loading = computed(
  () => setRecruitmentLoading.value || getRecruitmentLoading.value
);

onMounted(async () => {
  document.title =
    i18n("routes.app") + " | " + i18n("routes.services-recruitment");
});
watch(
  isRTL,
  () =>
    (document.title =
      i18n("routes.app") + " | " + i18n("routes.services-recruitment"))
);
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->

      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <!-- Hero section title -->
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-base font-medium">
            {{ $t("website.services.recruitment.title") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("website.services.recruitment.description") }}
          </p>
        </div>
        <!-- end of  hero section title -->

        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>

        <div
          class="h-screen w-full flex items-center justify-center absolute"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>

        <ServiceRecruitmentHeroParagraph />

        <ServiceRecruitmentLocal />

        <ServiceRecruitmentOverSeas />

        <div class="mt-20 justify-end flex gap-2">
          <!-- <Button type="button" variant="outline-secondary" class="w-24 mr-1">
            {{ $t("common.cancel") }}
          </Button> -->
          <Button
            type="button"
            variant="primary"
            class="w-24"
            :disabled="loading"
            :isLoading="loading"
            @click="handleSubmit"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
