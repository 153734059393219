<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";
import Button from "@/base-components/Button";

const partnersPageData =
  inject<ComputedRef<IPartnersPage>>("partnersPageData")!;

const objAccessor = inject<ComputedRef<IPartnersObject>>("objAccessor")!;
</script>

<template>
  <section>
    <div>
      <h2 class="text-lg mt-12 mb-4 font-medium">
        {{ $t("common.socialLinks") }}
        <span class="text-slate-500 text-[11px] mx-1">
          {{ $t("common.optional") }}
        </span>
      </h2>
    </div>

    <div class="grid-cols-2 gap-3 my-3 sm:grid">
      <div>
        <FormLabel class="font-medium" htmlFor="crud-form-1">
          {{ $t("common.facebook") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="partnersPageData.socialLinks.facebook"
        />
      </div>
      <div>
        <FormLabel class="font-medium" htmlFor="crud-form-1">
          {{ $t("common.twitter") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="partnersPageData.socialLinks.twitter"
        />
      </div>
    </div>

    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium" htmlFor="crud-form-1">
          {{ $t("common.instagram") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="partnersPageData.socialLinks.instagram"
        />
      </div>
      <div>
        <FormLabel class="font-medium" htmlFor="crud-form-1">
          {{ $t("common.linkedin") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="partnersPageData.socialLinks.linkedin"
        />
      </div>
    </div>

    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium" htmlFor="crud-form-1">
          {{ $t("common.website") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="partnersPageData.socialLinks.website"
        />
      </div>
    </div>
  </section>
</template>
