<template>
    <div class="w-full flex flex-col  mb-6">
        <div v-for="n in 7" :key="n" class="py-4 border-t border-t-slate-200 flex gap-5">
            <div class="skeleton-loader min-w-[16px] min-h-[16px] rounded-md"></div>
            <div class="skeleton-loader w-full h-[15px] rounded-md"></div>
            <div class="skeleton-loader w-full h-[15px] rounded-md"></div>
        </div>
    </div>
</template>

<style scoped>
/* Define loader colors as variables */

.skeleton-loader {
    background: linear-gradient(90deg,
            hsl(210, 15%, 88%),
            hsl(210, 15%, 95%),
            hsl(210, 15%, 88%));
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}
</style>
