import gql from "graphql-tag";
import { recruitmentPageFragment } from "../fragments/recruitment-fragments";

export const GET_RECRUITMENT_PAGE = gql`
  query {
    getRecruitmentPage {
      ...recruitmentPageFragment
    }
  }
  ${recruitmentPageFragment}
`;
