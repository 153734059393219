import gql from "graphql-tag";
import { salarySurveyPageFragment } from "../fragments/salarySurvey-fragments";

export const GET_SALARYSURVEY = gql`
  query GetSalarySurveyPage {
    getSalarySurveyPage {
      ...salarySurveyPageFragment
    }
  }
  ${salarySurveyPageFragment}
`;
