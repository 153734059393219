import gql from "graphql-tag";
import { recruitmentPageFragment } from "../fragments/recruitment-fragments";

export const SET_RECRUITMENT = gql`
  mutation UPDATE_RECRUITMENT($data: RecruitmentPageInput!) {
    updateRecruitmentPage(data: $data) {
      ...recruitmentPageFragment
    }
  }
  ${recruitmentPageFragment}
`;
