import {
  changeMyPassword,
  fetchLocalCurrentUser,
  fetchMe,
  onAuthChanged,
  saveLocalCurrentUser,
  sendPasswordResetMail,
  signinWithEmailAndPassword,
  signout,
} from "@/stores/auth/auth-service";
import { toast } from "vue-sonner";
import { t } from "@/locale/i18n";
import { defineStore } from "pinia";
import router from "@/router";
import firebaseInit from "@/lib/firebase/firebase-init";
import { User } from "firebase/auth";

export const useAuthStore = defineStore("auth", {
  state: (): IAuthState => ({
    authenticationUser: null,
    currentUser: null,
    loadingInit: true,
    rememberMe: false,
    loadingEmailConfirmation: false,
    loadingPasswordResetEmail: false,
    loadingUpdateProfile: false,
    loadingChangeMyPassword: false,
    loading: false,
  }),
  getters: {
    currentUserEmail: (state) =>
      state.currentUser ? state.currentUser.email : null,
    currentUserFullName: (state) =>
      state.currentUser ? state.currentUser.fullName : "",
    signedIn: (state) => !!state.currentUser && !!state.currentUser.id,
    role: (state) =>
      state.currentUser ? state.currentUser.role || null : null,
    currentUserAvatar: (state) => {
      if (!state.currentUser || !state.currentUser.profilePic) {
        return null;
      }
      return state.currentUser.profilePic;
    },
  },
  actions: {
    async doInit() {
      firebaseInit();
      const currentUser = fetchLocalCurrentUser();
      if (!currentUser) {
        this.authenticationUser = null;
        this.currentUser = null;
        this.loadingInit = false;
        return;
      }
      onAuthChanged(
        (authenticationUser: User | null) => {
          this.doSigninFromAuthChange(authenticationUser);
        },
        () => {
          this.authenticationUser = null;
          this.currentUser = null;
          this.loadingInit = false;
        }
      );
    },
    async doWaitUntilInit() {
      if (!this.loadingInit) {
        return Promise.resolve();
      }
      return new Promise<void>((resolve) => {
        const waitUntilInitInterval = setInterval(() => {
          if (!this.loadingInit) {
            clearInterval(waitUntilInitInterval);
            resolve();
          }
        }, 500);
      });
    },
    async doSigninFromAuthChange(authenticationUser: User | null) {
      try {
        const currentUser = await fetchMe();
        saveLocalCurrentUser(currentUser);
        this.authenticationUser = authenticationUser;
        this.currentUser = currentUser;
        this.loadingInit = false;
      } catch (error) {
        await signout();
        this.authenticationUser = null;
        this.currentUser = null;
        this.loadingInit = false;
      }
    },
    async doSigninWithEmailAndPassword({
      email,
      password,
      rememberMe,
    }: ILogin) {
      try {
        this.loading = true;
        let authenticationUser = null;
        let currentUser = null;
        const credentials = await signinWithEmailAndPassword({
          email,
          password,
          rememberMe,
        });
        if (credentials && credentials.user) {
          authenticationUser = credentials.user;
          currentUser = await fetchMe();
        }
        this.authenticationUser = authenticationUser || null;
        this.currentUser = currentUser || null;
        this.rememberMe = rememberMe || false;
        this.loading = false;
        saveLocalCurrentUser(currentUser);
        router.push("/");
      } catch (error) {
        toast.error(t("auth.invalidCredintials"));
        await signout();
        this.authenticationUser = null;
        this.currentUser = null;
        this.loading = false;
      }
    },
    async doSendPasswordResetEmail(email: string) {
      try {
        this.loadingPasswordResetEmail = true;
        await sendPasswordResetMail(email);
        toast.success(t("auth.passwordResetEmailSuccess"));
        this.loadingPasswordResetEmail = false;
      } catch (error) {
        this.loadingPasswordResetEmail = false;
      }
    },
    async doRefreshCurrentUser() {
      try {
        const currentUser = await fetchMe();
        saveLocalCurrentUser(currentUser);
        this.currentUser = currentUser || null;
      } catch (error) {
        await signout();
      }
    },
    async doChangeMyPassword({ oldPassword, newPassword }: IChangePassword) {
      try {
        this.loadingChangeMyPassword = true;
        await changeMyPassword(oldPassword, newPassword);
        const email = this.currentUser?.email;
        const rememberMe = this.rememberMe;
        if (email) {
          await signinWithEmailAndPassword({
            email,
            password: newPassword,
            rememberMe,
          });
          toast.success(t("auth.passwordChangedSuccessfully"));
          return true;
        }
      } catch (error: any) {
        toast.error(error.message);
        return false;
      } finally {
        this.loadingChangeMyPassword = false;
      }
    },
    async doSignout() {
      try {
        this.loading = true;
        await signout();
        localStorage.clear();
        this.authenticationUser = null;
        this.currentUser = null;
        this.rememberMe = false;
        this.loading = false;
        router.push({
          name: "login-page",
        });
      } catch (error) {
        this.authenticationUser = null;
        this.currentUser = null;
        this.loading = false;
      }
    },
  },
});
