import { getApps } from "firebase/app";
import { getAuth, getIdToken } from "firebase/auth";
// import { appCheck } from "@/lib/firebase/firebase-init";
// import { getToken } from "firebase/app-check";

export class AuthToken {
  static async get() {
    const currentUser = getAuth().currentUser;
    if (getApps().length && currentUser) {
      return await getIdToken(currentUser, false);
    }
    return null;
  }

  // static async getAppCheckToken() {
  //   try {
  //     if (appCheck) return await getToken(appCheck, true);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
}
