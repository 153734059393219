<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import FileUploader from "@/base-components/FileUploader";

const image = defineModel<IImage>({ required: true, default: {} });

type Props = {
  acceptedFiles?: string;
  isOptional?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  acceptedFiles: "image/*",
  isOptional: false,
});
</script>

<template>
  <div class="bg-slate-100 p-4 rounded-md">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-700 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.title") }}
          <span v-if="isOptional" class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.optional") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="image.title"
        />
      </div>
      <div>
        <FormLabel
          class="!text-slate-700 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.alt") }}
          <span v-if="isOptional" class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.optional") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="image.alt"
        />
      </div>
    </div>
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-700 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.caption") }}
          <span v-if="isOptional" class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.optional") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="image.caption"
        />
      </div>
      <div>
        <FormLabel
          class="!text-slate-700 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.description") }}
          <span v-if="isOptional" class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.optional") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="image.description"
        />
      </div>
    </div>
    <FileUploader
      v-model="image.url"
      storagePath="SeoImages"
      :acceptedFiles="acceptedFiles"
    />
  </div>
</template>

<style scoped>
:deep(.dropzone .dz-preview.dz-image-preview) {
  border-radius: 5px;
  background: transparent;
  /* color: white !important; */
}

:deep(.dropzone .dz-preview .dz-image) {
  display: flex;
  padding: 10px;
}

:deep(.dropzone .dz-preview .dz-image img) {
  object-fit: contain !important;
}

:deep(.dropzone .dz-message) {
  margin: 1.5rem 0px;
}

:deep(.dropzone) {
  border-color: slategray !important;
  display: flex;
  justify-content: center;
}
</style>
