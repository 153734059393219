import gql from "graphql-tag";
import { MetaTagsComponent } from "../fragments/seo-fragments";

export const GET_META_TAGS = gql`
  query {
    getMetaTags {
      ...MetaTagsComponent
    }
  }
  ${MetaTagsComponent}
`;

export const GET_META_TAG = gql`
  query GET_META_TAG($id: String!) {
    getMetaTag(id: $id) {
      ...MetaTagsComponent
    }
  }
  ${MetaTagsComponent}
`;
