import gql from "graphql-tag";
import { HRPageFragment } from "../fragments/HRPage-fragment";

export const GET_HR = gql`
  query {
    getHRPage {
      ...HRPageFragment
    }
  }
  ${HRPageFragment}
`;
