const firebaseConfig = {
  apiKey: "AIzaSyADk74LxDuwopOGmGeYHm7mzylMORL1zFc",
  authDomain: "tawzef-ecd44.firebaseapp.com",
  projectId: "tawzef-ecd44",
  storageBucket: "tawzef-ecd44.appspot.com",
  messagingSenderId: "641472732301",
  appId: "1:641472732301:web:346f9df63800e628cd56c4",
  measurementId: "G-5X42S5QP7K",
};

export default {
  firebaseConfig
};
