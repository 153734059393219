<script setup lang="ts">
import { GET_CLIENT } from "@/lib/graphql/queries/clients";
import { useLocalize } from "@/locale/i18n";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { computed, onMounted, watch, ref } from "vue";
import { createNewClient } from "./client";
import LoadingSpinner from "@/components/Loading";
import Button from "@/base-components/Button";
import { CREATE_CLIENT, UPDATE_CLIENT } from "@/lib/graphql/mutations/clients";
import { useRouter } from "vue-router";
import { toast } from "vue-sonner";

// Localization
const { isRTL, i18n } = useLocalize();

const props = defineProps<{ id: string }>();

const isEditing = computed<boolean>(() => !!props.id);

const router = useRouter();

type ClientApi = {
  getClient: IClient;
};

const client = ref(createNewClient());
let getClientLoading = ref(false);

if (isEditing.value) {
  const { result, loading } = useQuery<ClientApi>(
    GET_CLIENT,
    { id: props.id },
    {
      fetchPolicy: "no-cache",
    }
  );
  getClientLoading = loading;
  watch(
    () => result.value,
    (res) => {
      if (res?.getClient) {
        client.value = res.getClient;
      }
    }
  );
}

const { mutate: updateClient, loading: setClientLoading } =
  useMutation(UPDATE_CLIENT);
const { mutate: createClient, loading: createClientLoading } =
  useMutation(CREATE_CLIENT);
const handleSubmit = async () => {
  const data = { image: client.value.image };
  try {
    isEditing.value
      ? await updateClient({ data, id: props.id })
      : await createClient({ data });
    toast.success(i18n("common.updated"));
    router.push({ name: "website-clients" });
  } catch (error: any) {
    toast.error(error.message);
  }
};

const loading = computed<boolean>(
  () =>
    getClientLoading.value ||
    setClientLoading.value ||
    createClientLoading.value
);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.website-clients");
});
watch(
  isRTL,
  () =>
    (document.title =
      i18n("routes.app") + " | " + i18n("routes.website-clients"))
);
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <div class="p-5 intro-y box w-full">
        <div
          class="h-full w-full flex items-center justify-center absolute z-50"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>
        <div class="mt-4">
          <SeoImageInput v-model="client.image" />
        </div>
        <div class="mt-5 text-right">
          <Button
            type="button"
            variant="outline-secondary"
            class="w-24 mr-1"
            @click="$router.push({ name: 'website-clients' })"
          >
            {{ $t("common.cancel") }}
          </Button>
          <Button
            type="button"
            variant="primary"
            class="w-24"
            :disabled="loading"
            @click="handleSubmit"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
