<script setup lang="ts">
import TomSelect from "@/base-components/TomSelect";
import { inject, ComputedRef, computed } from "vue";
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { useQuery } from "@vue/apollo-composable";
import { GET_CLIENTS } from "@/lib/graphql/queries/clients";

const { i18n } = useLocalize();

const homePageData = inject<ComputedRef<IHomePage>>("homePageData")!;

const objAccessor = inject<ComputedRef<IHomeObject> | IHomeObject>(
  "objAccessor",
  "EnHomePage"
);

type ClientApi = {
  getClients: {
    client: IClient[];
    totalRecords: number;
  };
};

const { loading, result } = useQuery<ClientApi>(GET_CLIENTS, null, {
  fetchPolicy: "no-cache",
});

const placeholder = computed<string>(() =>
  i18n("website.home.ourClients.fields.selectClients")
);

const clientsList = computed<IClient[]>(() => result.value?.getClients?.client || []);
</script>

<template>
  <div class="my-20">
    <div class="mb-3 intro-y pb-4 mt-10">
      <h2 class="text-base font-medium">
        {{ $t("website.home.ourClients.title") }}
      </h2>

      <p class="text-slate-400 text-xs mt-1 font-normal">
        {{ $t("website.home.ourClients.description") }}
      </p>
    </div>
    <div>
      <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
        {{ $t("website.home.ourClients.fields.title") }}</FormLabel
      >
      <FormInput
        id="crud-form-1"
        type="text"
        class="w-full"
        v-model="homePageData[objAccessor].ourCustomersTitle"
      />
    </div>
    <div class="mt-3">
      <TomSelect
        v-model="homePageData[objAccessor].ourCustomers"
        :options="{
          placeholder,
        }"
        class="w-full"
        multiple
        :create="false"
      >
        <option
          disabled
          value="loading"
          v-if="loading"
          style="text-align: center"
        >
          Loading...
        </option>
        <template v-else>
          <option
            v-for="client in clientsList"
            :key="client.id"
            :value="client.id"
          >
            {{ client.image.title }}
          </option>
        </template>
      </TomSelect>
    </div>
  </div>
</template>
