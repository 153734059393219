import gql from "graphql-tag";
import { BlogFragment } from "../fragments/blog-fragments";

export const GET_BLOG = gql`
  query GET_BLOG($blogId: String!) {
    getBlog(blogId: $blogId) {
      ...BlogFragment
    }
  }
  ${BlogFragment}
`;

export const GET_BLOGS = gql`
  query GET_BLOGS($pagination: AlgoliaPagination, $filters: AlgoliaFilters) {
    getBlogs(pagination: $pagination, filters: $filters) {
      totalRecords
      blog {
        ...BlogFragment
      }
    }
  }
  ${BlogFragment}
`;
