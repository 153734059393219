export interface FAQPage {
  ArFAQPage: ContentFAQPage;
  EnFAQPage: ContentFAQPage;
  metaTags: IMetaTags;
}

export type FaqObject = "ArFAQPage" | "EnFAQPage";

export interface ContentFAQPage {
  title: string;
  paragraph: string;
}

export interface CategoriesWithTotalRecords {
  totalRecords: number;
  category: Categories[];
}

export interface Categories {
  category: string;
  slug: string;
  id: string;
}

export enum SocialType {
  Twitter = "twitter",
  Facebook = "facebook",
}

export interface Social {
  socialNetwork: SocialType;
  description: string;
  title: string;
  image: Image;
}
export interface Image {
  alt: string;
  caption: string;
  description: string;
  title: string;
  url: string;
}
