import gql from "graphql-tag";
import { ServicesPageFragment } from "../fragments/serviceMain-fragment";

export const GET_SERVICES = gql`
  query {
    getServicesPage {
      ...ServicesPageFragment
    }
  }
  ${ServicesPageFragment}
`;
