<script setup lang="ts">
import { computed, ref } from "vue";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Alignment,
  Font,
  List,
  Heading,
  Base64UploadAdapter,
  Link,
  Table,
  BlockQuote,
  Image, // Add Image plugin
  ImageToolbar,
  ImageUpload, // Add Image upload plugin
} from "ckeditor5";

interface CkeditorProps {
  config?: any;
  as?: string | object;
  disabled?: boolean;
  refKey?: string;
}

const content = defineModel<string>("modelValue", {
  required: true,
});

const props = withDefaults(defineProps<CkeditorProps>(), {
  config: {},
});

const editor = ref(ClassicEditor);

const defaultConfig = ref({
  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  plugins: [
    Bold,
    Essentials,
    Italic,
    Mention,
    Paragraph,
    Undo,
    Alignment,
    Font,
    Heading,
    List,
    Base64UploadAdapter,
    Link,
    Table,
    BlockQuote,
    Image, // Add Image plugin here
    ImageToolbar,
    ImageUpload,
  ],
  toolbar: [
    "undo",
    "redo",
    "|",
    "heading",
    "|",
    "fontSize",
    "fontFamily",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "alignment",
    "font",
    "list",
    "|",
    "link",
    "insertTable",
    "|",
    "bulletedList",
    "numberedList",
    "blockQuote",
    "imageUpload", // Add image upload button to the toolbar
  ],
});

const editorConfig = computed(() => ({
  ...defaultConfig.value,
  ...props.config,
}));
</script>

<template>
  <ckeditor
    :editor="editor"
    v-model="content"
    :config="editorConfig"
  ></ckeditor>
</template>


<style>
.ck-content a {
  color: red;
}
</style>