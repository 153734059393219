<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useLocalize } from "@/locale/i18n";
import { columns, actionsDropdown } from "./tableConfig";
import Button from "@/base-components/Button";
import AddNewMember from "@/components/Modals/AddNewMember.vue";
import { useLazyQuery, useMutation } from "@vue/apollo-composable";
import { GET_MEMBERS } from "@/lib/graphql/queries/members";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import { DELETE_MEMBER, TOGGLE_MEMBER } from "@/lib/graphql/mutations/members";
import { toast } from "vue-sonner";

import { useAuthStore } from "@/stores/auth/auth-store";
import { storeToRefs } from "pinia";

const { currentUser } = storeToRefs(useAuthStore());
console.log(currentUser, "its Current User");

// Localization
const { isRTL, i18n } = useLocalize();

type MemberApi = {
  getMembers: {
    member: IMember[];
    totalRecords: number;
  };
};

const {
  loading: fetchLoading,
  result,
  refetch,
  load,
} = useLazyQuery<MemberApi>(GET_MEMBERS, null, {
  fetchPolicy: "no-cache",
  notifyOnNetworkStatusChange: true,
});

load();
// load(null, { pageSize: 100000, doc: {}, filters: {}, pagination: getPaginationVariables() });

const members = computed<IMember[]>(
  () => result.value?.getMembers?.member || []
);

const { mutate: deleteMember, loading: deleteLoading } =
  useMutation(DELETE_MEMBER);

const { mutate: toggleMember, loading: toggleLoading } =
  useMutation(TOGGLE_MEMBER);

const handleDelete = async (ids: string[]) => {
  try {
    await deleteMember({ data: { memberIds: ids } });
    await refetch();
    toast.success(i18n("members.memberDeleted"));
  } catch (err: any) {
    toast.error(err.message);
  }
};

const handletoggle = async (id: string) => {
  await toggleMember({ memberId: id });
  await refetch();
};

const loading = computed<boolean>(
  () => fetchLoading.value || deleteLoading.value || toggleLoading.value
);

// MOUNTED
onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.members");
});

watch(
  isRTL,
  () => (document.title = i18n("routes.app") + " | " + i18n("routes.members"))
);
</script>

<template>
  <!-- BEGIN: TABLE LAYOUT -->
  <data-list
    tableTitle="routes.members"
    :rows="members"
    :columns="columns"
    :loading="loading"
    :multiSelect="true"
    :actionsDropdown="actionsDropdown"
    class="mt-6"
  >
    <template #deleteAll="{ selected }">
      <div @click="handleDelete(selected)" class="flex items-center gap-2">
        <Icon icon="solar:trash-bin-2-bold" class="w-4 h-4" />{{
          $t("common.delete")
        }}
      </div>
    </template>
    <template #costumeCreateNew>
      <AddNewMember @added:success="refetch()" />
    </template>
    <template #default="{ row }">
      <router-link
        class="flex items-end gap-2 whitespace-nowrap"
        :to="`members/${row?.id}`"
      >
        <Icon icon="solar:pen-new-square-broken" class="h-5 w-5" />
      </router-link>
      <button
        v-if="row?.isEnabled"
        @click="handletoggle(row?.id)"
        class="flex items-end gap-2 whitespace-nowrap"
      >
        <Icon icon="tabler:user-x" class="h-5 w-5" />
      </button>
      <button
        v-else-if="!row?.isEnabled"
        @click="handletoggle(row?.id)"
        class="flex items-end gap-2 whitespace-nowrap"
      >
        <Icon icon="tabler:user-check" class="h-5 w-5" /></button
      ><DeleteModal @delete="handleDelete([row?.id])" />
    </template>
  </data-list>
  <!-- END: TABLE LAYOUT -->
  <!-- BEGIN: Pagination -->

  <!-- END: Pagination -->
  <!-- BEGIN: Modal Content -->
  <!-- <DeleteModal @delete="
    doDestroy(selectedId);
  showDeleteModal = false;
  " @close="showDeleteModal = false" :show="showDeleteModal" /> -->
  <!-- END: Modal Content -->
</template>
