import gql from "graphql-tag";
import {
  ButtonFragment,
  ImageFragment,
  MetaTagsFragment,
  StatisticsFragment,
  CardsFragment,
} from "./base-fragments";

export const HRGapAnalysisFragment = gql`
  fragment HRGapAnalysisFragment on HRGapAnalysis {
    title
    description
    paragraphs
    image {
      ...ImageFragment
    }
    buttons {
      ...ButtonFragment
    }
  }
  ${ButtonFragment}
  ${ImageFragment}
`;

export const HRBusinessStrategiesFragment = gql`
  fragment HRBusinessStrategiesFragment on HRBusinessStrategies {
    title
    description
    paragraphs
    buttons {
      ...ButtonFragment
    }
  }
  ${ButtonFragment}
`;

export const HRManagmentSystemFragment = gql`
  fragment HRManagmentSystemFragment on HRManagmentSystem {
    title
    description
    paragraphs
    ending
    buttons {
      ...ButtonFragment
    }
    image {
      ...ImageFragment
    }
  }
  ${ButtonFragment}
  ${ImageFragment}
`;

export const AssessmentCenterFragment = gql`
  fragment AssessmentCenterFragment on AssessmentCenter {
    title
    description
    paragraphs
    buttons {
      ...ButtonFragment
    }
  }
  ${ButtonFragment}
`;

export const ContentHRPageFragment = gql`
  fragment ContentHRPageFragment on ContentHRPage {
    title
    paragraph
    button {
      ...ButtonFragment
    }
    gapAnalysis {
      ...HRGapAnalysisFragment
    }
    businessStrategies {
      ...HRBusinessStrategiesFragment
    }
    managmentSystem {
      ...HRManagmentSystemFragment
    }
    assessmentCenter {
      ...AssessmentCenterFragment
    }
  }
  ${ButtonFragment}
  ${HRGapAnalysisFragment}
  ${HRBusinessStrategiesFragment}
  ${HRManagmentSystemFragment}
  ${AssessmentCenterFragment}
`;

export const HRPageFragment = gql`
  fragment HRPageFragment on HRPage {
    metaTags {
      ...MetaTagsFragment
    }
    EnHRPage {
      ...ContentHRPageFragment
    }
    ArHRPage {
      ...ContentHRPageFragment
    }
  }
  ${MetaTagsFragment}
  ${ContentHRPageFragment}
`;
