<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";
import FileUploader from "@/base-components/FileUploader";

import  ClassicEditor  from "@/base-components/Ckeditor/ClassicEditor.vue";
import Dropzone from "@/base-components/Dropzone";
import DraggableList from "@/base-components/DraggableList";

const { i18n } = useLocalize();

const contactUsData = inject<ComputedRef<IContactUsPage>>("contactUsData")!;

const objAccessor = inject<ComputedRef<IContactUsObject>>("objAccessor")!;

const handleAddItem = () => {
  // reviews.value.push({});
  contactUsData.value[objAccessor.value].contactMethods.push({
    title: "",
    paragraphs: [""],
    image: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
  });
};
const handleRemoveItem = (index: number) => {
  contactUsData.value[objAccessor.value].contactMethods.splice(index, 1);
};

const handleAddParagraph = (parentIndex: number) => {
  contactUsData.value[objAccessor.value].contactMethods[
    parentIndex
  ].paragraphs.push("");
};

const handleRemoveParagraph = (parentIndex: number, childIndex: number) => {
  contactUsData.value[objAccessor.value].contactMethods[
    parentIndex
  ].paragraphs.splice(childIndex, 1);
};
</script>

<template>
  <div class="mt-4">
    <h4 class="font-medium">
      {{ $t("website.contactUs.contacts.title") }}
    </h4>
    <DraggableList
      class="mt-4"
      @removeItem="handleRemoveItem"
      @addItem="handleAddItem"
      :theArray="contactUsData[objAccessor].contactMethods"
    >
      <template v-slot="{ index: parentIndex }">
        <div class="w-full">
          <div class="grid-cols-2 gap-3 mt-2 sm:grid">
            <div>
              <FormLabel
                class="!text-slate-400 text-xs font-medium mt-2"
                htmlFor="crud-form-1"
              >
                {{ $t("table.title") }}</FormLabel
              >
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="
                  contactUsData[objAccessor].contactMethods[parentIndex].title
                "
              />
            </div>
          </div>

          <DraggableList
            @removeItem="
              (childIndex) => handleRemoveParagraph(parentIndex, childIndex)
            "
            @addItem="handleAddParagraph(parentIndex)"
            :theArray="
              contactUsData[objAccessor].contactMethods[parentIndex].paragraphs
            "
            class="mt-7"
          >
            <template v-slot="{ index: childIndex }">
              <div class="w-full">
                <!-- Number and Seo Image -->
                <div class="grid-cols-2 gap-3 mt-2 sm:grid">
                  <div>
                    <FormLabel
                      class="!text-slate-400 text-xs font-medium mt-2"
                      htmlFor="crud-form-1"
                    >
                      {{ $t("common.paragraph") }}</FormLabel
                    >
                    <FormInput
                      id="crud-form-1"
                      type="text"
                      class="w-full"
                      v-model="
                        contactUsData[objAccessor].contactMethods[parentIndex]
                          .paragraphs[childIndex]
                      "
                    />
                  </div>
                </div>
              </div>
            </template>
          </DraggableList>
          <div class="mt-3 grid-cols-2 gap-3 sm:grid">
            <div class="col-span-2">
              <FormLabel
                class="!text-slate-400 text-xs font-medium mt-2"
                htmlFor="crud-form-1"
              >
                {{ $t("website.contactUs.contacts.icon") }}</FormLabel
              >
              <!-- <Dropzone
                refKey="dropzoneMultipleRef"
                :options="{
                  url: 'https://httpbin.org/post',
                  thumbnailWidth: 150,
                  maxFilesize: 0.5,
                  addRemoveLinks: true,
                  headers: { 'My-Awesome-Header': 'header value' },
                }"
                class="dropzone cursor-pointer"
              >
                <div class="font-medium text-slate-400">
                  {{ $t("common.dropFilesHere") }}
                </div>
              </Dropzone> -->

              <FileUploader
                v-model="
                  contactUsData[objAccessor].contactMethods[parentIndex].image
                    .url
                "
                storagePath="SeoImages"
              />

              <!-- <SeoImageInput
                class="mb-4"
                v-model="
                  contactUsData[objAccessor].contactMethods[parentIndex].image
                "
              /> -->
            </div>
          </div>
        </div>
      </template>
    </DraggableList>
  </div>
</template>
