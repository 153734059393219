import gql from "graphql-tag";
import { BlogCategoryFragment } from "../fragments/blogs-categories-fragments";

export const UPDATE_BLOG_CATEGORY = gql`
  mutation UPDATE_BLOG_CATEGORY($data: CategoryInput!, $categoryId: String) {
    updateCategory(data: $data, categoryId: $categoryId) {
      ...BlogCategoryFragment
    }
  }
  ${BlogCategoryFragment}
`;

export const DELETE_BLOG_CATEGORY = gql`
  mutation DELETE_BLOG_CATEGORY($categoryId: String!) {
    deleteCategory(categoryId: $categoryId)
  }
`;
