import { HomePage, SocialType } from "@/types/website/home";

export const initHome: HomePage = {
  ArHomePage: {
    title: "",
    paragraph: "",
    buttons: [
      { text: "", link: "" },
      { text: "", link: "" },
    ],
    images: [
      { title: "icon 1", alt: "", caption: "", description: "", url: "" },
    ],
    ourCustomersTitle: '',
    ourCustomers: [],
    aboutUs: {
      title: "",
      paragraph: "",
      button: { text: "", link: "" },
      statistics: [
        {
          title: "",
          number: "",
          paragraph: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
        },
      ],
    },
    services: {
      title: "",
      paragraph: "",
      cardsInput: [
        {
          title: "",
          paragraph: "",
          image: { alt: "", caption: "", description: "", title: "", url: "" }, //lsa m3mltsh v-model
          button: { text: "", link: "" },
        },
      ],
    },
    outSourcing: { title: "", paragraph: "", button: { text: "", link: "" } },
    testimonials: [
      {
        review: "",
        reviewerName: "",
        reviewerTitle: "",
        reviewerImage: {
          alt: "",
          caption: "",
          description: "",
          title: "",
          url: "",
        },
        companyImage: {
          alt: "",
          caption: "",
          description: "",
          title: "",
          url: "",
        },
      },
    ],
  },
  EnHomePage: {
    title: "",
    paragraph: "",
    buttons: [
      { text: "", link: "" },
      { text: "", link: "" },
    ],
    images: [
      { title: "icon 1", alt: "", caption: "", description: "", url: "" },
    ],
    ourCustomersTitle: '',
    ourCustomers: [],
    aboutUs: {
      title: "",
      paragraph: "",
      button: { text: "", link: "" },
      statistics: [
        {
          title: "",
          number: "",
          paragraph: "",
          image: {
            title: "",
            alt: "",
            caption: "",
            description: "",
            url: "",
          },
        },
      ],
    },
    services: {
      title: "",
      paragraph: "",
      cardsInput: [
        {
          title: "",
          paragraph: "",
          image: { alt: "", caption: "", description: "", title: "", url: "" },
          button: { text: "", link: "" },
        },
      ],
    },
    outSourcing: { title: "", paragraph: "", button: { text: "", link: "" } },
    testimonials: [
      {
        review: "",
        reviewerName: "",
        reviewerTitle: "",
        reviewerImage: {
          alt: "",
          caption: "",
          description: "",
          title: "",
          url: "",
        },
        companyImage: {
          alt: "",
          caption: "",
          description: "",
          title: "",
          url: "",
        },
      },
    ],
  },
  metaTags: {
    robots: "",
    canonical: "",
    description: "",
    social: {
      socialNetwork: SocialType.Facebook,
      description: "",
      title: "",
      image: { alt: "", caption: "", description: "", title: "", url: "" },
    },
    title: "",
    keywords: [],
  },
};

export default initHome;
