import gql from "graphql-tag";
import {
  ButtonFragment,
  ImageFragment,
  MetaTagsFragment,
  StatisticsFragment,
  CardsFragment,
} from "./base-fragments";

export const AboutUsFragment = gql`
  fragment AboutUsFragment on AboutUs {
    title
    paragraph
    button {
      ...ButtonFragment
    }
    statistics {
      ...StatisticsFragment
    }
  }
  ${ButtonFragment}
  ${StatisticsFragment}
`;

export const ServicesServicesFragment = gql`
  fragment ServicesServicesFragment on ServicesServices {
    title
    paragraph


    cards {
      ...CardsFragment
    }
  }

  ${CardsFragment}
`;

export const ContentServicesPageFragment = gql`
  fragment ContentServicesPageFragment on ContentServicesPage {
    title
    paragraph
    button {
      ...ButtonFragment
    }
    services {
      ...ServicesServicesFragment
    }
    # ourCustomersTitle
    # ourCustomers
    aboutUs {
      ...AboutUsFragment
    }
  }
  ${ButtonFragment}
  ${ServicesServicesFragment}
  ${AboutUsFragment}
`;

export const ServicesPageFragment = gql`
  fragment ServicesPageFragment on ServicesPage {
    metaTags {
      ...MetaTagsFragment
    }
    EnServicesPage {
      ...ContentServicesPageFragment
    }
    ArServicesPage {
      ...ContentServicesPageFragment
    }
  }
  ${MetaTagsFragment}
  ${ContentServicesPageFragment}
`;
