import { useAuthStore } from "@/stores/auth/auth-store";
import { storeToRefs } from "pinia";
import { getLanguage } from "@/locale/i18n";
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import usePermissions from "@/composables/usePermissions";

export default {
  async beforeRouteEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!to.meta) {
      next();
      return;
    }
    const lang = getLanguage();
    const { doWaitUntilInit } = useAuthStore();
    const { signedIn, currentUser } = storeToRefs(useAuthStore());

    //Permissions
    const { hasPermission } = usePermissions();

    let userInStorage = localStorage.getItem("currentUser");
    if (userInStorage) {
      await doWaitUntilInit();
    }
    if (to.meta.auth) {
      if (!signedIn.value) {
        next({
          name: "login-page",
          params: {
            locale: lang,
          },
        });
        console.log("after next login");
        return;
      }

      // if (to.meta.permission) {
      //   next();
      // } else {
      //   next({
      //     name: "login-page",
      //     params: {
      //       locale: lang,
      //     },
      //   });
      //   console.log("after next login");
      //   return;
      // }
      //   if (
      //     to.path !== "/auth/empty-permissions" &&
      //     currentUser.value.emailVerified &&
      //     !roles.value.length

      //   ) {
      //     next({ path: "/auth/empty-permissions" });
      //     return;
      //   }
    }
    if (to.meta.unauth) {
      if (signedIn.value) {
        next({ path: "/" });
        return;
      }
    }
    // if (to.meta.notEmptyPermissions) {
    //   if (signedIn.value && roles.value.length) {
    //     next("/");
    //   } else {
    //     next();
    //   }
    // }
    if (to.meta.permission) {
      console.log(to.meta.permission, "its meta permission");
      // check if this page permission , is in array of permissions for that user
      if (hasPermission(to.meta.permission)) {
        next();
        console.log(to.meta.permission, "able to enter");
      } else {
        next("/403");
        console.log("don't able to enter");
      }
    }
    next();
  },
};
