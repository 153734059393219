<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const servicPageData = inject<IServicePage>(
  "servicPageData",
  {} as IServicePage
);
const objAccessor = inject<ComputedRef<IServiceObject> | IServiceObject>(
  "objAccessor",
  "EnServicesPage"
);

const accessor = computed<IServiceObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});

const handleAddItem = () => {
  const lastIndex =
    servicPageData.value[accessor.value]?.aboutUs.statistics.length;
  const title = `${i18n("common.statistics")} ${lastIndex + 1}`;
  servicPageData.value[accessor.value]?.aboutUs.statistics.push({
    title: "",
    number: "",
    paragraph: "",
    image: {
      title,
      alt: "",
      caption: "",
      description: "",
      url: "",
    },
  });
};

const handleRemoveItem = (index: number) => {
  servicPageData.value[accessor.value]?.aboutUs.statistics.splice(index, 1);
};
</script>

<template>
  <div class="mt-4">
    <h4 class="font-medium">
      {{ $t("website.home.aboutUs.fields.statistics") }}
    </h4>

    <DraggableList
      class="mt-5"
      @removeItem="handleRemoveItem"
      @addItem="handleAddItem"
      :theArray="servicPageData[objAccessor].aboutUs.statistics"
    >
      <template v-slot="{ index }">
        <div class="w-full">
          <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
            <div>
              <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
                {{ $t("common.name") }}</FormLabel
              >
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="
                  servicPageData[objAccessor].aboutUs.statistics[index].title
                "
              />
            </div>
            <div>
              <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
                {{ $t("table.paragraph") }}</FormLabel
              >
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="
                  servicPageData[objAccessor].aboutUs.statistics[index]
                    .paragraph
                "
              />
            </div>
          </div>

          <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-3 sm:grid">
            <div>
              <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
                {{ $t("website.home.aboutUs.fields.number") }}</FormLabel
              >
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="
                  servicPageData[objAccessor].aboutUs.statistics[index].number
                "
              />
            </div>
            <div>
              <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
                {{ $t("website.home.aboutUs.fields.media") }}</FormLabel
              >
              <SeoImageInput
                v-model="
                  servicPageData[objAccessor].aboutUs.statistics[index].image
                "
              />
            </div>
          </div>
        </div>
      </template>
    </DraggableList>
  </div>
</template>
