/* eslint-disable */ // import CryptoJSON from 'crypto-json'
// import CryptoJS = require('crypto-js')

// const CryptoJSON = require('crypto-json')
// const CryptoJS = require('crypto-js')
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
/**
 * Encryption Functions
 */
export default class Crypto {
  // #region [ Encryption Functions ]
  // ================================================================= //
  //                     ENCRYPT & DECRYPT OBJECT                      //
  // ================================================================= //
  static encryptObject(object: any, key: any) {
    const CryptoJSON = require("crypto-json");
    const algorithm = "aes256";
    const encoding = "hex";
    const password = key;
    let keys = [];

    for (let [k, value] of Object.entries(object)) {
      if (value) {
        keys.push(k);
      }
    }
    const output = CryptoJSON.encrypt(object, password, {
      encoding,
      keys,
      algorithm,
    });
    return output;
  }

  static decryptObject(encryptedObject: any, key: any) {
    const CryptoJSON = require("crypto-json");
    const algorithm = "aes256";
    const encoding = "hex";
    const password = key;
    let keys = [];

    for (let [k, value] of Object.entries(encryptedObject)) {
      if (value) {
        keys.push(k);
      }
    }

    const output = CryptoJSON.decrypt(encryptedObject, password, {
      encoding,
      keys,
      algorithm,
    });
    return output;
  }

  // ================================================================= //
  //                     ENCRYPT & DECRYPT STRING                      //
  // ================================================================= //
  static encryptString(message: any, key: any) {
    const ciphertext = AES.encrypt(message, key); // Encrypt
    return ciphertext;
  }

  static decryptString(ciphertext: any, key: any) {
    if (!ciphertext) return null;
    const bytes = AES.decrypt(ciphertext.toString(), key); // Decrypt
    const plaintext = bytes.toString(Utf8);
    return plaintext;
  }

  // ================================================================= //
  //             OBJECT ENCRYPTION & DECRYPTION OTHER WAY              //
  // ================================================================= //
  static encryption(data: any, key: any) {
    const ciphertext = AES.encrypt(JSON.stringify(data), key); // Encrypt
    return ciphertext;
  }
  static decryption(cipherData: any, key: any) {
    if (!cipherData) return null;
    const bytes = AES.decrypt(cipherData.toString(), key); // Decrypt
    const decryptedData = JSON.parse(bytes.toString(Utf8));
    return decryptedData;
  }
  // #endregion
}
