<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import TomSelect from "@/base-components/TomSelect";
import { useLocalize } from "@/locale/i18n";
import { GET_MEMBERS_DROPMENU } from "@/lib/graphql/queries/members";
import { useQuery } from "@vue/apollo-composable";

import { ref, ComputedRef, inject, computed } from "vue";

const { i18n } = useLocalize();

const { loading, result } = useQuery<any>(GET_MEMBERS_DROPMENU, null, {
  fetchPolicy: "no-cache",
});

const placeholder = computed<string>(() => i18n("blogs.selectMembers"));

const membersList = computed<IMember[]>(
  () => result.value?.getMembers?.member || []
);

const selectedPartners = ref<any[]>([]);
const partnersList = ref<any[]>([
  "centeral Test",
  "Connected",
  "ExtraMile",
  "L&B Solutions",
]);

const Loading = false;

const aboutusData = inject<ComputedRef<IAboutUsPage>>("aboutusData")!;

const objAccessor = inject<ComputedRef<IAboutUsObject>>("objAccessor")!;
</script>

<template>
  <div class="my-20">
    <div class="mb-3 intro-y pb-4 mt-10">
      <h2 class="text-lg md:text-xl font-medium">
        {{ $t("website.contactUs.ourTeam") }}
      </h2>
    </div>

    <!-- ACHIVMENT TITLE AND PARAGRAPH -->
    <div>
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("common.title") }}
          </FormLabel>
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].ourMembers.title"
          />
        </div>

        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("common.paragraph") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].ourMembers.paragraph"
          />
        </div>
      </div>
    </div>
    <!-- END ACHIVMENT TITLE AND PARAGRAPH -->

    <div class="mt-4">
      <h4 class="font-medium mb-2">
        {{ $t("website.contactUs.ourTeam") }}
      </h4>
    </div>

    <div class="mt-3">
      <TomSelect
        v-model="aboutusData[objAccessor].memberIds"
        :options="{
          placeholder,
        }"
        class="w-full"
        multiple
        
        :create="false"
      >
        <option
          disabled
          value="loading"
          v-if="Loading"
          style="text-align: center"
        >
          Loading...
        </option>
        <template v-else>
          <option
            v-for="member in membersList"
            :key="member.id"
            :value="member.id"
          >
            {{ member?.fullName }}
          </option>
        </template>
      </TomSelect>
    </div>
  </div>
</template>
