export default interface permissions {
  hasPermissionToPrint: boolean;
  hasPermissionToExport: boolean;
  hasPermissionToDestroy: boolean;
  hasPermissionToChangeStatus: boolean;
  hasPermissionToRead: boolean;
  hasPermissionToEdit: boolean;
  hasPermissionToCreate: boolean;
}

export enum Roles {
  ADMINISTRATOR = "ADMINISTRATOR",
  MODERATOR = "MODERATOR",
  EMPLOYEE = "EMPLOYEE",
  AUTHOR = "AUTHOR",
}

export enum Permissions {
  ViewHomeDashboard = "ViewHome",
  ViewBlogs = "View blogs",
  ViewBlogCategories = "ViewBlogCategories",
  DeleteBlog = "DeleteBlog",
  EditBlog = "EditBlog",
  ViewPartners = "ViewPartners",
  EditPartners = "EditPartners",
  ViewClients = "ViewClients",
  EditClients = "EditClients",
  ViewFaqs = "ViewFaqs",
  EditFaqs = "EditFaqs",
  ViewMembers = "ViewMembers",
  EditMembers = "EditMembers",
  UpdateMyProfile = "UpdateMyProfile",
  ViewWebsiteMenu = "ViewWebsiteMenu",
  ViewSEO = "ViewSEO",
}
