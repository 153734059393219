import { HRPage } from "@/types/website/services/serviceHR";

export const initialHRPage: HRPage = {
  EnHRPage: {
    title: "",
    paragraph: "",
    button: {
      text: "",
      link: "",
    },
    gapAnalysis: {
      title: "",
      description: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    businessStrategies: {
      title: "",
      description: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
    managmentSystem: {
      title: "",
      description: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
      ending: "",
    },
    assessmentCenter: {
      title: "",
      description: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
  },
  ArHRPage: {
    title: "",
    paragraph: "",
    button: {
      text: "",
      link: "",
    },
    gapAnalysis: {
      title: "",
      description: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    businessStrategies: {
      title: "",
      description: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
    managmentSystem: {
      title: "",
      description: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
      ending: "",
    },
    assessmentCenter: {
      title: "",
      description: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
  },
};

export default initialHRPage;
