<script setup lang="ts">
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import { useLocalize } from "@/locale/i18n";
import Button from "@/base-components/Button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { reactive } from "vue";
import { UPDATE_CATEGORY_QUESTION } from "@/lib/graphql/mutations/faq";
import { useMutation } from "@vue/apollo-composable";
import { toast } from "vue-sonner";
import { ref } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
const emit = defineEmits(["question-added"]);
const open = ref(false);

const props = defineProps<{ row: Question }>();
interface LocalizedInput {
  ar: String;
  en: String;
}

interface Question {
  answer: LocalizedInput;
  question: LocalizedInput;
  categoryId: string;
  id: string;
}

const { i18n } = useLocalize();
const question = reactive({
  en: props.row?.question?.en || "",
  ar: props.row?.question?.ar || "",
});

const answer = reactive({
  en: props.row?.answer?.en || "",
  ar: props.row?.answer?.ar || "",
});

const { mutate: updateFAQQuestion, loading } = useMutation(
  UPDATE_CATEGORY_QUESTION
);

const onsubmit = async () => {
  const data = {
    question: { en: question.en, ar: question.ar },
    answer: { en: answer.en, ar: answer.ar },
    categoryId: route.params.id,
  };
  try {
    const result = await updateFAQQuestion({ data, id: props.row?.id });
    emit("question-added");
    if (result?.data?.updateFAQQuestion?.id) {
      props.row?.id
        ? toast.success(i18n("faqs.questionsUpdated"))
        : toast.success(i18n("faqs.questionsCreated"));
    }
    open.value = false;
  } catch (error: any) {
    toast.error(error.message || i18n("faqs.error"));
  }
};
</script>

<template>
  <Dialog v-model:open="open">
    <DialogTrigger as-child>
      <slot name="trigger">
        <button
          class="flex items-center px-4 py-2 border border-slate-200 rounded-md gap-1 cursor-pointer"
        >
          <span class="text-sm">
            {{ $t("common.createNew") }}
          </span>
          <Icon class="text-base" icon="ic:round-add" /></button
      ></slot>
    </DialogTrigger>
    <DialogContent class="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>
          {{ $t("faqs.addQuestion") }}
        </DialogTitle>
        <DialogDescription>
          {{ $t("faqs.addQuestionDescription") }}
        </DialogDescription>
      </DialogHeader>
      <div class="grid gap-4 py-4">
        <div>
          <Label for="question">
            {{ $t("faqs.questionEN") }}
          </Label>
          <Input v-model="question.en" type="text" id="question" class="mt-2" />
        </div>
        <div>
          <Label for="answer">
            {{ $t("faqs.answerEN") }}
          </Label>
          <Input v-model="answer.en" type="answer" id="answer" class="mt-2" />
        </div>
      </div>
      <div class="grid gap-4 py-4">
        <div>
          <Label for="question">
            {{ $t("faqs.questionAR") }}
          </Label>
          <Input v-model="question.ar" type="text" id="question" class="mt-2" />
        </div>
        <div>
          <Label for="answer">
            {{ $t("faqs.answerAR") }}
          </Label>
          <Input v-model="answer.ar" type="answer" id="answer" class="mt-2" />
        </div>
      </div>
      <DialogFooter>
        <Button :isLoading="loading" variant="primary" @click="onsubmit">
          {{ $t("common.send") }}</Button
        >
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
