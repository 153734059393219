<template>
  <!-- BEGIN: TABLE LAYOUT -->
  <!-- <SeoMetaFields class="bg-white p-5 rounded-lg mt-10" /> -->
  <!-- :actionsDropdown="actionsDropdown" -->
  <!-- :toolbar="toolbar" -->
  <!-- @filter="doFilter" 
  @reset="doResetFilter"
  :filter="filterFields" -->

  <data-list
    createLink="blogs-new"
    tableTitle="routes.blogs"
    :rows="blogs"
    :columns="columns"
    :loading="loading"
    :pagination="pagination"
    :withPagination="true"
    class="mt-6"
  >
    <template #default="{ row }">
      <router-link
        class="flex items-center gap-2 whitespace-nowrap"
        :to="`blogs/edit/${row?.id}`"
      >
        <Icon icon="solar:square-top-down-broken" class="h-5 w-5" />
        <!-- Open -->
      </router-link>
      <DeleteModal
        @delete="handleDelete(row?.id)"
        v-if="hasPermission(Permissions.DeleteBlog)"
      />
    </template>
  </data-list>

  <!-- END: TABLE LAYOUT -->
  <!-- BEGIN: Pagination -->

  <!-- END: Pagination -->
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import { GET_BLOGS } from "@/lib/graphql/queries/blog";
import { useMutation, useLazyQuery } from "@vue/apollo-composable";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import { DELETE_BLOG } from "@/lib/graphql/mutations/blog";
import { usePagination } from "@/lib/pagination";
import { useLocalize } from "@/locale/i18n";
import {
  columns,
  rows,
  actionsDropdown,
  filterFields,
  toolbar,
} from "./tableConfig";
import usePermissions from "@/composables/usePermissions";

const { hasPermission, Permissions } = usePermissions();

// Localization
const { isRTL, i18n } = useLocalize();

// MODAL TOGGLE
const showDeleteModal = ref(false);

// FILTER
const filterInput = ref<Record<string, string>[]>([]);

const doFilter = async (filter: Record<string, string>[], orderBy: string) => {
  filterInput.value = [];
  if (!filter) return;
  if (Array.isArray(filter)) {
    filterInput.value.push(...filter);
  } else {
    filterInput.value.push(filter);
  }
};
const doResetFilter = async () => {
  filterInput.value = [];
};

type BlogsApi = {
  getBlogs: {
    blog: IBlog[];
    totalRecords: number;
  };
};

const {
  loading: fetchLoading,
  result,
  refetch,
  load,
} = useLazyQuery<BlogsApi>(GET_BLOGS, null, {
  fetchPolicy: "no-cache",
  notifyOnNetworkStatusChange: true,
});

const { getPaginationVariables, pagination } = usePagination<BlogsApi>(
  result,
  "getBlogs",
  refetch
);

load(null, { pagination: getPaginationVariables() });

const { mutate: deleteBlog, loading: deleteLoading } = useMutation(DELETE_BLOG);

const handleDelete = async (blogId: string) => {
  // console.log(blogId, "its blog id");
  await deleteBlog({ blogId });
  await refetch();
};

const loading = computed<boolean>(
  () => fetchLoading.value || deleteLoading.value
);

const blogs = computed<IBlog[]>(() => result?.value?.getBlogs?.blog || []);

// MOUNTED
onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.blogs");
});
watch(
  isRTL,
  () => (document.title = i18n("routes.app") + " | " + i18n("routes.blogs"))
);
</script>
