<script setup lang="ts">
import { ref } from "vue";
const props = defineProps(["name", "isShowenProp", "index"]);

const isShowen = ref(props.isShowenProp);
const toggle = () => {
  isShowen.value = !isShowen.value;
};
</script>

<template>
  <div class="px-3 border-b-2 border-b-slate-200 bg-white">
    <div class="flex justify-between py-3 sm:py-5">
      <div class="flex items-center gap-3">
        <Icon
          class="text-xl sm:text-2xl transition-all duration-300"
          :class="{ 'rotate-180': isShowen }"
          @click="toggle"
          icon="solar:round-alt-arrow-up-broken"
        />

        <div>{{ name ? name : "name" }}</div>
      </div>

      <div class="flex items-center gap-3">
        <span @click="$emit('removeItem')" class="cursor-pointer">
          <Icon class="text-xl sm:text-2xl" icon="solar:trash-bin-2-broken" />
        </span>
        <span class="cursor-pointer">
          <Icon class="text-xl sm:text-2xl" icon="nimbus:drag-dots" />
        </span>
      </div>
    </div>
    <div
      class="left-part grid gap-5 items-center overflow-hidden max-h-[0px] transition-[max-height] duration-700 ease-out"
      :class="{ '!max-h-[900px] openAnimation': isShowen }"
    >
      <div class="mb-7" :draggable="false">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.field) {
  min-height: 23px;
  overflow: hidden;
  display: flex;
  gap: 20px;
  align-items: center;
}

.left-part.openAnimation :deep(.field:not(:first-child)) {
  animation: fadeIn 0.8s ease forwards !important;
}

.left-part:not(.openAnimation) :deep(.field:not(:first-child)) {
  animation: fadeOut 0.2s ease forwards !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
