<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const outsourcingData =
  inject<ComputedRef<IOutsourcingPage>>("outsourcingData")!;

// ! to tell ts iam sure its exist
const objAccessor = inject<ComputedRef<IOutsourcingObject>>("objAccessor")!;

console.log(outsourcingData.value, "outsourcingData");
console.log(objAccessor.value, "objAccessor");

// Define a computed property with getter and setter
</script>

<template>
  <div class="mt-6">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("website.home.hero.fields.title") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("website.contactUs.hero.fields.paragraph") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].paragraph"
        />
      </div>
    </div>
  </div>
  <!-- End Hero Title & paragraph-->

  <!--Hero CallToActionName En and Ar -->
  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.aboutUs.hero.fields.callToAction") }}
    </h4>
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("website.aboutUs.hero.fields.callToAction") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].button.text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].button.link"
        />
      </div>
    </div>
  </div>

  <!-- End Hero CallToActionName En and Ar -->
</template>
