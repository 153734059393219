<script setup lang="ts">
import TomSelect from "@/base-components/TomSelect";
import { FormInput, FormLabel } from "@/base-components/Form";
import { ref, inject, ComputedRef, computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { GET_PARTNERS } from "@/lib/graphql/queries/partners";
import { useLocalize } from "@/locale/i18n";

const { i18n, language } = useLocalize();

// const selectedPartners = ref<any[]>([]);
// const partnersList = ref<any[]>([
//   "centeral Test",
//   "Connected",
//   "ExtraMile",
//   "L&B Solutions",
// ]);
// const Loading = false;

const aboutusData = inject<ComputedRef<IAboutUsPage>>("aboutusData")!;
const objAccessor = inject<ComputedRef<IAboutUsObject>>("objAccessor")!;

type PartnerApi = {
  listPartners: {
    partner: IPartnersPage[];
    totalRecords: number;
  };
};

const { loading, result } = useQuery<PartnerApi>(GET_PARTNERS, null, {
  fetchPolicy: "no-cache",
});

const placeholder = computed<string>(() =>
  i18n("website.aboutUs.selectPartners")
);

interface Partner {
  id: number;
  title: string;
}

const partners = computed<Partner[]>(() => {
  const EnPartners = result.value?.listPartners.partner.map((partner) => {
    return { id: partner.id, ...partner.EnPartnersPage };
  });

  const ArPartners = result.value?.listPartners.partner.map((partner) => {
    return { id: partner.id, ...partner.ArPartnersPage };
  });

  if (language.value === "en") {
    return EnPartners;
  } else {
    return ArPartners;
  }
});
</script>

<template>
  <div class="my-20">
    <div class="mb-3 intro-y pb-4 mt-10">
      <h2 class="text-base font-medium">
        {{ $t("website.home.ourPartners.title") }}
      </h2>

      <p class="text-slate-400 text-xs mt-1 font-normal">
        {{ $t("website.home.ourPartners.description") }}
      </p>
    </div>

    <div class="mb-5">
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("common.title") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].ourPartners.title"
          />
        </div>

        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("common.paragraph") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].ourPartners.paragraph"
          />
        </div>
      </div>
    </div>

    <!--Call To Action BTN -->
    <div class="mb-5">
      <h4 class="font-medium">
        {{ $t("website.aboutUs.hero.fields.callToAction") }}
      </h4>
      <div class="grid md:grid-cols-2 gap-3">
        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.name") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].ourPartners.button.text"
          />
        </div>
        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.link") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="url"
            class="w-full"
            v-model="aboutusData[objAccessor].ourPartners.button.link"
          />
        </div>
      </div>
    </div>
    <!-- End Call To Action BTN -->

    <div class="mt-3">
      <TomSelect
        v-model="aboutusData[objAccessor].partnerIds"
        :options="{
          placeholder,
        }"
        class="w-full"
        multiple
        :create="false"
      >
        <option
          disabled
          value="loading"
          v-if="loading"
          style="text-align: center"
        >
          Loading...
        </option>
        <template v-else>
          <option
            v-for="partner in partners"
            :key="partner.id"
            :value="partner.id"
          >
            {{ partner.title }}
          </option>
        </template>
      </TomSelect>
    </div>
  </div>
</template>
