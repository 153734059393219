import gql from "graphql-tag";
import { AssessmentsPageFragment } from "../fragments/serviceAssessments-fragments";


export const SET_ASSESSMENTS = gql`
  mutation UPDATE_ASSESSMENTS($data: PsychometricAssessmentsPageInput!) {
    updatePsychometricAssessmentsPage(data: $data) {
      ...AssessmentsPageFragment
    }
  }
  ${AssessmentsPageFragment}
`;


