<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const assessmentsPageData = inject<ComputedRef<IPsychometricAssessmentsPage>>(
  "assessmentsPageData"
)!;

const objAccessor =
  inject<ComputedRef<IPsychometricAssessmentsObject>>("objAccessor")!;

const handleAddItem = () => {
  assessmentsPageData.value[objAccessor.value].benfits.paragraph.push("");
};

const handleRemoveItem = (index: number) => {
  assessmentsPageData.value[objAccessor.value].benfits.paragraph.splice(
    index,
    1
  );

  console.log(index, "index");
};
</script>

<template>
  <div class="mb-5">
    <h2 class="text-lg mt-12 font-medium">
      {{ $t("website.services.assessments.benfits") }}
    </h2>
  </div>

  <div class="mt-4">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.title") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="assessmentsPageData[objAccessor].benfits.title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.description") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="assessmentsPageData[objAccessor].benfits.description"
        />
      </div>
    </div>
  </div>

  <h4 class="font-medium mt-5">
    {{ $t("common.paragraphs") }}
  </h4>
  <DraggableList
    @removeItem="handleRemoveItem"
    @addItem="handleAddItem"
    :theArray="assessmentsPageData[objAccessor].benfits.paragraph"
    class="mt-5"
  >
    <template v-slot="{ index }">
      <div class="w-full">
        <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-3 sm:grid">
          <div>
            <FormLabel
              class="!text-slate-400 text-xs font-medium mt-2"
              htmlFor="crud-form-1"
            >
              {{ $t("common.name") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="
                assessmentsPageData[objAccessor].benfits.paragraph[index]
              "
            />
          </div>
        </div>
      </div>
    </template>
  </DraggableList>
  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.firstCallToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="assessmentsPageData[objAccessor].benfits.buttons[0].text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="assessmentsPageData[objAccessor].benfits.buttons[0].link"
        />
      </div>
    </div>
  </div>

  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.secondCallToAction") }}
    </h4>
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("website.aboutUs.hero.fields.callToAction") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="assessmentsPageData[objAccessor].benfits.buttons[1].text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="assessmentsPageData[objAccessor].benfits.buttons[1].link"
        />
      </div>
    </div>
  </div>
</template>
