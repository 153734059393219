import { MetaTags } from "./home";

export interface MetaTagsComponent {
  id?: string;
  metaTag: MetaTags;
  uniqueComponentName?: PagesNames;
}

export enum PagesNames {
  Login = "login",
  ForgetPassword = "forgetPassword",
  CreateAccount = "createAccount",
  Home = "home",
  ContactUs = "contactUs",
  AboutUs = "aboutUs",
  Services = "services",
  Hr = "hr",
  PsychometricAssessment = "psychometricAssessment",
  Salary = "salary",
  Recruitment = "recruitment",
  OutSourcing = "outSourcing",
  Faq = "faq",
  Blogs = "blogs",
  Jobs = "jobs",
  SavedJobs = "savedJobs",
  Profile = "profile",
  TermsAndCondition = "termsAndCondition",
  PrivacyPolicy = "privacyPolicy",
  Assessment = "assessment",
  Partners = "partners",
}
