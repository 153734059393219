import gql from "graphql-tag";
import { ClientsFragment } from "../fragments/client-fragments";

export const CREATE_CLIENT = gql`
  mutation CREATE_CLIENT($data: ClientInput!) {
    createClient(data: $data) {
      ...ClientsFragment
    }
  }
  ${ClientsFragment}
`;

export const UPDATE_CLIENT = gql`
  mutation UPDATE_CLIENT($data: ClientInputUpdate!, $id: String!) {
    updateClient(data: $data, id: $id) {
      ...ClientsFragment
    }
  }
  ${ClientsFragment}
`;

export const DELETE_CLIENT = gql`
  mutation DELETE_CLIENT($id: String!) {
    deleteClient(id: $id)
  }
`;
