import gql from "graphql-tag";
import { AssessmentsPageFragment } from "../fragments/serviceAssessments-fragments";

export const GET_ASSESSMENTS = gql`
  query {
    getPsychometricAssessmentsPage {
      ...AssessmentsPageFragment
    }
  }
  ${AssessmentsPageFragment}
`;
