import gql from "graphql-tag";
import { ButtonFragment, ImageFragment } from "./base-fragments";

export const AboutUsBriefFragment = gql`
  fragment AboutUsBriefFragment on AboutUsBrief {
    title
    paragraph
    button {
      ...ButtonFragment
    }
    image {
      ...ImageFragment
    }
  }
  ${ButtonFragment}
  ${ImageFragment}
`;

export const VisionAndMissionFragment = gql`
  fragment VisionAndMissionFragment on AboutUsVisionAndMission {
    title
    image {
      ...ImageFragment
    }
    visionTitle
    visionParagraph
    missionTitle
    missionParagraph
  }
  ${ImageFragment}
`;

export const OurPartnersFragment = gql`
  fragment OurPartnersFragment on AboutUsOurParteners {
    title
    paragraph
    button {
      ...ButtonFragment
    }
  }
  ${ButtonFragment}
`;

export const StatisticsFragment = gql`
  fragment StatisticsFragment on Statistics {
    title
    number
    paragraph
    image {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const OurAchievementsFragment = gql`
  fragment OurAchievementsFragment on AboutUsOurAchievements {
    title
    paragraph
    image {
      ...ImageFragment
    }
    statistics {
      ...StatisticsFragment
    }
  }
  ${ImageFragment}
  ${StatisticsFragment}
`;

export const OurMembersFragment = gql`
  fragment OurMembersFragment on OurMembers {
    title
    paragraph
  }
`;

// Fragment for ContentPartnersPage
// export const ContentPartnersPageFragment = gql`
//   fragment ContentPartnersPageFragment on ContentPartnersPage {
//     title
//     image {
//       ...ImageFragment
//     }
//   }
//   ${ImageFragment}
// `;

// Fragment for PartnersPage
// export const PartnersPageFragment = gql`
//   fragment PartnersPageFragment on PartnersPage {
//     id
//     EnPartnersPage {
//       ...ContentPartnersPageFragment
//     }
//     ArPartnersPage {
//       ...ContentPartnersPageFragment
//     }
//   }
//   ${ContentPartnersPageFragment}
// `;

/// Fragment for MembersPage
// export const MembersPageFragment = gql`
//   fragment MembersPageFragment on MemberModel {
//     id
//     title
//     fullName
//   }
// `;

export const ContentAboutUsPageFragment = gql`
  fragment ContentAboutUsPageFragment on ContentAboutUsPage {
    title
    paragraph
    button {
      ...ButtonFragment
    }
    brief {
      ...AboutUsBriefFragment
    }
    VisionAndMission {
      ...VisionAndMissionFragment
    }
    ourPartners {
      ...OurPartnersFragment
    }
    OurAchievements {
      ...OurAchievementsFragment
    }
    ourMembers {
      ...OurMembersFragment
    }
    partnerIds
    memberIds
  }
  ${ButtonFragment}
  ${AboutUsBriefFragment}
  ${VisionAndMissionFragment}
  ${OurPartnersFragment}
  ${OurAchievementsFragment}
  ${OurMembersFragment}
`;

export const AboutUsPageFragment = gql`
  fragment AboutUsPageFragment on AboutUsPageWithPartners {
    EnAboutUsPage {
      ...ContentAboutUsPageFragment
    }
    ArAboutUsPage {
      ...ContentAboutUsPageFragment
    }
  }

  ${ContentAboutUsPageFragment}
`;
