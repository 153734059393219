// import { HomePage, SocialType } from "@/types/website/home";

export const initialRecruitmentPage: IRecruitmentPage = {
  EnRecruitmentPage: {
    paragraph: "",
    title: "",
    button: {
      text: "",
      link: "",
    },
    local: {
      description: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
      title: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
    overSeas: {
      title: "",
      description: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
  },
  ArRecruitmentPage: {
    paragraph: "",
    title: "",
    button: {
      text: "",
      link: "",
    },
    local: {
      description: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
      title: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
    overSeas: {
      description: "",
      title: "",
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
  },
};

export default initialRecruitmentPage;
