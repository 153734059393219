export const initialAboutUsPageWithPartners: IAboutUsPage = {
  EnAboutUsPage: {
    partnerIds: [], // selected partnersIds from the list
    memberIds: [], // selected membersIds from the list
    title: "",
    paragraph: "",
    button: {
      text: "",
      link: "",
    },
    brief: {
      title: "",
      paragraph: "",
      button: {
        text: "",
        link: "",
      },
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    VisionAndMission: {
      title: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
      visionTitle: "",
      visionParagraph: "",
      missionTitle: "",
      missionParagraph: "",
    },
    ourPartners: {
      title: "",
      paragraph: "",
      button: {
        text: "",
        link: "",
      },
    },
    OurAchievements: {
      title: "",
      paragraph: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
      statistics: [
        {
          title: "",
          number: "",
          paragraph: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
        },
      ],
    },
    ourMembers: {
      title: "",
      paragraph: "",
    },
  },
  ArAboutUsPage: {
    partnerIds: [], // selected partnersIds from the list
    memberIds: [], // selected membersIds from the list
    title: "",
    paragraph: "",
    button: {
      text: "",
      link: "",
    },
    brief: {
      title: "",
      paragraph: "",
      button: {
        text: "",
        link: "",
      },
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    VisionAndMission: {
      title: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
      visionTitle: "",
      visionParagraph: "",
      missionTitle: "",
      missionParagraph: "",
    },
    ourPartners: {
      title: "",
      paragraph: "",
      button: {
        text: "",
        link: "",
      },
    },
    OurAchievements: {
      title: "",
      paragraph: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
      statistics: [
        {
          title: "",
          number: "",
          paragraph: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
        },
      ],
    },
    ourMembers: {
      title: "",
      paragraph: "",
    },
  },
};
