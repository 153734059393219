import gql from "graphql-tag";
import { AboutUsPageFragment } from "../fragments/aboutus-fragments";

export const GET_ABOUTUS = gql`
  query {
    getAboutUsPage {
      ...AboutUsPageFragment
    }
  }
  ${AboutUsPageFragment}
`;
