<template>
  <div>
    {{ formatPrice(row[column.field]) }}
  </div>
</template>

<script setup lang="ts">
import IColumn from "@/types/TableColumn";
import { useLocalize } from "@/locale/i18n";

const { isRTL } = useLocalize();

interface IRow {
  [key: string]: any;
}

interface IProps {
  row: IRow;
  column: IColumn;
}

defineProps<IProps>();

const formatPrice = (price: number) => {
  const PRICE = (Math.round(price * 100) / 100).toFixed(2);
  return isRTL.value ? `${PRICE} جم` : `${PRICE} EGP`;
};
</script>
