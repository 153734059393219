import { createI18n } from "vue-i18n";
import en from "./en.json";
import ar from "./ar.json";
import { useI18n } from "vue-i18n";

import { computed } from "vue";

const defaultLocale = "en";
const vueI18n = createI18n({
  // something vue-i18n options here ...
  legacy: false,
  locale: localStorage.getItem("lang") || defaultLocale, // set locale
  fallbackLocale: localStorage.getItem("lang") || defaultLocale, // set fallback locale
  messages: {
    en,
    ar,
  }, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});

const setArLang = () => {
  vueI18n.global.locale.value = "ar";
  document.body.setAttribute("lang", "ar");
  localStorage.setItem("lang", "ar");
  document.body.setAttribute("dir", "rtl");
};

const setEnLang = () => {
  vueI18n.global.locale.value = "en";
  document.body.setAttribute("lang", "en");
  document.body.setAttribute("dir", "ltr");

  localStorage.setItem("lang", "en");
};
export const useLocalize = () => {
  const isRTL = computed(() => vueI18n.global.locale.value == "ar");
  const language = computed(() => vueI18n.global.locale.value);
  const { t: i18n } = useI18n();
  return { isRTL, i18n, language };
};
export const t = (key: string, ...args: string[]) =>
  vueI18n.global.t(key, args);

export { vueI18n, setArLang, setEnLang, defaultLocale };
export const getLanguage = () => vueI18n.global.locale.value;
