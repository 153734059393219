
// TABLE COLUMNS
export const columns = [
  {
    field: "title",
    label: "table.title",
    type: "string",
  },
  
  {
    field: "paragraph",
    label: "table.paragraph",
    type: "truncatedText",
  },
  {
    field: "action",
    label: "table.actions",
    type: "action",
    align:"end",
    ignorePrint: "ignore-0",
    ignorePrintRow: "ignore-00",
  },
];
//ACTIONS DROPDOWN
export const actionsDropdown = ["deleteAll"];

//FILTER
export const filterFields = [
  {
    label: "name",
    field: "name",
    type: "text",
    orderBy: "name",
    operator: "Equal",
  },

  {
    label: "Start Date",
    field: "startDate",
    type: "date",
    orderBy: "id",
    operator: "Equal",
  },
  {
    label: "End Date",
    field: "dateRange",
    type: "dateRange",
    orderBy: "id",
  },

  {
    label: "status",
    field: "status",
    type: "list",
    orderBy: "id",
    operator: "Equal",

    options: [
      {
        value: "enabled",
        label: "enabled",
      },
      {
        value: "disabled",
        label: "disabled",
      },
    ],
  },
];

// TOOLBAR
export const toolbar = {
  maxWidth: 400,
};

// DUMMY ROWS
export const rows = [
  {
    id: "Rn2",
    title: 'assessment title',
    paragraph: "assessment paragraph",
    categoryName: "Software 1",
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
    
  },
  {
    id: "Rn23",
    title: 'assessment title',
    paragraph: "assessment paragraph",
    status: "enabled",
    categoryName: "Software 2",
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
    
  },
  {
    id: "Rn25",
    title: 'assessment title',
    paragraph: "assessment paragraph",
    status: "disabled",
    categoryName: "Software 3",
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
  },
];
// export const rows = [];
