<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const servicHRData = inject<IHRPage>("servicHRData", {} as IHRPage);
const objAccessor = inject<ComputedRef<IHRObject> | IHRObject>(
  "objAccessor",
  "EnHRPage"
);

const accessor = computed<IHRObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});

const handleAddItem = () => {
  const lastIndex =
    servicHRData.value[accessor.value]?.managmentSystem.paragraphs.length;
  const title = `${i18n("common.statistics")} ${lastIndex + 1}`;
  servicHRData.value[accessor.value]?.managmentSystem.paragraphs.push({
    title: "",
  });
};

const handleRemoveItem = (index: number) => {
  servicHRData.value[accessor.value]?.managmentSystem.paragraphs.splice(
    index,
    1
  );
};
</script>

<template>
  <div class="mb-5">
    <h2 class="text-lg mt-12 font-medium">
      {{ $t("website.services.hr.managmentSystem.title") }}
    </h2>
    <p class="text-slate-400 text-xs mt-1 font-normal">
      {{ $t("website.services.hr.managmentSystem.description") }}
    </p>
  </div>

  <div class="mt-4">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.title") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicHRData[objAccessor].managmentSystem.title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.description") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicHRData[objAccessor].managmentSystem.description"
        />
      </div>
    </div>
  </div>

  <div class="mt-4">
    <h4 class="font-medium mb-4">
      {{ $t("common.media") }}
    </h4>
    <SeoImageInput v-model="servicHRData[objAccessor].managmentSystem.image" />
  </div>

  <h4 class="font-medium mt-5">
    {{ $t("common.paragraphs") }}
  </h4>
  <DraggableList
    @removeItem="handleRemoveItem"
    @addItem="handleAddItem"
    :theArray="servicHRData[objAccessor].managmentSystem.paragraphs"
    class="mt-5"
  >
    <template v-slot="{ index }">
      <div class="w-full">
        <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-3 sm:grid">
          <div>
            <FormLabel
              class="!text-slate-400 text-xs font-medium mt-2"
              htmlFor="crud-form-1"
            >
              {{ $t("common.name") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="
                servicHRData[objAccessor].managmentSystem.paragraphs[index]
              "
            />
          </div>
        </div>
      </div>
    </template>
  </DraggableList>
  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.firstCallToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicHRData[objAccessor].managmentSystem.buttons[0].text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicHRData[objAccessor].managmentSystem.buttons[0].link"
        />
      </div>
    </div>
  </div>

  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.secondCallToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicHRData[objAccessor].managmentSystem.buttons[1].text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicHRData[objAccessor].managmentSystem.buttons[1].link"
        />
      </div>
    </div>
  </div>

  <div class="mt-4">
    <div class="grid-cols-1 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("website.services.hr.managmentSystem.ending") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicHRData[objAccessor].managmentSystem.ending"
        />
      </div>
    </div>
  </div>
</template>
