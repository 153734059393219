<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const servicPageData = inject<IServicePage>(
  "servicPageData",
  {} as IServicePage
);
const objAccessor = inject<ComputedRef<IServiceObject> | IServiceObject>(
  "objAccessor",
  "EnServicesPage"
);

const accessor = computed<IServiceObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});
</script>

<template>
  <div class="mb-3 intro-y pb-4 mt-10">
    <h2 class="text-base font-medium">
      {{ $t("website.home.aboutUs.title") }}
    </h2>

    <p class="text-slate-400 text-xs mt-1 font-normal">
      {{ $t("website.home.aboutUs.description") }}
    </p>
  </div>
  <!--About us Title En and Ar -->
  <div>
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("website.home.aboutUs.fields.title") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicPageData[objAccessor].aboutUs.title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("website.home.aboutUs.fields.paragraph") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicPageData[objAccessor].aboutUs.paragraph"
        />
      </div>
    </div>
  </div>
  <!-- End About us Title En and Ar -->

  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.aboutUs.hero.fields.callToAction") }}
    </h4>
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("website.aboutUs.hero.fields.callToAction") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicPageData[objAccessor].aboutUs.button.text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicPageData[objAccessor].aboutUs.button.link"
        />
      </div>
    </div>
  </div>
</template>
