export interface AssessmentsPage {
  id?: string;
  EnAssessmentsPage: ContentAssessmentsPage;
  ArAssessmentsPage: ContentAssessmentsPage;
  metaTags: IMetaTags;
}
export type AssessmentsObject = "ArAssessmentsPage" | "EnAssessmentsPage";
export interface ContentAssessmentsPage {
  title?: string;
  paragraph?: string;
  slug?: string;
  button?: IButton;
  info: AssessmentsInfo[];
  objective: AssessmentsObjective;
  keyFeatures: AssessmentsKeyFeatures;
  details: AssessmentsDetails;
  sample: AssessmentsSample;
  report: AssessmentsReport;
}
export interface AssessmentsInfo {
  title: string;
  paragraph: string;
  image: IImage;
}

export interface AssessmentsObjective {
  title: string;
  paragraphs: string[];
}
export interface AssessmentsKeyFeatures {
  title: string;
  paragraphs: string[];
}
export interface AssessmentsDetails {
  title: string;
  paragraphs: string[];
}
export interface AssessmentsSample {
  title: string;
  paragraph: string;
}
export interface AssessmentsReport {
  title: string;
  paragraphs: string[];
}

export enum SocialType {
  Twitter = "twitter",
  Facebook = "facebook",
}
