export interface ServicesPage {
  EnServicesPage: ContentServicesPage;
  ArServicesPage: ContentServicesPage;
  metaTags?: IMetaTags;
}

export type ServicesObject = "ArServicesPage" | "EnServicesPage";
export interface ContentServicesPage {
  title: string;
  paragraph: string;
  button: IButton;
  aboutUs: AboutUs;
  services: ServicesServices;
}

export interface ServicesServices {
  title: string;
  paragraph: string;
  cards: Cards[];
}

export interface Cards {
  title: string;
  paragraph: string;
  image: IImage;
  button: IButton;
}

export interface AboutUs {
  title: string;
  paragraph: string;
  button: IButton;
  statistics: Statistics[];
}

export interface Statistics {
  title?: string;
  number?: string;
  paragraph?: string;
  image?: IImage;
}

export enum SocialType {
  Twitter = "twitter",
  Facebook = "facebook",
}
