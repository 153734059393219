import gql from "graphql-tag";
import { ServicesPageFragment } from "../fragments/serviceMain-fragment";

export const SET_SERVICES = gql`
  mutation UPDATE_SERVICES($data: ServicesPageInput!) {
    updateServicesPage(data: $data) {
      ...ServicesPageFragment
    }
  }
  ${ServicesPageFragment}
`;
