import DesktopTableSkeleton from "./DesktopTableSkeleton.vue";
import MobileTableSkeleton from "./MobileTableSkeleton.vue";

const TableSkeleton = Object.assign(
  {},
  {
    DesktopTableSkeleton,
    MobileTableSkeleton,
  }
);

export default TableSkeleton;
