<script setup lang="ts">
import Dropzone from "@/base-components/Dropzone";

type Props = {
  storagePath: string;
  acceptedFiles: string;
};

const props = withDefaults(defineProps<Props>(), {
  acceptedFiles: "image/*",
});

const model = defineModel<string>({ required: true });

const dropzoneOptions = {
  url: "/",
  autoProcessQueue: false, // Disable automatic file processing
  thumbnailWidth: 150,
  maxFilesize: 21,
  addRemoveLinks: true,
  acceptedFiles: props.acceptedFiles,
  maxFiles: 1,
  headers: { "My-Awesome-Header": "header value" },
  storagePath: props.storagePath,
};

const handleUploadSuccess = (downloadURL: string) => {
  model.value = downloadURL;
};

const isValidURL = (url: string) =>
  /^(?:https?:\/\/)?(?:www\.)?[^\s.]+\.[^\s]{2,}$/i.test(url);
</script>
<template>
  <div
    class="relative flex justify-center mt-4 w-fit m-auto rounded-md overflow-hidden min-w-[450px]"
    v-if="!!model && isValidURL(model)"
  >
    <img
      :src="model"
      @error="model = ''"
      alt=""
      style="height: 150px"
      v-if="acceptedFiles === 'image/*'"
    />

    <video height="150" controls v-else class="h-[300px]">
      <source :src="model" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <img
      src="/deleteIcon.png"
      alt=""
      class="delete-icon !w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
      @click="model = ''"
    />
  </div>
  <Dropzone
    v-else
    refKey="dropzoneMultipleRef"
    :options="dropzoneOptions"
    @uploadSuccess="handleUploadSuccess"
    class="dropzone cursor-pointer grid-cols-1 mt-5 border-solid"
  >
    <div class="font-medium text-slate-700">
      {{ $t("common.dropFilesHere") }}
    </div>
  </Dropzone>
</template>
