// import { SalarySurveyPage } from "@/types/website/services/salarysurvey";

export const initialSalarySurveyPage: ISalarySurveyPage = {
  EnSalarySurveyPage: {
    title: "",
    paragraph: "",
    button: { text: "", link: "" },
    brief: {
      title: "",
      paragraph: "",
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      SalarySurveyCardsSection: [
        {
          title: "",
          paragraph: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
        },
      ],
    },
    benfits: {
      title: "",
      paragraph: "",
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    birchesGroup: {
      title: "",
      paragraph: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    subscriptions: [
      {
        title: "",
        paragraph: [""],
        button: {
          text: "",
          link: "",
        },
      },
    ],
  },
  ArSalarySurveyPage: {
    title: "",
    paragraph: "",
    button: { text: "", link: "" },
    brief: {
      title: "",
      paragraph: "",
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      SalarySurveyCardsSection: [
        {
          title: "",
          paragraph: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
        },
      ],
    },
    benfits: {
      title: "",
      paragraph: "",
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    birchesGroup: {
      title: "",
      paragraph: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    subscriptions: [
      {
        title: "",
        paragraph: [""],
        button: {
          text: "",
          link: "",
        },
      },
    ],
  },
  // metaTags: {}, // Optional empty metaTags object
};
export default initialSalarySurveyPage;
