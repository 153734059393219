<script setup lang="ts">
import Button from "@/base-components/Button";
import { FormInput, FormLabel } from "@/base-components/Form";
import { onMounted, ref, watch, computed } from "vue";
// import {useRoute} from "vue-router";
import { LIST_ASSESSMENTS } from "@/lib/graphql/queries/assessment";
import { useLazyQuery, useMutation } from "@vue/apollo-composable";
import { DELETE_ASSESSMENTS } from "@/lib/graphql/mutations/assessment";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import { useLocalize } from "@/locale/i18n";
import { usePagination } from "@/lib/pagination";
import {
  columns,
  rows,
  actionsDropdown,
  filterFields,
  toolbar,
} from "./tableConfig";
// import AddAssessmentsModal from "@/components/Modals/AddAssessmentsModal.vue";

// Localization
const { isRTL, i18n, language } = useLocalize();

type AssesmentApi = {
  listAssessments: {
    assessment: IAssessmentsPage[];
    totalRecords: number;
  };
};

const {
  loading: fetchLoading,
  result,
  refetch,
  load,
} = useLazyQuery<AssesmentApi>(LIST_ASSESSMENTS, null, {
  fetchPolicy: "no-cache",
  notifyOnNetworkStatusChange: true,
});

const { getPaginationVariables, pagination } = usePagination<AssesmentApi>(
  result,
  "listAssessments",
  refetch
);

load(null, { pagination: getPaginationVariables() });

const { mutate: deleteAssesment, loading: deleteLoading } =
  useMutation(DELETE_ASSESSMENTS);

const loading = computed<boolean>(
  () => fetchLoading.value || deleteLoading.value
);

const assesments = computed<Object[]>(() => {
  const EnAssesments = result.value?.listAssessments.assessment.map(
    (assesment) => {
      return { id: assesment.id, ...assesment.EnAssessmentsPage };
    }
  );

  const ArAssesments = result.value?.listAssessments.assessment.map(
    (assesment) => {
      return { id: assesment.id, ...assesment.ArAssessmentsPage };
    }
  );

  if (language.value === "en") {
    return EnAssesments;
  } else {
    return ArAssesments;
  }
});

const handleDelete = async (assessmentId: string) => {
  await deleteAssesment({ id: assessmentId });
  await refetch();
};

onMounted(async () => {
  document.title =
    i18n("routes.app") + " | " + i18n("routes.website-assessments");
});
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <!-- Begin Faqs -->
        <!--services Title En and Ar -->
        <data-list
          tableTitle="routes.assessments"
          createLink="website-assessments-new"
          :rows="assesments"
          :columns="columns"
          :loading="loading"
          :withPagination="true"
          :pagination="pagination"
          class="!px-0 !mt-0"
        >
          <!-- <template #deleteAll="{ selected }">
            <div @click="selected" class="flex items-center gap-2">
              <Icon icon="solar:trash-bin-trash-broken" class="w-4 h-4" />{{
                $t("common.delete")
              }}
            </div>
          </template> -->
          <template #default="{ row }">
            <router-link
              class="flex items-center gap-2 whitespace-nowrap"
              :to="`website-assessments/${row?.id}`"
            >
              <Icon icon="solar:square-top-down-broken" class="h-5 w-5" />
              <!-- Open -->
            </router-link>
            <DeleteModal @delete="handleDelete(row?.id)" />
          </template>
        </data-list>

        <!-- End Faqs -->
      </div>

      <!-- END: Form Layout -->
    </div>
  </div>
</template>
