<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const assessmentsPageData = inject<ComputedRef<IPsychometricAssessmentsPage>>(
  "assessmentsPageData"
)!;

const objAccessor =
  inject<ComputedRef<IPsychometricAssessmentsObject>>("objAccessor")!;
</script>

<template>
  <div class="mb-5">
    <h2 class="text-lg mt-12 font-medium">
      {{ $t("website.services.assessments.centeralTest") }}
    </h2>
  </div>

  <div class="mt-4">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.title") }}</FormLabel
        >

        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="assessmentsPageData[objAccessor].centralTest.title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.paragraph") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="assessmentsPageData[objAccessor].centralTest.paragraph"
        />
      </div>
    </div>
  </div>

  <div class="mt-4">
    <h4 class="font-medium mb-4">
      {{ $t("common.media") }}
    </h4>
    <SeoImageInput
      class="mb-4"
      v-model="assessmentsPageData[objAccessor].centralTest.image"
    />
  </div>
</template>
