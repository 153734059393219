import UserField from "./UserField.vue";
import StringField from "./StringField.vue";
import ActionField from "./ActionField.vue";
import DateField from "./DateField.vue";
import ImgField from "./ImgField.vue";
import MultipleImageField from "./MultipleImageField.vue";
import NumberField from "./NumberField.vue";
import ObjectSubField from "./ObjectSubField.vue";
import PriceField from "./PriceField.vue";
import ProductField from "./ProductField.vue";
import RatingField from "./RatingField.vue";
import StatusField from "./StatusField.vue";
import LocalizedStringField from "./LocalizedStringField.vue";
import TruncatedText from "./TruncatedText.vue";

const TableFields = Object.assign(
  {},
  {
    user: UserField,
    string: StringField,
    action: ActionField,
    status: StatusField,
    date: DateField,
    img: ImgField,
    imgs: MultipleImageField,
    number: NumberField,
    object: ObjectSubField,
    price: PriceField,
    product: ProductField,
    rating: RatingField,
    localized: LocalizedStringField,
    truncatedText: TruncatedText,
  }
);

export default TableFields;
