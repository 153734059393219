<template>
  <div class="flex w-40">
    <div class="w-10 h-10 image-fit zoom-in" :class="{ '-ml-5': index !== 0 }"
      v-for="(img, index) in row[column.field] || []" :key="index">
      <Tippy tag="img" alt="Tawzeef CopyRights" class="rounded-full" :src="GenericModel.presenterImg(img)"
        :content="img.name || i18n('products.table.picture')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import IColumn from "@/types/TableColumn";
import { GenericModel } from "@/lib/model/generic-model";
import { useLocalize } from "@/locale/i18n";

const { i18n } = useLocalize();

interface IRow {
  [key: string]: any;
}

interface IProps {
  row: IRow;
  column: IColumn;
}

defineProps<IProps>();
</script>
