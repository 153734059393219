<template>
  <div
    class="flex gap-2 bg-white rounded-md items-center px-3 border-slate-200 border"
  >
    <input
      :value="modelValue"
      @input="handleInputChange"
      @keydown.enter.exact.prevent="$emit('doFilter')"
      type="number"
      class="min-w-[6rem] placeholder:text-sm py-2 px-0 2xl:w-full shadow-none border-none !ring-0 text-sm"
    />
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(["doFilter", "update:modelValue"]);
const handleInputChange = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  emit("update:modelValue", parseInt(value));
};

defineProps(["modelValue"]);
</script>
