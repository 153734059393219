import gql from "graphql-tag";
import { SettingsModelFragment } from "../fragments/settings-fragments";

export const SET_SETTINGS = gql`
  mutation UPDATE_HR($data: settingsUpdate!) {
    updateSettings(data: $data) {
      ...SettingsModelFragment
    }
  }
  ${SettingsModelFragment}
`;
