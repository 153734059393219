<script setup lang="ts">
import Button from "@/base-components/Button";
import { useLocalize } from "@/locale/i18n";
import Tab from "@/base-components/Headless/Tab";
import SeoMetaFields from "@/base-components/SeoMetaFields/SeoMetaFields.vue";
import { onMounted, watch, ref, computed } from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { GET_META_TAG } from "@/lib/graphql/queries/seo";
import { useRoute } from "vue-router";
import { createEmptySeo } from "./emptySEO";
import { watchEffect } from "vue";
import LoadingSpinner from "@/components/Loading";
import {
  SET_META_TAG,
  MetaTagsComponentInput,
} from "@/lib/graphql/mutations/seo";
import { PagesNames } from "@/types/website/seo";
import { toast } from "vue-sonner";

import { merge } from "lodash";

// Localization
const { isRTL, i18n, language } = useLocalize();

const seoData = ref<IMetaTagsComponent>(createEmptySeo());
const lang = ref<"en" | "ar">(language.value);

const route = useRoute();

const componentName = computed(() => {
  const id = route.path.split("/").filter(Boolean).pop() || "";
  return { id };
});

type SeoApi = {
  getMetaTag: IMetaTagsComponent;
};

const { result: seoResult, loading: seoLoading } = useQuery<SeoApi>(
  GET_META_TAG,
  componentName,
  {
    fetchPolicy: "no-cache",
  }
);

const { mutate: setSeo, loading: setSeoLoading } = useMutation(SET_META_TAG);
const handleSubmit = async () => {
  const data: MetaTagsComponentInput = {
    metaTag: seoData.value.metaTag,
    uniqueComponentName:
      seoData.value.uniqueComponentName ||
      (componentName.value.id as PagesNames),
  };
  try {
    await setSeo({ data });
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    toast.error(error.message);
  }
};

const loading = computed(() => seoLoading.value || setSeoLoading.value);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.seo");
});
watch(
  isRTL,
  () => (document.title = i18n("routes.app") + " | " + i18n("routes.seo"))
);

watchEffect(() => {
  if (componentName.value.id) {
    seoData.value = createEmptySeo();
  }
});

watchEffect(() => {
  if (seoResult.value?.getMetaTag) {
    // console.log(seoData.value, "before merge");
    // merge(seoData.value.metaTag, seoResult.value?.getMetaTag.metaTag);
    // console.log(seoData.value, "after merge");

    seoData.value = {
      ...seoData.value,
      ...seoResult.value?.getMetaTag,
    };
  }
});
</script>

<template>
  <div class="mt-5">
    <div class="p-5 intro-y box w-full">
      <div class="mb-8 mt-4 intro-y">
        <h2 class="text-base font-medium">
          {{ $t("seo.customeSeo") }} ( {{ $t("seo.optional") }})
        </h2>
        <p class="text-slate-400 text-xs mt-1 font-normal">
          {{ $t("blogs.customeSeoField") }}
        </p>
      </div>
      <Tab.Group>
        <Tab.List variant="boxed-tabs">
          <Tab>
            <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
              {{ $t("common.english") }}
            </Tab.Button>
          </Tab>
          <Tab>
            <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
              {{ $t("common.arabic") }}
            </Tab.Button>
          </Tab>
        </Tab.List>
      </Tab.Group>
      <div
        class="h-full w-full flex items-center justify-center absolute top-0 left-0"
        v-if="loading"
      >
        <LoadingSpinner />
      </div>
      <SeoMetaFields v-model="seoData.metaTag[lang]" />

      <div class="mt-8 justify-end flex gap-2">
        <!-- <Button type="button" variant="outline-secondary" class="w-24 mr-1">
          {{ $t("common.cancel") }}
        </Button> -->
        <Button
          type="button"
          variant="primary"
          class="w-24"
          :disabled="loading"
          :isLoading="loading"
          @click="handleSubmit"
        >
          {{ $t("common.save") }}
        </Button>
      </div>
    </div>
  </div>
</template>
