<script setup lang="ts">
import IColumn from "@/types/TableColumn";
import { GenericModel } from "@/lib/model/generic-model";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const Languages = ["ar", "en"];

interface IRow {
  [key: string]: any;
}

interface IProps {
  row: IRow;
  column: IColumn;
}

defineProps<IProps>();
</script>
<template>
  <div class="font-medium whitespace-nowrap flex flex-col gap-1">
    <div v-for="(lang, index) in Languages" class="flex gap-2">
      <span class="capitalize flex"> {{ lang }} : </span>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <span class="truncate max-w-[180px]">
              {{
                GenericModel.presenterSingleLanguage(row, column.field, lang)
              }}
            </span>
          </TooltipTrigger>
          <TooltipContent>
            <span>
              {{
                GenericModel.presenterSingleLanguage(row, column.field, lang)
              }}
            </span>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  </div>
</template>
