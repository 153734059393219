import gql from "graphql-tag";
import { BlogFragment } from "../fragments/blog-fragments";

export const UPDATE_BLOG = gql`
  mutation UPDATE_BLOG($data: BlogUpdate!, $blogId: String) {
    updateBlog(data: $data, blogId: $blogId) {
      ...BlogFragment
    }
  }
  ${BlogFragment}
`;



export const DELETE_BLOG = gql`
  mutation DELETE_BLOG($blogId: String!) {
    deleteBlog(blogId: $blogId)
  }
`;
