<script setup lang="ts">
import _ from "lodash";
import { ref, provide, onMounted, watch } from "vue";
import fakerData from "@/lib/faker";
import Button from "../base-components/Button";
import { FormInput, FormSelect } from "../base-components/Form";
import TinySlider, { TinySliderElement } from "../base-components/TinySlider";
import Lucide from "../base-components/Lucide";
import Tippy from "../base-components/Tippy";
import ReportDonutChart from "@/components/ReportDonutChart";
import { Tab } from "../base-components/Headless";
import { useLocalize } from "@/locale/i18n";

const importantNotesRef = ref<TinySliderElement>();

provide("bind[importantNotesRef]", (el: TinySliderElement) => {
  importantNotesRef.value = el;
});

const prevImportantNotes = () => {
  importantNotesRef.value?.tns.goTo("prev");
};
const nextImportantNotes = () => {
  importantNotesRef.value?.tns.goTo("next");
};
// Localization
const { isRTL, i18n } = useLocalize();

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.home");
});
watch(
  isRTL,
  () => (document.title = i18n("routes.app") + " | " + i18n("routes.home"))
);
</script>

<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 2xl:col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8 xl:col-span-9">
          <div class="flex items-center h-10 intro-y">
            <h2 class="mr-5 text-lg font-medium truncate">
              {{ $t("dashboard.generalReport") }}
            </h2>
          </div>
          <div class="mt-5 intro-y">
            <div
              class="relative before:content-[''] before:w-[95%] before:shadow-[0px_3px_5px_#0000000b] before:bg-white/60 before:h-full before:mt-2.5 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70"
            >
              <div class="grid grid-cols-12 box">
                <div
                  class="flex flex-col justify-center col-span-12 px-6 md:px-8 py-8 md:py-12 lg:col-span-4"
                >
                  <Lucide icon="PieChart" class="w-10 h-10 text-pending" />
                  <div
                    class="flex items-center justify-start mt-12 text-slate-600 dark:text-slate-300"
                  >
                    {{ $t("dashboard.totalAnnualVisits") }}
                    <Tippy content="Total value of your sales: $158.409.416">
                      <Lucide icon="AlertCircle" class="w-4 h-4 mx-1.5" />
                    </Tippy>
                  </div>
                  <div class="flex items-center justify-start mt-4">
                    <div class="relative text-2xl font-medium">
                      1,413,102.02
                    </div>
                    <a class="mx-4 text-slate-500" href="">
                      <Lucide icon="RefreshCcw" class="w-4 h-4" />
                    </a>
                  </div>
                  <div class="mt-4 text-xs text-slate-500">
                    {{ $t("dashboard.lastUpdated") }} 1 hour ago
                  </div>
                  <Button
                    variant="outline-secondary"
                    class="relative justify-start mt-12 rounded-full"
                  >
                    {{ $t("dashboard.downloadReports") }}
                    <span
                      class="w-8 h-8 absolute flex justify-center items-center bg-primary text-white rounded-full rtl:left-0 ltr:right-0 top-0 bottom-0 my-auto mx-0.5"
                    >
                      <Lucide
                        icon="ArrowRight"
                        class="w-4 h-4 rtl:rotate-180"
                      />
                    </span>
                  </Button>
                </div>
                <div
                  class="col-span-12 p-6 py-8 md:p-8 border-t border-dashed lg:col-span-8 lg:border-t-0 lg:border-l border-slate-200 dark:border-darkmode-300"
                >
                  <Tab.Group>
                    <Tab.List
                      variant="pills"
                      class="p-1 mx-auto mb-8 border border-dashed rounded-md w-60 border-slate-300 dark:border-darkmode-300"
                      role="tablist"
                    >
                      <Tab>
                        <Tab.Button as="button" class="w-full py-1.5 px-2">
                          {{ $t("dashboard.weekly") }}
                        </Tab.Button>
                      </Tab>
                      <Tab>
                        <Tab.Button as="button" class="w-full py-1.5 px-2">
                          {{ $t("dashboard.Monthly") }}
                        </Tab.Button>
                      </Tab>
                    </Tab.List>
                    <Tab.Panels class="md:px-5 pb-5">
                      <Tab.Panel class="grid grid-cols-12 gap-y-8 gap-x-10">
                        <div class="col-span-6 sm:col-span-6 md:col-span-4">
                          <div class="text-slate-500">
                            {{ $t("dashboard.totalVisits") }}
                          </div>
                          <div class="mt-1.5 flex items-center">
                            <div class="text-base">42</div>
                          </div>
                        </div>
                        <div class="col-span-12 sm:col-span-6 md:col-span-4">
                          <div class="text-slate-500">
                            {{ $t("dashboard.totalUniqueVisits") }}
                          </div>
                          <div class="mt-1.5 flex items-center">
                            <div class="text-base">22</div>
                          </div>
                        </div>
                        <div class="col-span-12 sm:col-span-6 md:col-span-4">
                          <div class="text-slate-500">
                            {{ $t("dashboard.submittedContactForm") }}
                          </div>
                          <div class="mt-1.5 flex items-center">
                            <div class="text-base">72</div>
                          </div>
                        </div>
                        <div class="col-span-12 sm:col-span-6 md:col-span-4">
                          <div class="text-slate-500">
                            {{ $t("dashboard.submittedSubscribeForm") }}
                          </div>
                          <div class="mt-1.5 flex items-center">
                            <div class="text-base">54</div>
                          </div>
                        </div>
                        <div class="col-span-12 sm:col-span-6 md:col-span-4">
                          <div class="text-slate-500">
                            {{ $t("dashboard.averageActiveTimePerVisit") }}
                          </div>
                          <div class="mt-1.5 flex items-center">
                            <div class="text-base">23</div>
                          </div>
                        </div>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <div
          class="col-span-12 row-start-4 mt-6 md:col-span-6 lg:col-span-4 xl:col-span-3 lg:row-start-3 xl:row-start-auto xl:mt-8"
        >
          <div class="flex items-center h-10 intro-y">
            <h2 class="mr-5 text-lg font-medium truncate">
              {{ $t("dashboard.visitsChart") }}
            </h2>
          </div>
          <div
            class="mt-5 before:hidden xl:before:block intro-y relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_5px_#0000000b] before:bg-white/60 before:h-full before:mt-2.5 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70"
          >
            <div class="p-5 box">
              <div class="mt-3">
                <ReportDonutChart :height="196" />
              </div>
              <div class="mx-auto mt-[68px] w-52 sm:w-auto">
                <div class="flex items-center mt-10 justify-between">
                  <div class="flex gap-4 items-center">
                    <div class="w-2 h-2 mr-3 rounded-full bg-pending"></div>
                    <span class="truncate">{{
                      $t("dashboard.totalNonUniqueVisits")
                    }}</span>
                  </div>
                  <span class="font-medium">700</span>
                </div>
                <div class="flex items-center mt-4 justify-between">
                  <div class="flex gap-4 items-center">
                    <div class="w-2 h-2 mr-3 rounded-full bg-warning"></div>
                    <span class="truncate">{{
                      $t("dashboard.totalUniqueVisits")
                    }}</span>
                  </div>
                  <span class="font-medium">300</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->

        <!-- BEGIN: Visitors -->
        <div class="col-span-12 mt-6 md:col-span-6 lg:col-span-4">
          <div class="items-center block h-10 intro-y sm:flex justify-between">
            <h2 class="text-lg font-medium truncate">
              {{ $t("dashboard.onlineVisitorsByCountry") }}
            </h2>
            <FormSelect class="mt-3 sm:mt-0 sm:w-auto !box">
              <option value="daily">{{ $t("dashboard.daily") }}</option>
              <option value="weekly">
                {{ $t("dashboard.weekly") }}
              </option>
              <option value="monthly">
                {{ $t("dashboard.Monthly") }}
              </option>
              <option value="yearly">
                {{ $t("dashboard.yearly") }}
              </option>
            </FormSelect>
          </div>
          <div class="p-5 mt-12 intro-y box sm:mt-5">
            <div
              class="flex pb-3 mb-3 justify-between border-b border-dashed text-slate-500 border-slate-200 dark:border-darkmode-300"
            >
              <div>{{ $t("dashboard.countries") }}</div>
              <div>{{ $t("dashboard.visits") }}</div>
            </div>
            <div class="flex items-center mb-5 justify-between">
              <div class="flex items-center">
                <div>Egypt</div>
              </div>
              <div>40</div>
            </div>
            <div class="flex items-center mb-5 justify-between">
              <div class="flex items-center">
                <div>United states</div>
              </div>
              <div>21</div>
            </div>
            <div class="flex items-center mb-5 justify-between">
              <div class="flex items-center">
                <div>Mexico</div>
              </div>
              <div>500</div>
            </div>
            <div class="flex items-center mb-5 justify-between">
              <div class="flex items-center">
                <div>Germany</div>
              </div>
              <div>3</div>
            </div>
            <div class="flex items-center mb-5 justify-between">
              <div class="flex items-center">
                <div>France</div>
              </div>
              <div>32</div>
            </div>
            <div class="flex items-center mb-5 justify-between">
              <div class="flex items-center">
                <div>Japan</div>
              </div>
              <div>1</div>
            </div>
            <Button
              variant="outline-secondary"
              class="relative w-full mb-2 border-dashed border-slate-300 dark:border-darkmode-300 justify-between"
            >
              <span class="truncate">{{ $t("dashboard.viewFullReport") }}</span>
              <span
                class="w-8 h-8 flex justify-center items-center my-auto mr-0.5"
              >
                <Lucide icon="ArrowRight" class="w-4 h-4 rtl:rotate-180" />
              </span>
            </Button>
          </div>
        </div>
        <!-- END: Visitors -->
      </div>
    </div>
  </div>
</template>
