<template>
  <div class="flex items-center gap-4 !py-4">
    <div class="w-10 h-10 image-fit zoom-in">
      <Tippy tag="img" alt="" class="rounded-lg border-1 border-white shadow-md"
        :src="GenericModel.presenterImgSrc(row, column.productImg || '')" :content="i18n('products.table.picture')" />
    </div>
    <div>
      <router-link class="font-medium whitespace-nowrap" :to="column.link ? column.link + row.id : ''"
        :class="{ 'pointer-events-none': !column.link }">{{
          GenericModel.present(row, column.field, column.localized)
        }}</router-link>
      <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
        {{ GenericModel.present(row, column.category || "", column.subLocalized) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IColumn from "@/types/TableColumn";
import { GenericModel } from "@/lib/model/generic-model";
import { useLocalize } from "@/locale/i18n";

const { i18n } = useLocalize();

interface IRow {
  [key: string]: any;
}

interface IProps {
  row: IRow;
  column: IColumn;
}

defineProps<IProps>();
</script>
