import gql from "graphql-tag";
import {
  ButtonFragment,
  ImageFragment,
  MetaTagsFragment,
} from "./base-fragments";

export const PsychometricAssessmentsBrief = gql`
  fragment PsychometricAssessmentsBrief on PsychometricAssessmentsBrief {
    title
    paragraph
    buttons {
      ...ButtonFragment
    }
    image {
      ...ImageFragment
    }
  }
  ${ButtonFragment}
  ${ImageFragment}
`;

export const PsychometricAssessmentsBenfits = gql`
  fragment PsychometricAssessmentsBenfits on PsychometricAssessmentsBenfits {
    title
    description
    paragraph
    buttons {
      ...ButtonFragment
    }
  }
  ${ButtonFragment}
`;

export const PsychometricAssessmentsCentralTest = gql`
  fragment PsychometricAssessmentsCentralTest on PsychometricAssessmentsCentralTest {
    title
    paragraph
    image {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;
export const PsychometricAssessmentsSection = gql`
  fragment PsychometricAssessmentsSection on AssessmentsSection {
    title
    paragraph
  }
`;

export const ContentPsychometricAssessmentsPageDetail = gql`
  fragment ContentPsychometricAssessmentsPageDetail on ContentPsychometricAssessmentsPage {
    title
    paragraph
    button {
      ...ButtonFragment
    }
    brief {
      ...PsychometricAssessmentsBrief
    }
    benfits {
      ...PsychometricAssessmentsBenfits
    }
    centralTest {
      ...PsychometricAssessmentsCentralTest
    }
    assessments {
      ...PsychometricAssessmentsSection
    }
  }
  ${ButtonFragment}
  ${PsychometricAssessmentsBrief}
  ${PsychometricAssessmentsBenfits}
  ${PsychometricAssessmentsCentralTest}
  ${PsychometricAssessmentsSection}
`;

export const AssessmentsPageFragment = gql`
  fragment AssessmentsPageFragment on PsychometricAssessmentsPage {
    EnPsychometricAssessmentsPage {
      ...ContentPsychometricAssessmentsPageDetail
    }
    ArPsychometricAssessmentsPage {
      ...ContentPsychometricAssessmentsPageDetail
    }
    metaTags {
      ...MetaTagsFragment
    }
  }
  ${ContentPsychometricAssessmentsPageDetail}
  ${MetaTagsFragment}
`;
