import gql from "graphql-tag";
import { AssessmentsPageFragment , AssessmentsListFragment } from "../fragments/assessment-fragments";

export const GET_ASSESSMENT = gql`
  query GET_ASSESSMENT($id: String!) {
    getAssessmentPage(id: $id) {
      ...AssessmentsPageFragment
    }
  }
  ${AssessmentsPageFragment}
`;

export const LIST_ASSESSMENTS = gql`
  query LIST_ASSESSMENTS(
    $pagination: AlgoliaPagination
    $filters: AlgoliaFilters
  ) {
    listAssessments(pagination: $pagination, filters: $filters) {
      totalRecords
      assessment {
        id
        EnAssessmentsPage {
          title
          paragraph
        }
        ArAssessmentsPage {
          title
          paragraph
        }
      }
    }
  }
`;
