<script setup lang="ts">
import Button from "@/base-components/Button";
import AboutUsHero from "./components/AboutUsHero.vue";
import AboutUsBrief from "./components/AboutUsBrief.vue";
import AboutUsVisionAndMission from "./components/AboutUsVisionAndMission.vue";
import AboutUsOurAchievements from "./components/AboutUsOurAchievements.vue";
import AboutUsPartners from "./components/AboutUsPartners.vue";
import AboutOurTeam from "./components/AboutOurTeam.vue";
import LoadingSpinner from "@/components/Loading";

import { initialAboutUsPageWithPartners } from "./initialAbout";
import { GET_ABOUTUS } from "@/lib/graphql/queries/aboutus";
import { SET_ABOUTUS } from "@/lib/graphql/mutations/aboutus";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { toast } from "vue-sonner";
// import { SET_HOMEPAGE } from "@/lib/graphql/mutations";
import { useLocalize } from "@/locale/i18n";
import Tab from "@/base-components/Headless/Tab";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
// Localization
const { isRTL, i18n, language } = useLocalize();

const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IAboutUsObject>(() =>
  lang.value === "ar" ? "ArAboutUsPage" : "EnAboutUsPage"
);

type AboutUsResult = {
  getAboutUsPage: IAboutUsPage;
};

// Fetch AboutUs
const { result: aboutUsPage, loading: getAboutusLoading } =
  useQuery<AboutUsResult>(GET_ABOUTUS, null, {
    fetchPolicy: "no-cache",
  });

const aboutusData = ref<IAboutUsPage>(initialAboutUsPageWithPartners);
watchEffect(() => {
  if (aboutUsPage.value) {
    aboutusData.value = {
      ...aboutusData.value,
      ...aboutUsPage.value.getAboutUsPage,
    };
  }
});

provide("aboutusData", aboutusData);
provide("objAccessor", objAccessor);

// Submit Data
const { mutate: setAboutusPage, loading: setAboutusLoading } =
  useMutation(SET_ABOUTUS);
const handleSubmit = async () => {
  const data = { ...aboutusData.value };
  window.scrollTo({
    top: 120,
    behavior: "auto",
  });
  try {
    await setAboutusPage({ data });
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    toast.error(error.message);
  }
};

const loading = computed(
  () => setAboutusLoading.value || getAboutusLoading.value
);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.website-aboutus");
});
watch(
  isRTL,
  () =>
    (document.title =
      i18n("routes.app") + " | " + i18n("routes.website-aboutus"))
);
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Title -->
      <div class="p-5 intro-y box w-full">
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-lg md:text-xl font-medium">
            {{ $t("website.aboutUs.title") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("website.aboutUs.description") }}
          </p>
        </div>
        <!-- END OF TITLE -->
        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>

        <div
          class="h-screen w-full flex items-center justify-center absolute"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>

        <AboutUsHero />
        <AboutUsBrief />
        <AboutUsVisionAndMission />
        <AboutUsPartners />
        <AboutUsOurAchievements />
        <AboutOurTeam />

        <div class="mt-5 text-right">
          <!-- <Button type="button" variant="outline-secondary" class="w-24 mr-1">
            {{ $t("common.cancel") }}
          </Button> -->
          <Button
            type="button"
            variant="primary"
            class="w-24"
            :disabled="loading"
            :isLoading="loading"
            @click="handleSubmit"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
      <!-- END: Form Layout -->
    </div>
  </div>
</template>
