// import { HomePage, SocialType } from "@/types/website/home";
// change any to contact us page
export const initialContactUsPage: IContactUsPage = {
  ar: {
    title: "",
    paragraph: "",
    lat: "",
    lng: "",
    contactMethods: [
      {
        title: "",
        paragraphs: [""],
        image: {
          alt: "",
          caption: "",
          description: "",
          title: "",
          url: "",
        },
      },
    ],
  },
  en: {
    title: "",
    paragraph: "",
    lat: "",
    lng: "",
    contactMethods: [
      {
        title: "",
        paragraphs: [""],
        image: {
          alt: "",
          caption: "",
          description: "",
          title: "",
          url: "",
        },
      },
    ],
  },
};
