import {
  RouteRecordRaw,
  RouterView,
  createRouter,
  createWebHistory,
} from "vue-router";
import SideMenu from "../layouts/SideMenu/SideMenu.vue";
import ErrorPage from "../pages/ErrorPage.vue";
import Login from "../pages/Login.vue";
import Dashboard from "../pages/Dashboard.vue";
import ForgetPassword from "../pages/ForgetPassword.vue";
import Settings from "@/pages/Settings";
import WebsiteHome from "@/pages/WebsitePages/Home";
import Members from "@/pages/members";
import MembersInfo from "@/pages/members/members-info-page.vue";
import BlogsCategoriesList from "@/pages/BlogsCategories/categories-list-page.vue";
import BlogCategoryView from "@/pages/BlogsCategories/category-form-page.vue";
import Blogs from "@/pages/Blogs";
import BlogsForm from "@/pages/Blogs/blogs-form.vue";
import WebsiteServicesHr from "@/pages/WebsitePages/Services/ServiceHR/HR.vue";
import WebsiteServicesMain from "@/pages/WebsitePages/Services/ServiceMain/Main.vue";
import WebsiteServicesSalaraySurvey from "@/pages/WebsitePages/Services/ServiceSalary/SalaraySurvey.vue";
import WebsiteServicesPsychometricAssessments from "@/pages/WebsitePages/Services/ServicePsychometric/PsychometricAssessments.vue";
import WebsiteServicesRecruitment from "@/pages/WebsitePages/Services/ServiceRecruitment/Recruitment.vue";
import FaqsListPage from "@/pages/WebsitePages/Faqs/faqs-list-page.vue";
import FaqsViewPage from "@/pages/WebsitePages/Faqs/faqs-view-page.vue";
import JobsListPage from "@/pages/WebsitePages/Jobs/view-job.vue";
import FaqsCategoryViewPage from "@/pages/WebsitePages/Faqs/faqs-category-view.vue";

import PartnersListPage from "@/pages/WebsitePages/Partners/partners-list-page.vue";
import PartnersViewPage from "@/pages/WebsitePages/Partners/partners-view-page.vue";
import ClientsListPage from "@/pages/WebsitePages/clients/clients-list-page.vue";
import ClientsFormPage from "@/pages/WebsitePages/clients/clients-form-page.vue";
import JobsPage from "@/pages/WebsitePages/Jobs/Jobs.vue";
import AssessmentsListPage from "@/pages/WebsitePages/Assessments/assessments-list-page.vue";
import AssessmentsViewPage from "@/pages/WebsitePages/Assessments/assessments-view-page.vue";
import WebsiteAboutUs from "@/pages/WebsitePages/AboutUs";
import WebsiteContactUs from "@/pages/WebsitePages/ContactUs";
import WebsiteOursourcing from "@/pages/WebsitePages/Services/ServiceOurSourcing/Oursourcing.vue";
import SEO from "@/pages/Seo/index.vue";
import { Permissions, Roles } from "@/types/Permission";

import { defaultLocale, setEnLang, setArLang } from "../locale/i18n";
const storageLocale = localStorage.getItem("lang");

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: `/${storageLocale || defaultLocale}`,
  },

  {
    path: "/:locale",
    component: SideMenu,
    children: [
      {
        path: "",
        name: "home",
        component: Dashboard,
        meta: {
          auth: true,
          permissions: Permissions.ViewHomeDashboard,
        },
      },

      {
        path: "website-home",
        name: "website-home",
        component: WebsiteHome,
        meta: {
          auth: true,
          permission: " ",
        },
      },
      // FAQ AND FAQ CATEGORIES
      {
        path: "website-faqs",
        name: "website-faqs",
        component: FaqsListPage,
        meta: {
          auth: true,
          permission: Permissions.ViewFaqs,
        },
      },

      {
        path: "website-faqs/:id",
        name: "website-faqs-view",
        component: FaqsViewPage,
        meta: {
          auth: true,
          permission: Permissions.ViewFaqs,
        },
        props: true,
      },

      {
        path: "website-faqCategory/new",
        name: "website-faqCategory-new",
        component: FaqsCategoryViewPage,
        meta: {
          auth: true,
          permission: Permissions.ViewFaqs,
        },
      },
      {
        path: "website-faqCategory/:id",
        name: "website-faqCategory-view",
        props: true,

        component: FaqsCategoryViewPage,
        meta: {
          auth: true,
          permission: Permissions.ViewFaqs,
        },
      },

      // END OF FAQ AND FAQ CATEGORIES
      {
        path: "website-partners",
        name: "website-partners",
        component: PartnersListPage,
        meta: {
          auth: true,
          permission: Permissions.ViewPartners,
        },
      },
      {
        path: "website-partners/new",
        name: "website-partners-new",
        component: PartnersViewPage,
        meta: {
          auth: true,
          permission: Permissions.EditPartners,
        },
      },
      {
        path: "website-partners/:id",
        name: "website-partners-view",
        props: true,

        component: PartnersViewPage,
        meta: {
          auth: true,
          permission: Permissions.EditPartners,
        },
      },
      {
        path: "website-clients",
        name: "website-clients",
        component: ClientsListPage,
        meta: {
          auth: true,
          permission: Permissions.ViewClients,
        },
      },
      {
        path: "website-clients/new",
        name: "website-clients-new",
        component: ClientsFormPage,
        meta: {
          auth: true,
          permission: Permissions.EditClients,
        },
      },
      {
        path: "website-clients/:id",
        name: "website-clients-form",
        props: true,
        component: ClientsFormPage,
        meta: {
          auth: true,
          permission: Permissions.EditClients,
        },
      },

      {
        path: "website-assessments",
        name: "website-assessments",
        component: AssessmentsListPage,
        meta: {
          auth: true,
          permission: " ",
        },
      },
      {
        path: "website-assessments/new",
        name: "website-assessments-new",
        component: AssessmentsViewPage,
        meta: {
          auth: true,
          permission: " ",
        },
      },

      {
        path: "website-assessments/:id",
        name: "website-assessments-view",
        component: AssessmentsViewPage,
        meta: {
          auth: true,
          permission: " ",
        },
        props: true,
      },
      //start

      {
        path: "website-blogsCategories",
        name: "website-blogsCategories",
        component: BlogsCategoriesList,
        meta: {
          auth: true,
          permission: " ",
        },
      },
      {
        path: "website-blogsCategories/new",
        name: "website-blogsCategories-new",
        component: BlogCategoryView,
        meta: {
          auth: true,
          permission: " ",
        },
      },

      {
        path: "website-blogsCategories/:id",
        name: "website-blogsCategories-view",
        component: BlogCategoryView,
        meta: {
          auth: true,
          permission: " ",
        },
        props: true,
      },

      //end

      {
        path: "website-aboutus",
        name: "website-aboutus",
        component: WebsiteAboutUs,
        meta: {
          auth: true,
          permission: " ",
        },
      },
      {
        path: "website-jobs",
        name: "website-jobs",
        component: JobsPage,
        meta: {
          auth: true,
          permission: " ",
        },
      },
      {
        path: "website-jobs/:id",
        name: "website-jobs-view",
        props: true,
        component: JobsListPage,
        meta: {
          auth: true,
          permission: "",
        },
      },
      {
        path: "website-contactus",
        name: "website-contactus",
        component: WebsiteContactUs,
        meta: {
          auth: true,
          permission: " ",
        },
      },
      {
        path: "services-main",
        name: "services-main",
        component: WebsiteServicesMain,
        meta: {
          auth: true,
          permission: " ",
        },
      },
      {
        path: "services-hr",
        name: "services-hr",
        component: WebsiteServicesHr,
        meta: {
          auth: true,
          permission: " ",
        },
      },
      {
        path: "services-psychometricAssessments",
        name: "services-psychometricAssessments",
        component: WebsiteServicesPsychometricAssessments,
        meta: {
          auth: true,
          permission: " ",
        },
      },
      {
        path: "services-salarySurvey",
        name: "services-salarySurvey",
        component: WebsiteServicesSalaraySurvey,
        meta: {
          auth: true,
          permission: " ",
        },
      },
      {
        path: "services-recruitment",
        name: "services-recruitment",
        component: WebsiteServicesRecruitment,
        meta: {
          auth: true,
          permission: " ",
        },
      },
      {
        path: "services-outsourcing",
        name: "services-outsourcing",
        component: WebsiteOursourcing,
        meta: {
          auth: true,
          permission: " ",
        },
      },

      {
        path: "members",
        name: "members",
        component: Members,
        meta: {
          auth: true,
          permission: Permissions.ViewMembers,
        },
      },
      {
        path: "members/:id",
        name: "members-view",
        props: true,
        component: MembersInfo,
        meta: {
          auth: true,
          permission: Permissions.EditMembers,
        },
      },
      {
        path: "blogs",
        name: "blogs",
        component: Blogs,
        meta: {
          auth: true,
          // permission: Permissions.ViewBlogs,
          permission: Permissions.ViewBlogs,
        },
      },
      {
        path: "blogs/new",
        name: "blogs-new",
        component: BlogsForm,
        meta: {
          auth: true,
          permission: Permissions.EditBlog,
        },
      },
      {
        path: "blogs/edit/:id",
        props: true,
        name: "blogs-edit",
        component: BlogsForm,
        meta: {
          auth: true,
          permission: Permissions.EditBlog,
        },
      },
      {
        path: "profile",
        name: "profile",
        component: MembersInfo,
        meta: {
          auth: true,
          permission: Permissions.UpdateMyProfile,
        },
      },
      {
        path: "seo",
        component: RouterView,

        children: [
          {
            path: PagesNames.Home,
            name: "seo-home",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.ContactUs,
            name: "seo-contactus",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.AboutUs,
            name: "seo-aboutus",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.Blogs,
            name: "seo-blogs",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },

          {
            path: PagesNames.Faq,
            name: "seo-faqs",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.Hr,
            name: "seo-hr-consultancy",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },

          {
            path: PagesNames.Jobs,
            name: "seo-jobs",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.Login,
            name: "seo-login",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.ForgetPassword,
            name: "seo-forgetPassword",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.OutSourcing,
            name: "seo-outsourcing",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.PrivacyPolicy,
            name: "seo-privacy-policy",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.Profile,
            name: "seo-profile",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.PsychometricAssessment,
            name: "seo-psychometric-assessments",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.Recruitment,
            name: "seo-recruitment",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.Salary,
            name: "seo-salary-survey",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.SavedJobs,
            name: "seo-saved-jobs",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.Services,
            name: "seo-services",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.TermsAndCondition,
            name: "seo-terms-conditions",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
          {
            path: PagesNames.CreateAccount,
            name: "seo-createAccount",
            component: SEO,
            meta: {
              auth: true,
              permission: " ",
            },
          },
        ],
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
        meta: {
          auth: true,
          permission: " ",
        },
      },
    ],
  },
  {
    path: "/:locale/forget-password",
    name: "forget-password-page",
    component: ForgetPassword,
    meta: {
      unauth: true,
    },
  },
  {
    path: "/:locale/login",
    name: "login-page",
    component: Login,
    meta: {
      unauth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});
router.beforeEach((to, from, next) => {
  const newLocale = to.params.locale;
  const prevLocale = from.params.locale;
  // If the locale hasn't changed, do nothing
  if (newLocale === prevLocale) {
    next();
  }

  if (newLocale == "ar") {
    setArLang();
  } else if (newLocale == "en") {
    setEnLang();
  } else {
    next(`/${storageLocale || defaultLocale}`);
  }
  next();
});

import authGuard from "@/router/AuthGuards";
import { PagesNames } from "@/types/website/seo";
import permissions from "./../types/Permission";
router.beforeResolve(async (to, from, next) => {
  // next() // disable auth guard
  await authGuard.beforeRouteEnter(to, from, next);
});

export default router;
