import gql from "graphql-tag";
import { ImageFragment, MetaTagsFragment } from "./base-fragments";

export const aboutFields = gql`
  fragment aboutFields on PartnersAbout {
    title
    paragraph
    image {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const buttomSectionFields = gql`
  fragment buttomSectionFields on ButtomSection {
    title
    image {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const servicesFields = gql`
  fragment servicesFields on PartnersServices {
    title
    buttomSection {
      ...buttomSectionFields
    }
  }
  ${buttomSectionFields}
`;

// Define a single fragment for PartnerContent
export const partnerContent = gql`
  fragment partnerContent on ContentPartnersPage {
    title
    paragraph
    slug
    image {
      ...ImageFragment
    }
    about {
      ...aboutFields
    }
    services {
      ...servicesFields
    }
  }
  ${ImageFragment}
  ${aboutFields}
  ${servicesFields}
  ${buttomSectionFields}
`;
export const socialLinksFragment = gql`
  fragment socialLinksFragment on SocialLinks {
    twitter
    website
    linkedin
    facebook
    instagram
  }
`;

export const PartnerPageFragment = gql`
  fragment PartnerPageFragment on PartnersPage {
    EnPartnersPage {
      ...partnerContent
    }
    ArPartnersPage {
      ...partnerContent
    }
    metaTags {
      ...MetaTagsFragment
    }
    socialLinks {
      ...socialLinksFragment
    }
  }
  ${partnerContent}
  ${MetaTagsFragment}
  ${socialLinksFragment}
`;
