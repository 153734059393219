<script setup lang="ts">
import { GET_FAQCategory } from "@/lib/graphql/queries/faq";
import { useLocalize } from "@/locale/i18n";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { computed, onMounted, watch, ref } from "vue";
import { allJobs } from "./initialAllJobsSlug";

import LoadingSpinner from "@/components/Loading";
import Button from "@/base-components/Button";
import { FormInput, FormLabel } from "@/base-components/Form";
import Tab from "@/base-components/Headless/Tab";

import { UPDATE_JOB_SLUG } from "@/lib/graphql/mutations/job";
import { GET_JOB_BY_ID } from "@/lib/graphql/queries/job";

import { useRouter } from "vue-router";
import { toast } from "vue-sonner";

// Localization
const { isRTL, i18n, language } = useLocalize();

const props = defineProps<{ id: string }>();
const lang = ref<"en" | "ar">(language.value);

const isEditing = computed<boolean>(() => !!props.id);

const router = useRouter();




const slug = ref("");
const title = ref("");

let getJobLoading = ref(false);

if (isEditing.value) {
  console.log("its editing page");
  const { result, loading } = useQuery<{ getJob: { slug: string } }>(
    GET_JOB_BY_ID,
    { jobId: props.id },
    {
      fetchPolicy: "no-cache",
    }
  );
  getJobLoading = loading;

  watch(
    () => result.value,
    (res) => {
      console.log(res);
      if (res?.getJob) {
        slug.value = res?.getJob?.slug;
        title.value = res?.getJob?.postingTitle;
      }
    }
  );
}

const { mutate: updateJob, loading: updateJobLoading } =
  useMutation(UPDATE_JOB_SLUG);

const handleSubmit = async () => {
  try {
    isEditing.value
      ? await updateJob({ slug: slug.value, id: props.id })
      : await updateJob({ slug: slug.value });
    toast.success(i18n("common.updated"));
    setTimeout(() => {
      router.push({ name: "website-jobs" });
    }, 1000);
  } catch (error: any) {
    toast.error(error.message);
  }
};

const loading = computed<boolean>(
  () => getJobLoading.value || updateJobLoading.value
);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.website-job-view");
});
watch(
  isRTL,
  () =>
  (document.title =
    i18n("routes.app") + " | " + i18n("routes.website-job-view"))
);

//SLUG
const blogUrl = import.meta.env.VITE_WEBSITE_URL;
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <div class="p-5 intro-y box w-full">
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-base font-medium">
            {{ title }}
          </h2>
        </div>

        <div class="h-full w-full flex items-center justify-center absolute z-50" v-if="loading">
          <LoadingSpinner />
        </div>

        <div class="grid-cols-2 gap-3 mt-2 sm:grid">
          <div>
            <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
              {{ $t("blogs.slug") }}
            </FormLabel>
            <FormInput v-model="slug" id="crud-form-1" type="text" class="w-full" />
          </div>
        </div>

        <div class="mt-5 text-right">
          <Button type="button" variant="outline-secondary" class="w-24 mr-1"
            @click="$router.push({ name: 'website-jobs' })">
            {{ $t("common.cancel") }}
          </Button>
          <Button type="button" variant="primary" class="w-24" :disabled="loading" :isLoading="loading"
            @click="handleSubmit">
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
