<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import Tippy from "@/base-components/Tippy";
import Lucide from "@/base-components/Lucide";
import TopBar from "@/components/TopBar";
import MobileMenu from "@/components/MobileMenu";
import { useSideMenuStore } from "@/stores/side-menu";
import { useAuthStore } from "@/stores/auth/auth-store";
import usePermissions from "@/composables/usePermissions";
const { currentUser } = useAuthStore();
import {
  ProvideForceActiveMenu,
  forceActiveMenu,
  Route,
  FormattedMenu,
  nestedMenu,
  linkTo,
  enter,
  leave,
} from "./side-menu";
import { watch, reactive, ref, computed, onMounted, provide } from "vue";

const { hasPermission } = usePermissions();

const route: Route = useRoute();
const router = useRouter();
let formattedMenu = reactive<Array<FormattedMenu | "divider">>([]);
const setFormattedMenu = (
  computedFormattedMenu: Array<FormattedMenu | "divider">
) => {
  Object.assign(formattedMenu, computedFormattedMenu);
};
const sideMenuStore = useSideMenuStore();
const sideMenu = computed(() => nestedMenu(sideMenuStore.menu, route));
const windowWidth = ref(window.innerWidth);

provide<ProvideForceActiveMenu>("forceActiveMenu", (pageName: string) => {
  forceActiveMenu(route, pageName);
  setFormattedMenu(sideMenu.value);
});

watch(sideMenu, () => {
  setFormattedMenu(sideMenu.value);
});

watch(
  computed(() => route.path),
  () => {
    delete route.forceActiveMenu;
  }
);

onMounted(() => {
  setFormattedMenu(sideMenu.value);

  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});
</script>

<template>
  <div
    class="pt-2 pb-7 before:content-[''] before:absolute before:inset-0 before:bg-fixed before:bg-no-repeat before:bg-skew-pattern dark:before:bg-skew-pattern-dark overflow-hidden"
  >
    <MobileMenu />
    <TopBar />
    <div
      :class="[
        'relative',
        'before:content-[\'\'] before:w-[95%] before:z-[-1] before:rounded-xl before:bg-white/[0.12] before:h-full before:-mt-4 before:absolute before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/50',
        // Animation
        'before:translate-y-[35px] before:opacity-0 before:animate-[0.4s_ease-in-out_0.1s_intro-wrapper] before:animate-fill-mode-forwards',
      ]"
    >
      <div
        :class="[
          'translate-y-0 bg-primary flex rounded-xl -mt-[7px] md:mt-0 dark:bg-darkmode-400',
          'before:block before:absolute before:inset-0 before:bg-black/[0.15] before:rounded-xl before:z-[-1]',
          // Animation
          'animate-[0.4s_ease-in-out_0.2s_intro-wrapper] animate-fill-mode-forwards translate-y-[35px]',
        ]"
      >
        <!-- BEGIN: Side Menu -->
        <nav
          class="side-nav hidden md:block w-[105px] xl:w-[250px] px-5 pt-8 pb-16 overflow-x-hidden"
        >
          <ul>
            <!-- BEGIN: First Child -->
            <template v-for="(menu, menuKey) in formattedMenu" :key="menu">
              <section v-if="hasPermission(menu.permission)">
                <li
                  v-if="menu == 'divider'"
                  type="li"
                  :class="[
                    'side-nav__divider my-6',

                    // Animation
                    `opacity-0 animate-[0.4s_ease-in-out_0.1s_intro-divider] animate-fill-mode-forwards animate-delay-${
                      (menuKey + 1) * 10
                    }`,
                  ]"
                  :key="'divider-' + menuKey"
                ></li>

                <li v-else :key="menuKey">
                  <Tippy
                    as="a"
                    :content="menu.title"
                    :options="{
                      placement: 'right',
                    }"
                    :disable="windowWidth > 1260"
                    :href="menu.subMenu
      ? '#'
      : ((pageName: string | undefined) => {
        try {
          return router.resolve({
            name: pageName,
          }).fullPath;
        } catch (err) {
          return '';
        }
      })(menu.pageName)
      "
                    @click="(event: MouseEvent) => {
      event.preventDefault();
      linkTo(menu, router);
      setFormattedMenu([...formattedMenu]);
    }"
                    :class="[
                      menu.active ? 'side-menu side-menu--active' : 'side-menu',

                      // Animation
                      {
                        [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
                          (menuKey + 1) * 10
                        }`]: !menu.active,
                      },
                    ]"
                  >
                    <div class="side-menu__icon">
                      <Icon class="text-xl" :icon="menu.icon" />
                    </div>
                    <div class="side-menu__title">
                      {{ $t(menu.title) }}
                      <div
                        v-if="menu.subMenu"
                        :class="[
                          'side-menu__sub-icon',
                          { 'transform rotate-180': menu.activeDropdown },
                        ]"
                      >
                        <Lucide icon="ChevronDown" />
                      </div>
                    </div>
                  </Tippy>
                  <Transition @enter="enter" @leave="leave">
                    <ul
                      v-if="menu.subMenu && menu.activeDropdown"
                      :class="{ 'side-menu__sub-open': menu.activeDropdown }"
                    >
                      <li
                        v-for="(subMenu, subMenuKey) in menu.subMenu"
                        :key="subMenuKey"
                      >
                        <Tippy
                          v-if="hasPermission(subMenu.permission)"
                          as="a"
                          :content="$t(subMenu.title)"
                          :options="{
                            placement: 'right',
                          }"
                          :disable="windowWidth > 1260"
                          :href="subMenu.subMenu
      ? '#'
      : ((pageName: string | undefined) => {
        try {
          return router.resolve({
            name: pageName,
          }).fullPath;
        } catch (err) {
          return '';
        }
      })(subMenu.pageName)
      "
                          :class="[
                            subMenu.active
                              ? 'side-menu side-menu--active'
                              : 'side-menu',

                            // Animation
                            {
                              [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
                                (subMenuKey + 1) * 10
                              }`]: !subMenu.active,
                            },
                          ]"
                          @click="(event: MouseEvent) => {
      event.preventDefault();
      linkTo(subMenu, router);
      setFormattedMenu([...formattedMenu]);
    }"
                        >
                          <div class="side-menu__icon">
                            <Icon class="text-xl" :icon="subMenu.icon" />
                          </div>
                          <div class="side-menu__title">
                            {{ $t(subMenu.title) }}
                            <div
                              v-if="subMenu.subMenu"
                              :class="[
                                'side-menu__sub-icon',
                                {
                                  'transform rotate-180':
                                    subMenu.activeDropdown,
                                },
                              ]"
                            >
                              <Lucide icon="ChevronDown" />
                            </div>
                          </div>
                        </Tippy>
                        <Transition
                          @enter="enter"
                          @leave="leave"
                          v-if="subMenu.subMenu"
                        >
                          <ul
                            v-if="subMenu.subMenu && subMenu.activeDropdown"
                            :class="{
                              'side-menu__sub-open': subMenu.activeDropdown,
                            }"
                          >
                            <li
                              v-for="(
                                lastSubMenu, lastSubMenuKey
                              ) in subMenu.subMenu"
                              :key="lastSubMenuKey"
                            >
                              <Tippy
                                as="a"
                                :content="lastSubMenu.title"
                                :options="{
                                  placement: 'right',
                                }"
                                :disable="windowWidth > 1260"
                                :href="lastSubMenu.subMenu
      ? '#'
      : ((pageName: string | undefined) => {
        try {
          return router.resolve({
            name: pageName,
          }).fullPath;
        } catch (err) {
          return '';
        }
      })(lastSubMenu.pageName)
      "
                                :class="[
                                  lastSubMenu.active
                                    ? 'side-menu side-menu--active'
                                    : 'side-menu',

                                  // Animation
                                  {
                                    [`opacity-0 translate-x-[50px] animate-[0.4s_ease-in-out_0.1s_intro-menu] animate-fill-mode-forwards animate-delay-${
                                      (lastSubMenuKey + 1) * 10
                                    }`]: !lastSubMenu.active,
                                  },
                                ]"
                                @click="(event: MouseEvent) => {
      event.preventDefault();
      linkTo(lastSubMenu, router);
      setFormattedMenu([...formattedMenu]);
    }"
                              >
                                <div class="side-menu__icon">
                                  <Icon :icon="lastSubMenu.icon" />
                                </div>
                                <div class="side-menu__title">
                                  {{ $t(lastSubMenu.title) }}
                                </div>
                              </Tippy>
                            </li>
                          </ul>
                        </Transition>
                      </li>
                    </ul>
                  </Transition>
                </li>
              </section>
            </template>
            <!-- END: First Child -->
          </ul>
        </nav>
        <!-- END: Side Menu -->
        <!-- BEGIN: Content -->
        <div
          class="px-2 sm:px-4 md:px-[22px] max-w-full md:max-w-auto rounded-xl flex-1 min-w-0 min-h-screen pb-10 shadow-sm bg-gray-100 dark:bg-darkmode-700 before:content-[''] before:w-full before:h-px before:block"
        >
          <RouterView />
        </div>
        <!-- END: Content -->
      </div>
    </div>
  </div>
</template>
