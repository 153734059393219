<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import Button from "@/base-components/Button";
import { onMounted, watch } from "vue";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const AssessmentPageData =
  inject<ComputedRef<IAssessmentsPage>>("AssessmentPageData")!;

const objAccessor = inject<
  ComputedRef<IAssessmentsObject> | IAssessmentsObject
>("objAccessor", "EnAssessmentsPage");

const accessor = computed<IAssessmentsObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});

const handleAddItem = () => {
  const lastIndex = AssessmentPageData.value[accessor.value]?.info.length;
  const title = `${i18n("common.assessment")} ${lastIndex + 1}`;
  AssessmentPageData.value[accessor.value]?.info.push({
    title,
    paragraph: "",
    image: {
      title: "",
      alt: "",
      caption: "",
      description: "",
      url: "",
    },
  });
};

const handleRemoveItem = (index: number) => {
  AssessmentPageData.value[accessor.value]?.info.splice(index, 1);
};

// Localization
</script>

<template>
  <div>
    <h2 class="text-lg mt-12 mb-4 font-medium">
      {{ $t("common.assessmentInfo") }}
    </h2>
  </div>

  <DraggableList
    @removeItem="handleRemoveItem"
    @addItem="handleAddItem"
    :theArray="AssessmentPageData[objAccessor].info"
  >
    <template v-slot="{ index }">
      <div>
        <div class="grid-cols-2 gap-3 mt-2 sm:grid">
          <div>
            <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
              {{ $t("common.title") }}
              <span class="text-slate-500 text-[11px] mx-1">
                {{ $t("common.required") }}
              </span>
            </FormLabel>
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="AssessmentPageData[objAccessor].info[index].title"
            />
          </div>
          <div>
            <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
              {{ $t("common.paragraph") }}
              <span class="text-slate-500 text-[11px] mx-1">
                {{ $t("common.required") }}
              </span>
            </FormLabel>
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="AssessmentPageData[objAccessor].info[index].paragraph"
            />
          </div>
        </div>
      </div>

      <!-- End Hero Title En and Ar -->

      <!--Hero paragraph En and Ar -->

      <div class="mt-4">
        <h4 class="font-medium mb-4">
          {{ $t("common.media") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.requiredImg") }}
          </span>
        </h4>
        <SeoImageInput
          class="mb-4"
          v-model="AssessmentPageData[objAccessor].info[index].image"
          isOptional
        />
      </div>
    </template>
  </DraggableList>
</template>
