import { JobsPage } from "@/types/website/job";

export const initJobsPage: JobsPage = {
  en: {
    title: "",
    paragraph: "",
  },
  ar: {
    title: "",
    paragraph: "",
  },
};

export default initJobsPage;
