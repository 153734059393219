import gql from "graphql-tag";
import { salarySurveyPageFragment } from "../fragments/salarySurvey-fragments";

export const SET_SALARYSURVEY = gql`
  mutation UPDATE_SALARYSURVEY($data: SalarySurveyPageInput!) {
    updateSalarySurveyPage(data: $data) {
      ...salarySurveyPageFragment
    }
  }
  ${salarySurveyPageFragment}
`;
