<template>
  <div class="relative rounded-md overflow-hidden border border-slate-200">
    <div class="absolute flex items-center justify-center w-10 h-full bg-slate-100 text-primary">
      <Icon class="text-lg" icon="solar:calendar-broken" />
    </div>
    <Litepicker :key="language" v-model="date" @update:model-value="emit('update:modelValue', finalDate)" :options="{
      lang: language,
      autoApply: false,
      mobileFriendly: true,
      buttonText: {
        apply: $t('common.apply'),
        cancel: $t('common.cancel'),
        previousMonth: $t('→'),
        nextMonth: $t('←'),
        reset: $t('common.reset'),
      },
      showWeekNumbers: true,
      dropdowns: {
        minYear: 1990,
        maxYear: null,
        months: true,
        years: true,
      },
    }" class="ltr:pl-12 rtl:pr-12 py-2 border-none !text-sm" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useLocalize } from "@/locale/i18n";
import Litepicker from "@/base-components/Litepicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const { language } = useLocalize();
const emit = defineEmits(["doFilter", "update:modelValue"]);
interface IProps {
  modelValue: string;
}
const props = withDefaults(defineProps<IProps>(), {
  modelValue: "",
});
const date = ref(props.modelValue);
const finalDate = computed<string>(() => dayjs(date.value).utc(true).format());
</script>
