<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import DraggableList from "@/base-components/DraggableList";
import Button from "@/base-components/Button";
import { useLocalize } from "@/locale/i18n";
import  ClassicEditor  from "@/base-components/Ckeditor/ClassicEditor.vue";
import Dropzone from "@/base-components/Dropzone";
import Tab from "@/base-components/Headless/Tab";
import LoadingSpinner from "@/components/Loading";
import ContactUsHero from "./components/ContactUsHero.vue";
import ContactUsLocation from "./components/ContactUsLocation.vue";
import ContactUSContacts from "./components/ContactUSContacts.vue";
// import HomeHero from "@/pages/WebsitePages/Home/components/HomeHero.vue";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { GET_CONTACTUS } from "@/lib/graphql/queries/contactus";
import { SET_CONTACTUS } from "@/lib/graphql/mutations/contactus";
import { initialContactUsPage } from "./initialContactUs";
import { toast } from "vue-sonner";

// Localization
const { isRTL, i18n, language } = useLocalize();
const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IContactUsObject>(() =>
  lang.value === "ar" ? "ar" : "en"
);

type ContactUsResult = {
  getContactUs: IContactUsPage;
};

// Fetch HomePage
const { result: contactUsPage, loading: getContactusLoading } =
  useQuery<ContactUsResult>(GET_CONTACTUS, null, {
    fetchPolicy: "no-cache",
  });

const contactUsData = ref<IContactUsPage>(initialContactUsPage);
watchEffect(() => {
  if (contactUsPage.value) {
    contactUsData.value = {
      ...contactUsData.value,
      ...contactUsPage.value.getContactUs,
    };
  }
});

provide("contactUsData", contactUsData);
provide("objAccessor", objAccessor);

// Submit Data
const { mutate: setContactUsPage, loading: setContactusLoading } =
  useMutation(SET_CONTACTUS);
const handleSubmit = async () => {
  const data = { ...contactUsData.value };
  window.scrollTo({
    top: 120,
    behavior: "smooth",
  });
  try {
    await setContactUsPage({ data });
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    toast.error(error.message);
  }
};
const loading = computed(
  () => getContactusLoading.value || setContactusLoading.value
);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.website-home");
});

onMounted(async () => {
  document.title =
    i18n("routes.app") + " | " + i18n("routes.website-contactus");
});
watch(
  isRTL,
  () =>
    (document.title =
      i18n("routes.app") + " | " + i18n("routes.website-contactus"))
);
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->
      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-lg md:text-xl font-medium">
            {{ $t("website.contactUs.title") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("website.contactUs.description") }}
          </p>
        </div>
        <!-- end of  hero section title -->
        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>

        <div
          class="h-screen w-full flex items-center justify-center absolute"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>
        <!--Hero Title En and Ar -->

        <ContactUsHero />

        <ContactUsLocation />

        <ContactUSContacts />

        <br />

        <!-- End Hero paragraph En and Ar -->
        <div class="mt-5 text-right">
          <!-- <Button type="button" variant="outline-secondary" class="w-24 mr-1">
            {{ $t("common.cancel") }}
          </Button> -->
          <Button
            type="button"
            variant="primary"
            class="w-24"
            @click="handleSubmit"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
      <!-- END: Form Layout -->
    </div>
  </div>
</template>
