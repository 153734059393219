import gql from "graphql-tag";
import {
  ButtonFragment,
  ImageFragment,
  MetaTagsFragment,
} from "./base-fragments";

export const salarySurveyBriefCardsSectionFragment = gql`
  fragment SalarySurveyBriefCardsSectionFragment on SalarySurveyBriefCardsSection {
    title
    paragraph
    image {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const salarySurveyBriefFragment = gql`
  fragment SalarySurveyBriefFragment on SalarySurveyBrief {
    title
    paragraph
    buttons {
      ...ButtonFragment
    }
    SalarySurveyCardsSection {
      ...SalarySurveyBriefCardsSectionFragment
    }
  }
  ${ButtonFragment}
  ${salarySurveyBriefCardsSectionFragment}
`;

export const salarySurveyBenefitsFragment = gql`
  fragment SalarySurveyBenefitsFragment on SalarySurveyBenfits {
    title
    paragraph
    buttons {
      ...ButtonFragment
    }
    image {
      ...ImageFragment
    }
  }
  ${ButtonFragment}
  ${ImageFragment}
`;

export const salarySurveyBirchesGroupFragment = gql`
  fragment SalarySurveyBirchesGroupFragment on SalarySurveyBirchesGroup {
    title
    paragraph
    image {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const salarySurveySubscriptionsFragment = gql`
  fragment SalarySurveySubscriptionsFragment on SalarySurveySubscriptions {
    title
    paragraph
    button {
      ...ButtonFragment
    }
  }
  ${ButtonFragment}
`;

export const contentSalarySurveyPageFragment = gql`
  fragment contentSalarySurveyPageFragment on ContentSalarySurveyPage {
    title
    paragraph
    button {
      ...ButtonFragment
    }
    brief {
      ...SalarySurveyBriefFragment
    }
    benfits {
      ...SalarySurveyBenefitsFragment
    }
    birchesGroup {
      ...SalarySurveyBirchesGroupFragment
    }
    subscriptions {
      ...SalarySurveySubscriptionsFragment
    }
  }
  ${ButtonFragment}
  ${salarySurveyBriefFragment}
  ${salarySurveyBenefitsFragment}
  ${salarySurveyBirchesGroupFragment}
  ${salarySurveySubscriptionsFragment}
`;

export const salarySurveyPageFragment = gql`
  fragment salarySurveyPageFragment on SalarySurveyPage {
    EnSalarySurveyPage {
      ...contentSalarySurveyPageFragment
    }
    ArSalarySurveyPage {
      ...contentSalarySurveyPageFragment
    }
    metaTags {
      ...MetaTagsFragment
    }
  }
  ${contentSalarySurveyPageFragment}
  ${MetaTagsFragment}
`;
