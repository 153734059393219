<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { inject, ComputedRef, computed } from "vue";

const partnersPageData =
  inject<ComputedRef<IPartnersPage>>("partnersPageData")!;

const objAccessor = inject<ComputedRef<IPartnersObject>>("objAccessor")!;

</script>

<template>
  <div>
    <h2 class="text-lg mt-12 mb-4 font-medium">
      {{ $t("common.aboutPartner") }}
    </h2>
  </div>
  <div>
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium" htmlFor="crud-form-1">
          {{ $t("common.title") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="partnersPageData[objAccessor].about.title"
        />
      </div>

      <div>
        <FormLabel class="font-medium" htmlFor="crud-form-1">
          {{ $t("common.paragraph") }}

          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span></FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="partnersPageData[objAccessor].about.paragraph"
        />
      </div>
    </div>
  </div>
  <!-- End Hero Title En and Ar -->

  <!--Hero paragraph En and Ar -->

  <div class="mt-4">
    <h4 class="font-medium mb-4">
      {{ $t("common.media") }}
      <span class="text-slate-500 text-[11px] mx-1">
        {{ $t("common.requiredImg") }}
      </span>
    </h4>
    <SeoImageInput
      v-model="partnersPageData[objAccessor].about.image"
      isOptional
    />
  </div>
</template>
