import {
  getAuth,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  User,
} from "firebase/auth";
import crypto from "@/lib/crypto";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { GET_CURRENT_MEMBER } from "@/lib/graphql/queries/members";
import { CurrentMemeberApi } from "@/types/member";
import { provideApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "@/lib/graphql/ApolloClient";

export const fetchLocalCurrentUser = () => {
  const currentUser = localStorage.getItem("currentUser");
  return currentUser ? crypto.decryption(currentUser, "secret-c-u") : null;
};

export const saveLocalCurrentUser = (currentUser: ICurrentUser | null) => {
  if (currentUser) {
    localStorage.setItem(
      "currentUser",
      crypto.encryption(currentUser, "secret-c-u")
    );
  }
};

export const onAuthChanged = (
  callbackSuccess: (user: User | null) => void,
  callbackError: (error: Error) => void
) => onAuthStateChanged(getAuth(), callbackSuccess, callbackError);

export const fetchMe = async (): Promise<IMember | null> => {
  const { result, loading } = provideApolloClient(apolloClient)(() =>
    useQuery<CurrentMemeberApi>(GET_CURRENT_MEMBER, null, {
      fetchPolicy: "no-cache",
    })
  );
  return new Promise<IMember | null>((resolve) => {
    const waitUntilInitInterval = setInterval(() => {
      if (!loading.value) {
        clearInterval(waitUntilInitInterval);
        resolve(result.value?.memberFetchMe || null);
      }
    }, 100);
  });
};

export const signout = async () => {
  await signOut(getAuth());
};

export const signinWithEmailAndPassword = async ({
  email,
  password,
  rememberMe = false,
}: ILogin) => {
  const auth = getAuth();
  rememberMe
    ? await setPersistence(auth, browserLocalPersistence)
    : await setPersistence(auth, browserSessionPersistence);
  return await signInWithEmailAndPassword(auth, email, password);
};

export const sendPasswordResetMail = async (email: string) => {
  const auth = getAuth();
  auth.languageCode = localStorage.getItem("language") || "ar";
  await sendPasswordResetEmail(auth, email);
};
export const changeMyPassword = (oldPassword: string, newPassword: string) => {
  const { mutate, loading } = provideApolloClient(apolloClient)(() =>
    useMutation(
      gql`
        mutation AUTH_CHANGE_PASSWORD($data: ChangePassswrod!) {
          changePassword(data: $data)
        }
      `,
      {
        variables: {
          data: {
            oldPassword,
            newPassword,
          },
        },
      }
    )
  );
  mutate();
  return new Promise<void>((resolve) => {
    const waitUntilInitInterval = setInterval(() => {
      if (!loading.value) {
        clearInterval(waitUntilInitInterval);
        resolve();
      }
    }, 100);
  });
};
