<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import DynamicList from "@/components/ui/dynamic-list";
import Button from "@/base-components/Button";
/////////////////////////////////////////////////////////////////
import DraggableList from "@/base-components/DraggableList";
import { useLocalize } from "@/locale/i18n";
import ClassicEditor from "@/base-components/Ckeditor/ClassicEditor.vue";
import Dropzone from "@/base-components/Dropzone";
import Tab from "@/base-components/Headless/Tab";
import ServiceHeroParagraph from "./Components/ServiceHeroParagraph.vue";
import ServiceAboutUs from "./Components/ServiceAboutUs.vue";
import ServiceStatistics from "./Components/ServiceStatistics.vue";
import ServiceSection from "./Components/ServiceSection.vue";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { GET_SERVICES } from "@/lib/graphql/queries/serviceMain";
import { initialServicesPage } from "./initialServiceMain";
import LoadingSpinner from "@/components/Loading";
import { SET_SERVICES } from "@/lib/graphql/mutations/serviceMain";
import { toast } from "vue-sonner";

// Localization
const { isRTL, i18n, language } = useLocalize();

const servicPageData = ref<IServicePage>(initialServicesPage);
const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IServiceObject>(() =>
  lang.value === "ar" ? "ArServicesPage" : "EnServicesPage"
);

// Fetch HomePage
const { result: servicePage, loading: getServicePageLoading } = useQuery(
  GET_SERVICES,
  null,
  {
    fetchPolicy: "no-cache",
  }
);

provide("servicPageData", servicPageData);
provide("objAccessor", objAccessor);

// Watch for the servicePage result and update servicePageData
watchEffect(() => {
  if (servicePage.value?.getServicesPage) {
    servicPageData.value = {
      ...servicPageData.value,
      ...servicePage.value?.getServicesPage,
    };
  }
});

const { mutate: setServicePage, loading: setServicePageLoading } =
  useMutation(SET_SERVICES);

const handleSubmit = async () => {
  const data = { ...servicPageData.value };
  delete data.metaTags;
  window.scrollTo({
    top: 120,
    behavior: "smooth",
  });
  try {
    await setServicePage({ data });
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    toast.error(error.message);
  }
};

const loading = computed(
  () => setServicePageLoading.value || getServicePageLoading.value
);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.services");
});
watch(
  isRTL,
  () => (document.title = i18n("routes.app") + " | " + i18n("routes.services"))
);
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->

      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <!-- Hero section title -->
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-base font-medium">
            {{ $t("website.services.title") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("website.services.description") }}
          </p>
        </div>
        <!-- end of  hero section title -->
        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>

        <div
          class="h-screen w-full flex items-center justify-center absolute"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>

        <ServiceHeroParagraph />

        <ServiceAboutUs />

        <ServiceStatistics />

        <ServiceSection />

        <div class="mt-20 justify-end flex gap-2">
          <!-- <Button type="button" variant="outline-secondary" class="w-24 mr-1">
            {{ $t("common.cancel") }}
          </Button> -->
          <Button
            type="button"
            variant="primary"
            class="w-24"
            :disabled="loading"
            :isLoading="loading"
            @click="handleSubmit"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
