import gql from "graphql-tag";
import { ImageFragment, MetaTagsFragment } from "./base-fragments";

export const MemberFragment = gql`
  fragment MemberFragment on MemberModel {
    authId
    fullName
    phoneNumber_1
    id
    email
    linkedin
    slug
    profilePic {
      ...ImageFragment
    }
    title
    EnBrief
    ArBrief
    role
    isEnabled
    isDeleted
    createdAt
    updatedAt
    order
    metaTags {
      ...MetaTagsFragment
    }
  }
  ${ImageFragment}
  ${MetaTagsFragment}
`;
