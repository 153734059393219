<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const outsourcingData =
  inject<ComputedRef<IOutsourcingPage>>("outsourcingData")!;

// ! to tell ts iam sure its exist
const objAccessor = inject<ComputedRef<IOutsourcingObject>>("objAccessor")!;

const handleAddItem = () => {
  const lastIndex =
    outsourcingData.value[objAccessor.value]?.payroll.paragraphs.length;
  const title = `${i18n("common.icon")} ${lastIndex + 1}`;
  outsourcingData.value[objAccessor.value].payroll.paragraphs.push("");
};

const handleRemoveItem = (index: number) => {
  outsourcingData.value[objAccessor.value].payroll?.paragraphs.splice(index, 1);
};
</script>

<template>
  <div class="mb-5">
    <h2 class="text-lg mt-12 font-medium">
      {{ $t("website.services.outsourcing.payrollOutsourcing") }}
    </h2>
  </div>

  <div class="mt-4">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.title") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].payroll.title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.description") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].payroll.description"
        />
      </div>
    </div>
  </div>

  <h4 class="font-medium mt-5">
    {{ $t("common.paragraphs") }}
  </h4>
  <DraggableList
    @removeItem="handleRemoveItem"
    @addItem="handleAddItem"
    :theArray="outsourcingData[objAccessor].payroll.paragraphs"
    class="mt-5"
  >
    <template v-slot="{ index }">
      <div class="w-full">
        <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-3 sm:grid">
          <div>
            <FormLabel
              class="!text-slate-400 text-xs font-medium mt-2"
              htmlFor="crud-form-1"
            >
              {{ $t("common.name") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="outsourcingData[objAccessor].payroll.paragraphs[index]"
            />
          </div>
        </div>
      </div>
    </template>
  </DraggableList>
  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.firstCallToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].payroll.buttons[0].text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].payroll.buttons[0].link"
        />
      </div>
    </div>
  </div>

  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.secondCallToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].payroll.buttons[1].text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].payroll.buttons[1].link"
        />
      </div>
    </div>
  </div>
</template>
