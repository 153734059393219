/* eslint-disable */
import { DropzoneFile } from "dropzone";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

function extractExtensionFrom(filename: string) {
  if (!filename) {
    return null;
  }

  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(filename)?.[1];
}
interface CustomFile extends File {
  name: string;
}
interface IUploads {
  publicUrl: any;
  name: any;
}

export function upload(path: string, file: DropzoneFile, name: string) {
  return new Promise<{
    name: string;
    publicUrl: string;
  }>((resolve, reject) => {
    let uploads: IUploads;
    const fileName = `${name || file.name}-${Date.now()}`;
    const fullPath = `${path}/${fileName}`;
    const storage = getStorage();
    const storageRef = ref(storage, fullPath);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        reject(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        uploads = {
          name: file.name,
          publicUrl: downloadURL,
        };
        resolve(uploads);
      }
    );
  });
}

export function deleteFromStorage(
  path: string,
  file: DropzoneFile,
  name: string
): void {
  const fileName = name || file.name;
  const fullPath = `${path}/${fileName}`;
  const storage = getStorage();
  const storageRef = ref(storage, fullPath);
  deleteObject(storageRef)
    .then(() => console.log("file deleted"))
    .catch((err) => console.error(err));
}
