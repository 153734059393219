<template>
  <div class="w-10 h-10 image-fit mx-1">
    <img
      class="!shadow-none rounded"
      :src="GenericModel.presenterImgSrc(row, column.field)"
      alt="avatar"
    />
  </div>
</template>

<script setup lang="ts">
import IColumn from "@/types/TableColumn";
import { GenericModel } from "@/lib/model/generic-model";
import { useLocalize } from "@/locale/i18n";

const { i18n } = useLocalize();

interface IRow {
  [key: string]: any;
}

interface IProps {
  row: IRow;
  column: IColumn;
}
const travelBottom = (row: IRow, fieldName: string) => {
  console.log(fieldName, "fieldname");
  let val;
  if (!fieldName.includes(".")) {
    val = row !== null ? row[fieldName] : null;
  } else {
    val = fieldName.split(".").reduce((acc, prop) => {
      return acc[prop];
    }, row);
  }
  console.log(val);
  return val;
};
defineProps<IProps>();
</script>
