import gql from "graphql-tag";
import {
  ButtonFragment,
  ImageFragment,
  MetaTagsFragment,
} from "./base-fragments";

export const ContactmethodsFragment = gql`
  fragment ContactmethodsFragment on Contactmethods {
    title
    paragraphs
    image {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const ContactUsPageContentFragment = gql`
  fragment ContactUsPageContentFragment on ContactUsPageContent {
    title
    paragraph
    contactMethods {
      ...ContactmethodsFragment
    }
    lat
    lng
  }
  ${ContactmethodsFragment}
`;

export const ContactusPageFragment = gql`
  fragment ContactusPageFragment on ContactUsPage {
    en {
      ...ContactUsPageContentFragment
    }
    ar {
      ...ContactUsPageContentFragment
    }
  }
  ${ContactUsPageContentFragment}
`;
