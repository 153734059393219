import gql from "graphql-tag";
import { HomePageFragment } from "../fragments/homePage-fragments";

export const SET_HOMEPAGE = gql`
  mutation UPDATE_HOMEPAGE($data: HomePageInput!) {
    updateHomePage(data: $data) {
      ...HomePageFragment
    }
  }
  ${HomePageFragment}
`;
