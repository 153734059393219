import { Blog, SocialType } from "@/types/website/blog";

export const initBlog = (): Blog => ({
  en: {
    title: "",
    body: "",
    image: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
    slug: "",
  },
  ar: {
    title: "",
    body: "",
    image: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
    slug: "",
  },

  writenBy: [],

  categories: [],
  metaTags: {
    ar: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
    en: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
  },
});

export default initBlog;
