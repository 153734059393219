<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { inject, ref, ComputedRef, computed } from "vue";

const aboutusData = inject<ComputedRef<IAboutUsPage>>("aboutusData")!;

// ! to tell ts iam sure its exist
const objAccessor = inject<ComputedRef<IAboutUsObject>>("objAccessor")!;
</script>

<template>
  <!--HERO TITLE AND PARAGRAPH  -->
  <div class="mt-6">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("website.home.hero.fields.title") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="aboutusData[objAccessor].title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("website.home.hero.fields.paragraph") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="aboutusData[objAccessor].paragraph"
        />
      </div>
    </div>
  </div>
  <!-- End HERO TITLE AND PARAGRAPH-->

  <!--Call To Action BTN -->
  <div class="mt-4">
    <h4 class="font-medium">
      {{ $t("website.aboutUs.hero.fields.callToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="aboutusData[objAccessor].button.text"
        />
      </div>
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="url"
          class="w-full"
          v-model="aboutusData[objAccessor].button.link"
        />
      </div>
    </div>
  </div>
  <!-- End Call To Action BTN -->
</template>
