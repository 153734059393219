import { SocialType } from "@/types/website/home";

export const initialPartnersPage = (): IPartnersPage => ({
  EnPartnersPage: {
    title: "",
    paragraph: "",
    slug: "",
    image: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
    about: {
      title: "",
      paragraph: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    services: {
      title: "",
      buttomSection: [
        {
          title: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
        },
      ],
    },
  },
  ArPartnersPage: {
    title: "",
    paragraph: "",
    slug: "",
    image: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
    about: {
      title: "",
      paragraph: "",
      image: {
        alt: "",
        caption: "",
        description: "",
        title: "",
        url: "",
      },
    },
    services: {
      title: "",
      buttomSection: [
        {
          title: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
        },
      ],
    },
  },

  metaTags: {
    ar: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
    en: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
  },
  socialLinks: {
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    website: "",
  },
});

export default initialPartnersPage;
