<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const contactUsData = inject<ComputedRef<IContactUsPage>>("contactUsData")!;

const objAccessor = inject<ComputedRef<IContactUsObject>>("objAccessor")!;
</script>

<template>
  <!--Hero Title & paragraph -->
  <div class="mt-6">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.title") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="contactUsData[objAccessor].title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.paragraph") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="contactUsData[objAccessor].paragraph"
        />
      </div>
    </div>
  </div>
  <!-- End Hero Title & paragraph-->
</template>
