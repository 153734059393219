import gql from "graphql-tag";
import {
  FAQPageFragments,
  CategoriesWithTotalRecordsFragments,
} from "../fragments/faq-fragment";
import { MetaTagsFragment } from "@/lib/graphql/fragments/base-fragments";

export const GET_FAQ = gql`
  query {
    getFAQPage {
      ...FAQPageFragments
    }
  }
  ${FAQPageFragments}
`;

export const GET_FAQ_QUESTIONS = gql`
  query GET_FAQ_QUESTIONS($id: String!) {
    getFAQQuestions(id: $id) {
      totalRecords
      question {
        id
        question {
          en
          ar
        }
        answer {
          en
          ar
        }
        categoryId
      }
    }
  }
`;

export const GET_FAQCategories = gql`
  query {
    getFAQCategories {
      ...CategoriesWithTotalRecordsFragments
    }
  }

  ${CategoriesWithTotalRecordsFragments}
`;

export const GET_FAQCategory = gql`
  query GET_FAQCategory($id: String!) {
    getFAQCategory(id: $id) {
      category {
        en
        ar
      }
      slug {
        en
        ar
      }
      metaTags {
        ...MetaTagsFragment
      }
    }
  }
  ${MetaTagsFragment}
`;
