import gql from "graphql-tag";

// Define fragments
export const ButtonFragment = gql`
  fragment ButtonFragment on Button {
    text
    link
  }
`;

export const ImageFragment = gql`
  fragment ImageFragment on Image {
    alt
    caption
    description
    title
    url
  }
`;

export const CardsFragment = gql`
  fragment CardsFragment on Cards {
    title
    paragraph
    image {
      ...ImageFragment
    }
    button {
      ...ButtonFragment
    }
  }
  ${ImageFragment}
  ${ButtonFragment}
`;

export const SocialFragment = gql`
  fragment SocialFragment on Social {
    socialNetwork
    description
    title
    image {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const MetaTagsStructure = gql`
  fragment MetaTagsStructure on MetaTagsStructure {
    robots
    title
    keywords
    canonical
    description
    social {
      ...SocialFragment
    }
  }
  ${SocialFragment}
`;

export const MetaTagsFragment = gql`
  fragment MetaTagsFragment on MetaTags {
    en {
      ...MetaTagsStructure
    }
    ar {
      ...MetaTagsStructure
    }
  }
  ${MetaTagsStructure}
`;

export const StatisticsFragment = gql`
  fragment StatisticsFragment on Statistics {
    title
    number
    paragraph
    image {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;
