<script setup lang="ts">
import errorIllustration from "../assets/images/error-illustration.svg";
import Button from "../base-components/Button";
import { useLocalize } from "@/locale/i18n";
import { onMounted, watch } from "vue";
// Localization
const { isRTL, i18n } = useLocalize();

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.error");
});
watch(
  isRTL,
  () => (document.title = i18n("routes.app") + " | " + i18n("routes.error"))
);
</script>

<template>
  <div class="py-2">
    <div class="container">
      <!-- BEGIN: Error Page -->
      <div class="flex flex-col items-center justify-center h-screen text-center error-page lg:flex-row lg:text-left">
        <div class="-intro-x lg:mr-20">
          <img alt="Tawzeef CopyRights" class="w-[450px] h-48 lg:h-auto" :src="errorIllustration" />
        </div>
        <div class="mt-10 text-white lg:mt-0">
          <div class="font-medium intro-x text-8xl">404</div>
          <div class="mt-5 text-xl font-medium intro-x lg:text-3xl">
            {{ $t("404.title") }}
          </div>
          <div class="mt-3 text-lg intro-x">
            {{ $t("404.desc") }}
          </div>
          <Button class="px-4 py-3 mt-10 text-white border-white intro-x dark:border-darkmode-400 dark:text-slate-200">
            {{ $t("404.backToHome") }}
          </Button>
        </div>
      </div>
      <!-- END: Error Page -->
    </div>
  </div>
</template>
