import { ServicesPage, SocialType } from "@/types/website/services/serviceMain";

export const initialServicesPage: ServicesPage = {
  EnServicesPage: {
    title: "",
    paragraph: "",
    button: { text: "", link: "" },
    aboutUs: {
      title: "",
      paragraph: "",
      button: { text: "", link: "" },
      statistics: [
        {
          title: "",
          number: "",
          paragraph: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
        },
      ],
    },
    services: {
      title: "",
      paragraph: "",
      cards: [
        {
          title: "",
          paragraph: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
          button: { text: "", link: "" },
        },
      ],
    },
  },
  ArServicesPage: {
    title: "",
    paragraph: "",
    button: { text: "", link: "" },
    aboutUs: {
      title: "",
      paragraph: "",
      button: { text: "", link: "" },
      statistics: [
        {
          title: "",
          number: "",
          paragraph: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
        },
      ],
    },
    services: {
      title: "",
      paragraph: "",
      cards: [
        {
          title: "",
          paragraph: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
          button: { text: "", link: "" },
        },
      ],
    },
  },
  metaTags: {
    robots: "",
    canonical: "",
    description: "",
    social: {
      socialNetwork: SocialType.Facebook,
      description: "",
      title: "",
      image: { alt: "", caption: "", description: "", title: "", url: "" },
    },
    title: "",
    keywords: [],
  },
};

export default initialServicesPage;
