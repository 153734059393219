import { AssessmentsPage, SocialType } from "@/types/website/assessment";

export const initAssessments = (): AssessmentsPage => {
  return {
    EnAssessmentsPage: {
      title: "",
      paragraph: "",
      button: { text: "", link: "" },
      info: [
        {
          title: "",
          paragraph: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
        },
      ],
      objective: {
        title: "",
        paragraphs: [""],
      },
      keyFeatures: {
        title: "",
        paragraphs: [""],
      },
      details: {
        title: "",
        paragraphs: [""],
      },
      sample: {
        title: "",
        paragraph: "",
      },
      report: {
        title: "",
        paragraphs: [""],
      },
      slug: "",
    },
    ArAssessmentsPage: {
      title: "",
      paragraph: "",
      button: { text: "", link: "" },
      info: [
        {
          title: "",
          paragraph: "",
          image: {
            alt: "",
            caption: "",
            description: "",
            title: "",
            url: "",
          },
        },
      ],
      objective: {
        title: "",
        paragraphs: [""],
      },
      keyFeatures: {
        title: "",
        paragraphs: [""],
      },
      details: {
        title: "",
        paragraphs: [""],
      },
      sample: {
        title: "",
        paragraph: "",
      },
      report: {
        title: "",
        paragraphs: [""],
      },
      slug: "",
    },

    metaTags: {
      ar: {
        robots: "",
        canonical: "",
        description: "",
        social: [
          {
            title: "",
            description: "",
            image: {
              title: "",
              alt: "",
              caption: "",
              description: "",
              url: "",
            },
            socialNetwork: SocialType.Facebook,
          },
        ],
        title: "",
        keywords: [],
      },
      en: {
        robots: "",
        canonical: "",
        description: "",
        social: [
          {
            title: "",
            description: "",
            image: {
              title: "",
              alt: "",
              caption: "",
              description: "",
              url: "",
            },
            socialNetwork: SocialType.Facebook,
          },
        ],
        title: "",
        keywords: [],
      },
    },
  };
};

export default initAssessments;

// You can now use emptyAssessmentsPage object as a template for initializing new objects.
