<script setup lang="ts">
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { useLocalize } from "@/locale/i18n";
import Button from "@/base-components/Button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAuthStore } from "@/stores/auth/auth-store";
import { storeToRefs } from "pinia";
import { ref } from "vue";
const { doChangeMyPassword } = useAuthStore();
const open = ref(false);
const { loadingChangeMyPassword } = storeToRefs(useAuthStore());

const { i18n } = useLocalize();
const { errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    oldPassword: yup.string().required(() => i18n("form.required")),
    newPassword: yup
      .string()
      .min(8, () => i18n("form.minPasswordLength", { length: 8 }))
      .required(() => i18n("form.required")),
    confirmNewPassword: yup
      .string()
      .required("Please retype your password.")
      .oneOf([yup.ref("newPassword")], () => i18n("form.missMatchPassword")),
  }),
});
const [oldPassword] = defineField("oldPassword");
const [newPassword] = defineField("newPassword");
const [confirmNewPassword] = defineField("confirmNewPassword");

const onsubmit = handleSubmit(async (values: any) => {
  console.log("gg", values);
  const response = await doChangeMyPassword(values);
  if (response) open.value = false;
});
</script>

<template>
  <Dialog v-model:open="open">
    <DialogTrigger as-child>
      <div class="flex items-center gap-2 cursor-pointer">
        <Icon icon="solar:password-broken" class="w-4 h-4" />
        {{ $t("profile.changePassword") }}
      </div>
    </DialogTrigger>
    <DialogContent class="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>
          {{ $t("profile.changePassword") }}
        </DialogTitle>
        <DialogDescription>
          {{ $t("profile.changePasswordDesc") }}
        </DialogDescription>
      </DialogHeader>
      <div class="grid gap-4 py-4">
        <div>
          <Label for="oldPassword">
            {{ $t("profile.currentPassword") }}
          </Label>
          <Input
            v-model="oldPassword"
            :error="errors.oldPassword"
            type="password"
            id="oldPassword"
            class="mt-2"
          />
        </div>
        <div>
          <Label for="newPassword">
            {{ $t("profile.newPassword") }}
          </Label>
          <Input
            v-model="newPassword"
            :error="errors.newPassword"
            type="password"
            id="newPassword"
            class="mt-2"
          />
        </div>
        <div>
          <Label for="confirmNewPassword">
            {{ $t("profile.repeatNewPassword") }}</Label
          >
          <Input
            v-model="confirmNewPassword"
            :error="errors.confirmNewPassword"
            type="password"
            id="confirmNewPassword"
            class="mt-2"
          />
        </div>
      </div>
      <DialogFooter>
        <Button
          :isLoading="loadingChangeMyPassword"
          variant="primary"
          @click="onsubmit()"
        >
          {{ $t("profile.saveChanges") }}</Button
        >
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
