import config from "@/config";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// import { getDatabase } from "firebase/database";

// let appCheck: any | null = null;

const firebaseInit = () => {
  console.log(config)
  const app = initializeApp(config.firebaseConfig);
  getAuth(app);
  getFirestore(app);
  getStorage(app);
  // getDatabase(app);

  // if (import.meta.env.VITE_ENVIRONMENT === "development") {
  //   self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  // }
  // appCheck = initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider(
  //     "6LeaxBspAAAAAKCSsNXUsmEQIDVPZp7juGcK9w7A"
  //   ),
  //   isTokenAutoRefreshEnabled: true,
  // });
};

// export { appCheck };
export default firebaseInit;
