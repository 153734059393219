<script setup lang="ts">
import { computed } from "vue";
import { ChartData, ChartOptions } from "chart.js/auto";
import Chart from "../../base-components/Chart";
import { getColor } from "@/lib/colors";

const props = defineProps<{
  width?: number;
  height?: number;
}>();

const chartData = [70, 30];
const chartColors = () => [getColor("warning", 0.9), getColor("primary", 0.9)];
const data = computed<ChartData>(() => {
  return {
    labels: ["31 - 50 Years old", ">= 50 Years old", "17 - 30 Years old"],
    datasets: [
      {
        data: chartData,
        backgroundColor: "",
        hoverBackgroundColor: "",
        borderWidth: 5,
        borderColor: getColor("white"),
      },
    ],
  };
});

const options = computed<ChartOptions>(() => {
  return {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };
});
</script>

<template>
  <Chart
    type="doughnut"
    :width="props.width"
    :height="props.height"
    :data="data"
    :options="options"
  />
</template>
