import gql from "graphql-tag";
import { OutSourcingPageFragment } from "../fragments/outSourcing-fragments";

export const GET_OUTSOURCING = gql`
  query {
    getOutsourcingPage {
      ...OutSourcingPageFragment
    }
  }
  ${OutSourcingPageFragment}
`;
