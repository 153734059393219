import * as Sentry from "@sentry/browser";

export default function useSentry() {
  const captureSentryError = (error: any, context?: any) => {
    if (context) {
      Sentry.setContext("variables", context);
    }
    Sentry.captureException(error);
  };

  return {
    captureSentryError,
  };
}
