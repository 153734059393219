import gql from "graphql-tag";
import { ClientsFragment } from "../fragments/client-fragments";

export const GET_CLIENTS = gql`
  query GET_CLIENTS($pagination: AlgoliaPagination, $filters: AlgoliaFilters) {
    getClients(pagination: $pagination, filters: $filters) {
      client {
        ...ClientsFragment
      }
      totalRecords
    }
  }
  ${ClientsFragment}
`;

export const GET_CLIENT = gql`
  query GET_CLIENT($id: String!) {
    getClient(id: $id) {
      ...ClientsFragment
    }
  }
  ${ClientsFragment}
`;
