import gql from "graphql-tag";
import {
  ButtonFragment,
  ImageFragment,
  MetaTagsFragment,
  StatisticsFragment,
  CardsFragment,
} from "./base-fragments";

// Define fragments
export const AboutUsFragment = gql`
  fragment AboutUsFragment on AboutUs {
    title
    paragraph
    button {
      ...ButtonFragment
    }
    statistics {
      ...StatisticsFragment
    }
  }
  ${ButtonFragment}
  ${StatisticsFragment}
`;

export const outSourcingFragment = gql`
  fragment outSourcingFragment on OutSourcing {
    title
    paragraph
    button {
      ...ButtonFragment
    }
  }
  ${ButtonFragment}
`;

export const ServicesFragment = gql`
  fragment ServicesFragment on Services {
    title
    paragraph
    cardsInput {
      ...CardsFragment
    }
  }
  ${CardsFragment}
`;

export const TestimonialsFragment = gql`
  fragment TestimonialsFragment on Testimonials {
    review
    reviewerName
    reviewerTitle
    reviewerImage {
      ...ImageFragment
    }
    companyImage {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const ContentHomePageFragment = gql`
  fragment ContentHomePageFragment on ContentHomePage {
    title
    paragraph
    buttons {
      ...ButtonFragment
    }
    images {
      ...ImageFragment
    }
    ourCustomersTitle
    ourCustomers
    aboutUs {
      ...AboutUsFragment
    }
    services {
      ...ServicesFragment
    }
    outSourcing {
      ...outSourcingFragment
    }
    testimonials {
      ...TestimonialsFragment
    }
  }
  ${ButtonFragment}
  ${ImageFragment}
  ${AboutUsFragment}
  ${ServicesFragment}
  ${outSourcingFragment}
  ${TestimonialsFragment}
`;

export const HomePageFragment = gql`
  fragment HomePageFragment on HomePage {
    ArHomePage {
      ...ContentHomePageFragment
    }
    EnHomePage {
      ...ContentHomePageFragment
    }
    metaTags {
      ...MetaTagsFragment
    }
  }
  ${ContentHomePageFragment}
  ${MetaTagsFragment}
`;
