<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const homePageData = inject<ComputedRef<IHomePage>>("homePageData")!;

const objAccessor = inject<ComputedRef<IHomeObject> | IHomeObject>(
  "objAccessor",
  "EnHomePage"
);

const accessor = computed<IHomeObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});

const isAddEntryDisable = computed<Boolean>(() =>
  homePageData.value[accessor.value].images.length > 3 ? true : false
);

const handleAddItem = () => {
  const lastIndex = homePageData.value[accessor.value]?.images.length;
  const title = `${i18n("common.icon")} ${lastIndex + 1}`;
  homePageData.value[accessor.value].images.push({
    title,
    alt: "",
    caption: "",
    description: "",
    url: "",
  });
};

const handleRemoveItem = (index: number) => {
  homePageData.value[accessor.value]?.images.splice(index, 1);
};
</script>

<template>
  <!--Hero Title & paragraph -->
  <div class="mt-6">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("website.home.hero.fields.title") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="homePageData[objAccessor].title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("website.home.hero.fields.paragraph") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="homePageData[objAccessor].paragraph"
        />
      </div>
    </div>
  </div>
  <!-- End Hero Title & paragraph-->
  <!--Hero firstCallToActionName En and Ar -->
  <div class="mt-4">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.firstCallToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="homePageData[objAccessor].buttons[0].text"
        />
      </div>
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="url"
          class="w-full"
          v-model="homePageData[objAccessor].buttons[0].link"
        />
      </div>
    </div>
  </div>
  <!-- End Hero firstCallToActionName En and Ar -->
  <!--Hero secondeCallToActionName En and Ar -->
  <div class="mt-4">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.secondCallToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="homePageData[objAccessor].buttons[1].text"
        />
      </div>
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="url"
          class="w-full"
          v-model="homePageData[objAccessor].buttons[1].link"
        />
      </div>
    </div>
  </div>
  <!-- End Hero secondeCallToActionName En and Ar -->
  <!-- Begin Hero Icons -->
  <div class="mt-7">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.icons") }}
      <span class="text-slate-400 text-xs">
        <!-- (Maximum 4 icons) -->
        {{ $t("website.home.hero.maximumIcons") }}
      </span>
    </h4>
    <!-- <p class="text-slate-400 text-xs">Maximum 4 icons</p> -->
    <DraggableList
      @removeItem="handleRemoveItem"
      @addItem="handleAddItem"
      :theArray="homePageData[accessor].images"
      :isAddEntryDisabled="isAddEntryDisable"
      class="mt-5"
    >
      <template v-slot="{ index }">
        <div class="mt-3" :key="accessor">
          <div>
            <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
              {{ $t("website.home.services.fields.media") }}</FormLabel
            >
            <SeoImageInput v-model="homePageData[accessor].images[index]" />
          </div></div
      ></template>
    </DraggableList>
  </div>
  <!-- End Hero Icons -->
</template>
