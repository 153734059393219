import { getLanguage } from "@/locale/i18n";
import { AuthToken } from "@/stores/auth/auth-token";
import {
  ApolloClient,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import useSentry from "@/composables/useSentry";
const { captureSentryError } = useSentry();
const getHeaders = async () => {
  const language = getLanguage();
  const token = await AuthToken.get();
  //   const appCheckToken = await AuthToken.getAppCheckToken();

  return {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    // "X-Firebase-AppCheck": appCheckToken ? appCheckToken.token : "",
    authorization: token ? `Bearer ${token}` : "",
    "Accept-Language": language,
    "application-type": "web",
  };
};

// Create an error link
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  console.log("Error link");
  const { variables, query } = operation;
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      // You can add custom logic here, such as redirecting to a login page on authentication errors
      console.log("variables", variables, "query", query);
      captureSentryError(message, {
        variables,
      });
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    console.log("variables", variables, "query", query);
    captureSentryError(networkError, {
      variables,
    });
    // Handle network errors, for instance, by showing a user-friendly message
  }
});

const cache = new InMemoryCache({
  addTypename: false,
});

const retryLink = new RetryLink({
  attempts: {
    max: 2,
  },
});

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: "no-cache",
  },
  mutate: {
    fetchPolicy: "no-cache",
  },
};

const authLink = setContext(async (_, { headers }) => {
  const newHeaders = await getHeaders();

  return {
    headers: {
      ...headers,
      ...newHeaders,
    },
  };
});

const httpLink = createHttpLink({
  uri: `${import.meta.env.VITE_API_ENDPOINT}`,
});

const link = ApolloLink.from([errorLink, httpLink, retryLink, authLink]);

export const apolloClient = new ApolloClient({
  cache,
  defaultOptions,
  link: errorLink.concat(retryLink.concat(authLink.concat(httpLink))),
});
