<script setup lang="ts">
import { computed } from "vue";
import Lucide from "../../base-components/Lucide";
import Breadcrumb from "../../base-components/Breadcrumb";
import { Menu } from "../../base-components/Headless";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/auth/auth-store";
const { currentUser, doSignout } = useAuthStore();
const route = useRoute();
const Breadcrumbs = computed(() => {
  const routes = route.matched;
  return routes
    .map((route) => ({
      text: route.meta.breadcrumb || route.name,
      route: route.path,
    }))
    .filter((crumb) => crumb.text && crumb.text != "home");
});
</script>

<template>
  <!-- BEGIN: Top Bar -->
  <div
    class="top-bar-boxed h-[70px] z-[51] relative border-b border-white/[0.15] mt-12 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 md:pt-0 mb-8"
  >
    <div class="flex items-center h-full gap-5">
      <!-- BEGIN: Logo -->
      <RouterLink :to="{ name: 'home' }" class="hidden -intro-x md:flex">
        <img
          alt="Icewall Tailwind HTML Admin Template"
          class="w-28"
          src="@/assets/images/logo.png"
        />
      </RouterLink>
      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->

      <Breadcrumb
        light
        class="h-full ltr:md:ml-10 md:pl-10 rtl:md:mr-10 md:border-l border-white/[0.15] ltr:mr-auto rtl:ml-auto -intro-x"
      >
        <Breadcrumb.Link :active="true" to="/">{{
          $t(`routes.home`)
        }}</Breadcrumb.Link>
        <Breadcrumb.Link
          v-for="(crumb, index) in Breadcrumbs"
          :key="index + 1"
          :index="index + 1"
          to="/"
          >{{ $t(`routes.${crumb.text}`) }}</Breadcrumb.Link
        >
      </Breadcrumb>
      <!-- END: Breadcrumb -->
      <!-- BEGIN: Notifications -->
      <!-- END: Notifications -->
      <langSwitcher />
      <!-- BEGIN: Account Menu -->
      <Menu>
        <Menu.Button
          class="block w-8 h-8 overflow-hidden scale-110 rounded-full shadow-lg image-fit zoom-in intro-x"
        >
          <img
            alt="Tawzeef CopyRights"
            :src="
              currentUser?.profilePic?.url ||
              '@/assets/images/photoPlaceholder.svg'
            "
          />
        </Menu.Button>
        <Menu.Items
          class="w-56 mt-px relative bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white"
        >
          <Menu.Header class="font-normal">
            <div class="font-medium">{{ currentUser?.fullName }}</div>
            <div class="text-xs text-white/70 mt-0.5 dark:text-slate-500">
              {{ currentUser?.title }}
            </div>
          </Menu.Header>
          <Menu.Divider class="bg-white/[0.08]" />
          <Menu.Item class="hover:bg-white/10">
            <div
              @click="
                $router.push({
                  name: 'profile',
                })
              "
              class="w-full flex gap-2 items-center"
            >
              <Lucide icon="User" class="w-4 h-4" />{{ $t("common.profile") }}
            </div>
          </Menu.Item>

          <Menu.Divider class="bg-white/[0.08]" />
          <Menu.Item class="hover:bg-white/10 flex gap-2">
            <div @click="doSignout()" class="w-full flex gap-2 items-center">
              <Lucide icon="ToggleRight" class="w-4 h-4" />
              {{ $t("common.logout") }}
            </div>
          </Menu.Item>
        </Menu.Items>
      </Menu>
      <!-- END: Account Menu -->
    </div>
  </div>
  <!-- END: Top Bar -->
</template>
