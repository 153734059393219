import gql from "graphql-tag";
import { MetaTagsFragment } from "./base-fragments";

export const MetaTagsComponent = gql`
  fragment MetaTagsComponent on MetaTagsComponent {
    id
    metaTag {
      ...MetaTagsFragment
    }
    uniqueComponentName
  }
  ${MetaTagsFragment}
`;
