const firebaseConfig = {
  apiKey: "AIzaSyAxiaoNwpwCsrBEZxIHgIkf4Es9nQ0XthQ",
  authDomain: "tawzef-staging.firebaseapp.com",
  projectId: "tawzef-staging",
  storageBucket: "tawzef-staging.appspot.com",
  messagingSenderId: "222263250735",
  appId: "1:222263250735:web:06f45850202cf258d49bda",
  measurementId: "G-XZT990596K",
};

export default {
  firebaseConfig,
};
