import { computed } from "vue";
import { useAuthStore } from "@/stores/auth/auth-store";
import { storeToRefs } from "pinia";
import { Permissions, Roles } from "@/types/Permission";

// Administrator>>	Control over everything
// Moderator>>	Read & Edit over blog, testimonials, clients, partners, FAQs, team members pages, in terms of content and SEO & Managing Employees
// Employee	>>Conrotl over his profile & his own blog posts only without deleting
// Author	>> Read & Edit over own blog posts with SEO (Not an employee in the team but the author’s name is a direct link to his linkedin profile)

export default function usePermissions() {
  const { currentUser } = storeToRefs(useAuthStore());

  const RolePermissions = {
    [Roles.ADMINISTRATOR]: [
      Permissions.EditBlog,
      Permissions.EditClients,
      Permissions.EditPartners,
      Permissions.EditFaqs,
      Permissions.EditMembers,
      Permissions.DeleteBlog,
      Permissions.ViewBlogs,
    ],
    [Roles.MODERATOR]: [
      Permissions.ViewHomeDashboard,
      Permissions.EditBlog,
      Permissions.ViewBlogs,
      Permissions.DeleteBlog,
      Permissions.ViewWebsiteMenu,
      Permissions.ViewPartners,
      Permissions.EditPartners,
      Permissions.ViewClients,
      Permissions.EditClients,
      Permissions.ViewFaqs,
      Permissions.EditFaqs,
      Permissions.ViewMembers,
      Permissions.EditMembers,
      Permissions.UpdateMyProfile,
      Permissions.ViewSEO,
    ],
    [Roles.EMPLOYEE]: [
      Permissions.UpdateMyProfile,
      Permissions.EditBlog,
      Permissions.ViewBlogs,
      Permissions.ViewHomeDashboard,
    ],
    [Roles.AUTHOR]: [
      Permissions.EditBlog,
      Permissions.ViewBlogs,
      Permissions.DeleteBlog,

      Permissions.UpdateMyProfile,
      Permissions.ViewHomeDashboard,
    ],
  };

  const hasPermission = (routePermission: Permissions) => {
    return (
      RolePermissions[
        currentUser.value?.role as keyof typeof RolePermissions
      ].includes(routePermission) ||
      currentUser.value?.role === Roles.ADMINISTRATOR
    );
    // || to make all permesions available to admin
    // so in case he is admin it also will return true
  };

  return {
    Roles,
    RolePermissions,
    Permissions,
    hasPermission,
  };
}

// =============HOW TO MAKE PERMISSIONS ============
//1- TO MAKE NAVIGATION GUARD BASED ON USER(ROLE) PERMISSION
// - go to object of the route such as viewBlogs route
// - then in meta object set permission property equal Permissions.ViewBlogs
// in permessions of role such as AUTHOR put Permissions.ViewBlogs
// - then in AuthGuards.ts in case in case to.params.permession check if hasPermission(Permissions.ViewBlogs)

//2 - TO HIDE OR SHOW ICON IN SIDEMENU
// - in side-menu.ts >> in store , go to object of each menu
// - set property permission equal Permissions.ViewClients
// -in role such as AUTHOR put Permissions.ViewClients
// - in SideMenu.Vue >>
//<template v-for="(menu, menuKey) in formattedMenu" :key="menu">
// <section v-if="hasPermission(menu.permission)">
//

//3- EX: TO SHOW OR HIDE DELTE BLOG BUTTON
// <Button V-if="hasPermission(Permissions.DeleteBlog)">Delete</Button>
