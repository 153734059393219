<script setup lang="ts">
import { HTMLAttributes, ref, inject, onMounted } from "vue";
import DropzoneJs, { DropzoneOptions } from "dropzone";
import { init } from "./dropzone";

export type ProvideDropzone = (el: DropzoneElement) => void;

export interface DropzoneElement extends HTMLDivElement {
  dropzone: DropzoneJs;
}

interface DropzoneProps extends /* @vue-ignore */ HTMLAttributes {
  options: DropzoneOptions;
  refKey?: string;
}

interface DropzoneEmits {
  (event: "uploadSuccess", downloadURL: string): void;
}

const props = defineProps<DropzoneProps>();
const emit = defineEmits<DropzoneEmits>();

const fileUploadRef = ref<DropzoneElement>();

const bindInstance = (el: DropzoneElement) => {
  if (props.refKey) {
    const bind = inject<ProvideDropzone>(`bind[${props.refKey}]`);
    if (bind) {
      bind(el);
    }
  }
};

const vFileUploadDirective = {
  mounted(el: DropzoneElement) {
    init(el, {
      ...props,
      options: {
        ...props.options,
        onUploadSuccess: (downloadURL: string) => {
          emit("uploadSuccess", downloadURL);
        },
      },
    });
  },
};

onMounted(() => {
  if (fileUploadRef.value) {
    bindInstance(fileUploadRef.value);
  }
});
</script>

<template>
  <div
    ref="fileUploadRef"
    v-file-upload-directive
    class="[&.dropzone]:border-2 [&.dropzone]:border-dashed dropzone [&.dropzone]:border-slate-200 [&.dropzone]:dark:bg-darkmode-600 [&.dropzone]:dark:border-white/5"
  >
    <div class="dz-message flex flex-col items-center justify-center">
      <Icon class="text-6xl text-slate-400" icon="ph:image-thin" />
      <slot></slot>
    </div>
  </div>
</template>
