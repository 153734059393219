import {
  OutsourcingPage,
  SocialType,
} from "@/types/website/services/outsourcing";

export const initialOutsourcingPage: OutsourcingPage = {
  EnOutsourcingPage: {
    title: "",
    paragraph: "",
    button: {
      text: "",
      link: "",
    },
    manPower: {
      title : '', //
      description :'', 
      images: [
        {
          alt: "",
          caption: "",
          description: "",
          title: "",
          url: "",
        },
      ],
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
    payroll: {
      title: "",
      description :'', //
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        { 
          text: "",
          link: "",
        },
      ],
    },
    callCenter: {
      title: "",
     description :'', //

      images: [
        {
          alt: "",
          caption: "",
          description: "",
          title: "",
          url: "",
        },
      ],
      buttons: [ //
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      paragraphs: [""],
    },
  },
  ArOutsourcingPage: {
    title: "",
    paragraph: "",
    button: {
      text: "",
      link: "",
    },
    manPower: {
       title : '',
      description :'',
      images: [
        {
          alt: "",
          caption: "",
          description: "",
          title: "",
          url: "",
        },
      ],
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
    payroll: {
      title: "",
      description :'',
      paragraphs: [""],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
    },
    callCenter: {
      title: "",
      description :'',
      images: [
        {
          alt: "",
          caption: "",
          description: "",
          title: "",
          url: "",
        },
      ],
      buttons: [
        {
          text: "",
          link: "",
        },
        {
          text: "",
          link: "",
        },
      ],
      paragraphs: [""],
    },
  },
  // metaTags: {
  //   robots: "",
  //   canonical: "",
  //   description: "",
  //   social: {
  //     socialNetwork: SocialType.Facebook,
  //     description: "",
  //     title: "",
  //     image: { alt: "", caption: "", description: "", title: "", url: "" },
  //   },
  //   title: "",
  //   keywords: [],
  // },
};

export default initialOutsourcingPage;
