<script setup lang="ts">
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { useLocalize } from "@/locale/i18n";
import Button from "@/base-components/Button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { CREATE_MEMBER } from "@/lib/graphql/mutations/members";
import { toast } from "vue-sonner";

const open = ref(false);

const emit = defineEmits<{
  (e: "added:success"): void;
}>();

const { mutate: createMember, loading } = useMutation(CREATE_MEMBER);

const { i18n } = useLocalize();
const { errors, defineField, handleSubmit, resetForm } = useForm({
  validationSchema: yup.object({
    email: yup
      .string()
      .email(() => i18n("form.enterValidEmail"))
      .required(() => i18n("form.required")),
  }),
});
const [email] = defineField("email");

const onsubmit = handleSubmit(async (values) => {
  const data = {
    email: values.email,
  };
  try {
    const result = await createMember({ data });
    if (result?.data?.createMember?.id) {
      toast.success(i18n("members.memberCreated"));
      emit("added:success");
      open.value = false;
    }
  } catch (error: any) {
    toast.error(error.message || i18n("members.error"));
  }
  resetForm();
});
</script>

<template>
  <Dialog v-model:open="open">
    <DialogTrigger as-child>
      <button
        class="flex items-center px-4 py-2 border border-slate-200 rounded-md gap-1 cursor-pointer"
      >
        <span class="text-sm">
          {{ $t("common.createNew") }}
        </span>
        <Icon class="text-base" icon="ic:round-add" />
      </button>
    </DialogTrigger>
    <DialogContent class="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>
          {{ $t("members.inviteNewMemberTitle") }}
        </DialogTitle>
        <DialogDescription>
          {{ $t("members.inviteNewMemberDesc") }}
        </DialogDescription>
      </DialogHeader>
      <div class="grid gap-4 py-4">
        <div>
          <Label for="email">
            {{ $t("members.email") }}
          </Label>
          <Input
            v-model="email"
            :error="errors.email"
            type="email"
            id="email"
            class="mt-2"
          />
        </div>
      </div>
      <DialogFooter>
        <Button
          :isLoading="loading"
          :disabled="loading"
          variant="primary"
          @click="onsubmit"
        >
          {{ $t("members.send") }}</Button
        >
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
