<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { buildASTSchema } from "graphql";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const servicPageData = inject<IServicePage>(
  "servicPageData",
  {} as IServicePage
);
const objAccessor = inject<ComputedRef<IServiceObject> | IServiceObject>(
  "objAccessor",
  "EnServicesPage"
);

const accessor = computed<IServiceObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});

const handleAddItem = () => {
  const lastIndex = servicPageData.value[accessor.value]?.services.cards.length;
  const title = `${i18n("common.statistics")} ${lastIndex + 1}`;
  servicPageData.value[accessor.value]?.services.cards.push({
    title: "",
    paragraph: "",
    image: {
      title: "",
      alt: "",
      caption: "",
      description: "",
      url: "",
    },
    button: {
      text: "",
      link: "",
    },
  });
};

const handleRemoveItem = (index: number) => {
  servicPageData.value[accessor.value]?.services.cards.splice(index, 1);
};
</script>

<template>
  <div class="mb-3 intro-y pb-4 mt-10">
    <h2 class="text-base font-medium">
      {{ $t("website.home.services.title") }}
    </h2>

    <p class="text-slate-400 text-xs mt-1 font-normal">
      {{ $t("website.home.services.description") }}
    </p>
  </div>

  <div>
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("table.title") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicPageData[objAccessor].services.title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("table.paragraph") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="servicPageData[objAccessor].services.paragraph"
        />
      </div>
    </div>
  </div>

  <div class="mt-4">
    <h4 class="font-medium">
      {{ $t("website.home.services.fields.cards") }}
    </h4>

    <DraggableList
      class="mt-5"
      @removeItem="handleRemoveItem"
      @addItem="handleAddItem"
      :theArray="servicPageData[objAccessor].services.cards"
    >
      <template v-slot="{ index }">
        <div class="w-full">
          <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
            <div>
              <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
                {{ $t("table.title") }}</FormLabel
              >
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="
                  servicPageData[objAccessor].services.cards[index].title
                "
              />
            </div>
            <div>
              <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
                {{ $t("table.paragraph") }}</FormLabel
              >
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="
                  servicPageData[objAccessor].services.cards[index].paragraph
                "
              />
            </div>
          </div>

          <div class="mt-3">
            <div>
              <FormLabel
                class="!text-slate-400 text-xs font-medium mt-2"
                htmlFor="crud-form-1"
              >
                {{ $t("website.home.services.fields.media") }}</FormLabel
              >

              <SeoImageInput
                v-model="
                  servicPageData[objAccessor].services.cards[index].image
                "
              />
            </div>
          </div>
          <h2 class="font-medium mt-6">
            {{ $t("common.callToAction") }}
          </h2>
          <div class="grid-cols-2 gap-3 mt-2 sm:grid">
            <div>
              <FormLabel
                class="!text-slate-400 text-xs font-medium mt-2"
                htmlFor="crud-form-1"
              >
                {{ $t("website.aboutUs.hero.fields.callToAction") }}</FormLabel
              >
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="
                  servicPageData[objAccessor].services.cards[index].button.text
                "
              />
            </div>

            <div>
              <FormLabel
                class="!text-slate-400 text-xs font-medium mt-2"
                htmlFor="crud-form-1"
              >
                {{ $t("common.link") }}</FormLabel
              >
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="
                  servicPageData[objAccessor].services.cards[index].button.link
                "
              />
            </div>
          </div>
        </div>
      </template>
    </DraggableList>
  </div>
</template>
