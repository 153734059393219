<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import Button from "@/base-components/Button";
import { onMounted, watch } from "vue";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const AssessmentPageData = inject<IAssessmentsPage>(
  "AssessmentPageData",
  {} as IAssessmentsPage
);
const objAccessor = inject<
  ComputedRef<IAssessmentsObject> | IAssessmentsObject
>("objAccessor", "EnAssessmentsPage");

const accessor = computed<IAssessmentsObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});

const handleAddItem = () => {
  const lastIndex =
    AssessmentPageData.value[accessor.value]?.objective.paragraphs.length;
  const title = `${i18n("common.statistics")} ${lastIndex + 1}`;
  AssessmentPageData.value[accessor.value]?.objective.paragraphs.push([""]);
};

const handleRemoveItem = (index: number) => {
  AssessmentPageData.value[accessor.value]?.objective.paragraphs.splice(
    index,
    1
  );
};

// Localization
</script>

<template>
  <div>
    <h2 class="text-lg mt-12 mb-4 font-medium">
      {{ $t("common.assessmentObjectives") }}
    </h2>
  </div>
  <div>
    <div class="grid-cols-1 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.title") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="AssessmentPageData[objAccessor].objective.title"
        />
      </div>
    </div>
  </div>
  <h4 class="font-medium mt-4">
    {{ $t("common.paragraphs") }}
  </h4>
  <DraggableList
    @removeItem="handleRemoveItem"
    @addItem="handleAddItem"
    :theArray="AssessmentPageData[objAccessor].objective.paragraphs"
    class="mt-10"
  >
    <template v-slot="{ index }">
      <div class="w-full">
        <div class="grid-cols-1 md:grid-cols-1 gap-3 mt-3 sm:grid">
          <div>
            <FormLabel
              class="!text-slate-400 text-xs font-medium mt-2"
              htmlFor="crud-form-1"
            >
              {{ $t("common.paragraph") }}
              <span class="text-slate-500 text-[11px] mx-1">
                {{ $t("common.optional") }}
              </span>
            </FormLabel>
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="
                AssessmentPageData[objAccessor].objective.paragraphs[index]
              "
            />
          </div>
        </div>
      </div>
    </template>
  </DraggableList>
</template>
