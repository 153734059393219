<script setup lang="ts">
import { inject, ComputedRef, computed } from "vue";
import { FormInput, FormLabel } from "@/base-components/Form";

// to handle v-model
const homePageData = inject<ComputedRef<IHomePage>>("homePageData")!;
const objAccessor = inject<ComputedRef<IHomeObject> | IHomeObject>(
  "objAccessor",
  "EnHomePage"
);
</script>

<template>
  <!-- Begin About us section -->
  <div class="my-20">
    <div class="mb-3 intro-y pb-4 mt-10">
      <h2 class="text-base font-medium">
        {{ $t("website.home.outsourcing.title") }}
      </h2>

      <p class="text-slate-400 text-xs mt-1 font-normal">
        {{ $t("website.home.outsourcing.description") }}
      </p>
    </div>
    <!--About us Title En and Ar -->
    <div>
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.home.outsourcing.fields.title") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="homePageData[objAccessor].outSourcing.title"
          />
        </div>
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.home.outsourcing.fields.paragraph") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="homePageData[objAccessor].outSourcing.paragraph"
          />
        </div>
      </div>
    </div>
    <!-- End About us Title En and Ar -->
    <!--About us call to action  -->
    <div class="mt-4">
      <h4 class="font-medium">
        {{ $t("website.home.aboutUs.fields.callToAction") }}
      </h4>
      <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.name") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="homePageData[objAccessor].outSourcing.button.text"
          />
        </div>
        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.link") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="homePageData[objAccessor].outSourcing.button.link"
          />
        </div>
      </div>
    </div>
    <!-- End About us call to action  -->
  </div>
</template>
