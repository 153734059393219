import gql from "graphql-tag";
import { SettingsModelFragment } from "../fragments/settings-fragments";

export const GET_SETTINGS = gql`
  query {
    getSettings {
      ...SettingsModelFragment
    }
  }
  ${SettingsModelFragment}
`;
