import gql from "graphql-tag";
import { FAQPageFragments } from "../fragments/faq-fragment";

export const SET_FAQ = gql`
  mutation UPDATE_FAQ($data: FAQPageInput!) {
    updateFAQPage(data: $data) {
      ...FAQPageFragments
    }
  }
  ${FAQPageFragments}
`;

export const UPDATE_FAQ_CATEGORY = gql`
  mutation UPDATE_FAQ_CATEGORY($data: CategoriesInput!, $id: String) {
    updateFAQCategories(data: $data, id: $id) {
      id
      category {
        en
        ar
      }
      slug {
        en
        ar
      }
    }
  }
`;

export const UPDATE_CATEGORY_QUESTION = gql`
  mutation UPDATE_CATEGORY_QUESTION($data: QuestionsInput!, $id: String) {
    updateFAQQuestion(data: $data, id: $id) {
      id
    }
  }
`;

export const DELETE_FAQ_CATEGORY = gql`
  mutation DELETE_FAQ_CATEGORY($id: String!) {
    deleteFAQCategory(id: $id)
  }
`;

export const DELETE_QUESIOTN = gql`
  mutation DELETE_QUESIOTN($id: String!) {
    deleteFAQQuestion(id: $id)
  }
`;


