<template>
  <Select
    v-model="finaValue"
    @update="handleInputChange"
    class="shadow-none justify-start"
  >
    <SelectTrigger
      class="min-w-[155px] shadow-none border-slate-200 border flex gap-2 rounded-md !ring-0 h-auto py-2"
    >
      <SelectValue :placeholder="finaValue" />
    </SelectTrigger>
    <SelectContent>
      <SelectGroup>
        <SelectItem
          v-for="(option, index) in selectedField.options"
          :key="index"
          :value="option.value"
        >
          {{ $t(option.label) }}
        </SelectItem>
      </SelectGroup>
    </SelectContent>
  </Select>
</template>

<script setup lang="ts">
import { ref } from "vue";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const props = defineProps(["modelValue", "selectedField"]);

const emit = defineEmits(["doFilter", "update:modelValue"]);

const finaValue = ref(props.selectedField?.options[0]?.value);

const handleInputChange = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  emit("update:modelValue", value);
};
</script>
