<script setup lang="ts" generic="T">
import Fields from "./TableFields";
import print from "print-js";
import { exportToXlsx, downloadPdf } from "@/lib/helper";
import { ref, withDefaults } from "vue";
import { useLocalize } from "@/locale/i18n";
import { Table } from "@/types/Table";
import Button from "@/base-components/Button";
import TableSkeleton from "./TableSkeleton";
import iconSrc from "@/assets/json/emptybox.json";
import EmptyTable from "./TableEmptyPlaceholder/EmptyTable.vue";
import MobileTable from "./MobileTable.vue";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import ITableColumn from "@/types/TableColumn";
import IFilter from "@/types/TableFilter";

// Localization
const { i18n } = useLocalize();

//custome style for Dropdown Action Buttons
const baseButtonActionStyle =
  "shadow-none py-1.5 sm:py-2 flex md:gap-3 border-slate-200 w-full min-[1150px]:w-auto text-xs md:text-sm gap-1.5";

function getColumnComponentName(type: string) {
  // Map column types to component names
  const componentMap: Record<string, any> = Fields;
  // Return the component name based on the column type
  return componentMap[type] || componentMap.string; // DefaultColumn is string
}
const props = withDefaults(defineProps<Table<T>>(), {
  rows: () => [] as T[],
  tableTitle: "table",
  tableRef: "tableRef",
  tableId: "tablePrint",
  emptyTable: "empty.record",
  withPagination: false,
  pagination: () => ({
    size: 10,
    total: 0,
    hasNext: false,
    hasPrevious: false,
    page: 1,
    changePageSize: () => {},
    nextPage: () => {},
    previousPage: () => {},
  }),
  permissions: () => {
    return {
      hasPermissionToPrint: true,
      hasPermissionToExport: true,
      hasPermissionToDestroy: true,
      hasPermissionToChangeStatus: true,
      hasPermissionToRead: true,
      hasPermissionToEdit: true,
      hasPermissionToCreate: true,
    };
  },
});

const selected = ref<string[]>([]);
const selectAll = ref(false);

const select = () => {
  selected.value = [];
  if (!selectAll.value) {
    props.rows.forEach((element) => {
      selected.value.push(element.id);
    });
  }
};

const clearSelection = () => {
  selected.value = [];
  selectAll.value = false;
};

const onPrint = () => {
  print({
    printable: props.tableId,
    type: "html",
    scanStyles: true,
    targetStyles: ["*"],
    maxWidth: 1000,
    ignoreElements: props.columns.reduce((arr: string[], col: ITableColumn) => {
      if (col.ignorePrint) arr.push(col.ignorePrint);
      if (col.ignorePrintRow) arr.push(col.ignorePrintRow);
      return arr;
    }, []),
  });
};

const tabRef = {
  [props.tableRef]: ref(),
};
const onExportXlsx = () => {
  exportToXlsx(tabRef[props.tableRef].value, props.tableId, props.tableTitle);
};
const onDownloadPdf = () => {
  downloadPdf(tabRef[props.tableRef].value, props.tableTitle);
};
const selectedRow = ref<number | null>(null);
const handleRowClick = (index: number): void => {
  selectedRow.value = index;
};
const emit = defineEmits(["filter", "reset", "inputChange"]);

// const inputChange = (e, col) => {
//   editing.value[col.field].isEditing = false;
//   emit("inputChange", e);
// };
</script>

<template>
  <div class="bg-white pt-4 px-4 sm:px-5 rounded-lg">
    <div
      class="flex justify-between items-center bg-white sm:bg-transparent rounded-lg py-2.5 sm:p-0 sm:mb-5"
    >
      <div
        class="flex flex-col sm:flex-row justify-between items-center"
        v-if="tableTitle !== 'table'"
      >
        <h2 class="text-base sm:text-lg font-medium">{{ $t(tableTitle) }}</h2>
      </div>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <div
      class="flex flex-col min-[1150px]:flex-row items-center justify-between gap-2.5 md:gap-4"
    >
      <TableFilter
        v-if="filter"
        :disabled="loading"
        :filter-fields="filter"
        @filter="(filter: IFilter, orderBy: string) => emit('filter', filter, orderBy)"
        @reset="emit('reset')"
      >
      </TableFilter>

      <div
        class="flex gap-1 sm:gap-2 w-full min-[1150px]:w-auto justify-between items-center lg:justify-end"
        :class="{
          'ltr:ml-auto rtl:mr-auto': !filter,
        }"
      >
        <Button
          @click="
            $router.push({
              name: createLink,
            })
          "
          v-if="createLink"
          class="flex gap-2"
        >
          {{ $t("common.createNew") }}
          <Icon class="text-lg sm:text-xl" icon="solar:add-square-broken" />
        </Button>

        <!-- createNew Feature with slot -->
        <slot name="costumeCreateNew"></slot>
        <Button
          id="tabulator-print"
          :class="baseButtonActionStyle"
          v-if="refreshable"
        >
          <Icon icon="solar:refresh-linear" class="text-xl" />
          <span class="hidden min-[450px]:flex"
            >{{ $t("common.refresh") }}
          </span>
        </Button>

        <DropdownMenu v-if="toolbar">
          <DropdownMenuTrigger>
            <Button
              id="tabulator-print"
              :class="baseButtonActionStyle"
              class="!gap-1"
            >
              {{ $t("common.table.export") }}
              <Icon icon="solar:alt-arrow-down-bold-duotone" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem class="flex gap-2" @click="onExportXlsx">
              <Icon icon="solar:file-download-broken" class="text-xl" />
              {{ $t("xlsx") }}
            </DropdownMenuItem>
            <DropdownMenuItem class="flex gap-2" @click="onPrint">
              <Icon icon="solar:printer-broken" class="text-xl" />
              {{ $t("common.table.print") }}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <DropdownMenu v-if="actionsDropdown">
          <DropdownMenuTrigger>
            <Button id="tabulator-print" :class="baseButtonActionStyle">
              <Icon
                class="text-xl text-slate-500"
                icon="solar:menu-dots-bold-duotone"
              />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>{{ $t("common.actions") }}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem v-for="action in actionsDropdown" :key="action">
              <slot
                :name="action"
                :selected="selected"
                :selectAll="selectAll"
              ></slot>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div
      class="col-span-12 scrollBar overflow-auto mt-4 md:mt-6 rounded-md md:border border-slate-200"
    >
      <table
        :ref="tabRef[tableRef]"
        class="table table-report hidden md:table overflow-hidden"
        :id="tableId"
      >
        <thead v-if="rows.length || loading">
          <tr>
            <th id="ignore-0" v-if="multiSelect">
              <input
                class="rounded border-gray-300 bg-gray-50/25 focus:ring-0 text-primary"
                type="checkbox"
                v-model="selectAll"
                @click="select"
              />
            </th>
            <th class="whitespace-nowrap" id="ignore-0" v-if="indexed">#</th>
            <th
              v-for="col in columns"
              :key="col.field"
              :id="col.ignorePrint"
              class="whitespace-nowrap capitalize ltr:text-left rtl:text-right"
              :class="{ 'text-center': col.align === '!center' }"
            >
              <span> {{ $t(col.label) }}</span>
            </th>
          </tr>
        </thead>
        <tbody v-if="rows.length && !loading" class="intro-y">
          <tr v-for="(row, index) in rows" :key="index" class="intro-x">
            <td id="ignore-00" class="w-10 multi-select" v-if="multiSelect">
              <input
                class="rounded border-gray-300 bg-gray-50/25 focus:ring-0 text-primary"
                type="checkbox"
                :value="row.id"
                v-model="selected"
              />
            </td>
            <td class="h-full mx-5" v-if="indexed">
              <p>{{ index + 1 }}</p>
            </td>
            <td
              v-for="col in columns"
              :key="col.field"
              :class="col.align == 'center' ? 'text-center' : 'text-start'"
              :id="col.ignorePrintRow"
            >
              <!-- TD - Fields -->
              <component
                :is="getColumnComponentName(col.type)"
                :row="row"
                :column="col"
              >
                <slot :row="row"></slot>
              </component>
            </td>
          </tr>
        </tbody>

        <TableSkeleton.DesktopTableSkeleton
          :multiSelect="multiSelect"
          :columnsNumber="columns.length"
          v-if="loading"
        />
      </table>

      <!-- END: Data List -->

      <EmptyTable
        :iconSrc="iconSrc"
        v-if="!rows.length && !loading"
        :label="i18n('common.emptyTable')"
        label-class="text-lg font-medium"
        class="w-full pt-24 pb-40 sm:!py-48 border-t border-slate-200 rounded-t-none"
      />
    </div>

    <div>
      <div class="md:hidden">
        <div v-if="rows.length">
          <MobileTable v-for="(row, index) in rows" :key="index">
            <div class="field" v-for="col in columns" :key="col.field">
              <span class="truncate w-24 min-[400px]:w-36 sm:w-44 capitalize"
                >{{ $t(col.label) }}
              </span>
              <component
                :is="getColumnComponentName(col.type)"
                :row="row"
                :column="col"
              >
                <slot></slot>
              </component>
            </div>
          </MobileTable>
        </div>
        <TableSkeleton.MobileTableSkeleton v-if="loading && rows.length == 0" />
      </div>
      <Pagination
        v-if="rows.length && withPagination"
        :page="pagination.page"
        :pageSize="pagination.size"
        :rowsNumber="pagination.total"
        :hasNext="pagination.hasNext"
        :hasPrev="pagination.hasPrevious"
        :showSizeChanger="true"
        :disabled="!rows.length"
        @next="pagination.nextPage"
        @back="pagination.previousPage"
        @size-changed="pagination.changePageSize"
      />
    </div>
  </div>
</template>

<style scoped>
/* .scrollBar::-webkit-scrollbar {
  background-color: rgb(185, 179, 179);
  height: 12px;
}

.scrollBar::-webkit-scrollbar-thumb {
  background-color: rgb(112, 114, 117);
  border-radius: 10px;
} */
</style>
