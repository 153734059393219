<template>
  <div class="mobile-table-card flex gap-5 py-4 border-t border-t-slate-100">
    <Icon
      class="text-xl sm:text-2xl mt-0.5"
      :class="{ 'rotate-180': isShowen }"
      @click="toggle"
      icon="solar:square-alt-arrow-up-broken"
    />
    <div
      class="left-part grid gap-5 items-center overflow-hidden max-h-[30px] transition-all duration-700 ease-out"
      :class="{ '!max-h-[600px] openAnimation': isShowen }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
const isShowen = ref(false);
const toggle = () => {
  isShowen.value = !isShowen.value;
};
</script>

<style scoped>
:deep(.field) {
  min-height: 23px;
  overflow: hidden;
  display: flex;
  gap: 20px;
  align-items: center;
}

.left-part.openAnimation :deep(.field:not(:first-child)) {
  animation: fadeIn 0.8s ease forwards !important;
}

.left-part:not(.openAnimation) :deep(.field:not(:first-child)) {
  animation: fadeOut 0.2s ease forwards !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
