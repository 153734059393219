<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const objAccessor = inject<ComputedRef<IPartnersObject>>("objAccessor")!;
const partnersPageData =
  inject<ComputedRef<IPartnersPage>>("partnersPageData")!;

const handleAddItem = () => {
  partnersPageData.value[objAccessor.value].services.buttomSection.push({
    title: "",
    image: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
  });
};

const handleRemoveItem = (index: number) => {
  partnersPageData.value[objAccessor.value].services.buttomSection.splice(
    index,
    1
  );
};
</script>

<template>
  <div>
    <h2 class="text-lg mt-12 mb-4 font-medium">
      {{ $t("common.partnerServices") }}
    </h2>
  </div>

  <div>
    <h4 class="font-medium"></h4>
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.title") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="partnersPageData[objAccessor].services.title"
        />
      </div>
    </div>
  </div>

  <DraggableList
    @removeItem="handleRemoveItem"
    @addItem="handleAddItem"
    :theArray="partnersPageData[objAccessor].services.buttomSection"
    class="mt-8"
  >
    <template v-slot="{ index }">
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("common.title") }}
            <span class="text-slate-500 text-[11px] mx-1">
              {{ $t("common.required") }}
            </span>
          </FormLabel>
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="
              partnersPageData[objAccessor].services.buttomSection[index].title
            "
          />
        </div>
      </div>

      <div class="mt-4">
        <h4 class="font-medium mb-4">
          {{ $t("common.media") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.requiredImg") }}
          </span>
        </h4>
        <SeoImageInput
          isOptional
          v-model="
            partnersPageData[objAccessor].services.buttomSection[index].image
          "
        />
      </div>
    </template>
  </DraggableList>
</template>
