import gql from "graphql-tag";
import { PartnerPageFragment } from "../fragments/partners-fragments";

export const GET_PARTNER_PAGE = gql`
  query GET_PARTNER_PAGE($id: String!) {
    getPartnerPage(id: $id) {
      ...PartnerPageFragment
    }
  }
  ${PartnerPageFragment}
`;

export const GET_PARTNERS = gql`
  query GET_PARTNERS($pagination: AlgoliaPagination, $filters: AlgoliaFilters) {
    listPartners(pagination: $pagination, filters: $filters) {
      totalRecords
      partner {
        id
        EnPartnersPage {
          title
          paragraph
        }
        ArPartnersPage {
          title
          paragraph
        }
      }
    }
  }
`;
// export const GET_PARTNERS = gql`
//   query {
//     listPartners {
//       totalRecords
//       partner {
//         ...PartnerPageFragment
//       }
//     }
//   }
//   ${PartnerPageFragment}
// `;
