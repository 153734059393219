import { defineStore } from "pinia";
import { Permissions, Roles } from "@/types/Permission";

export interface Menu {
  icon: string;
  title: string;
  pageName?: string;
  subMenu?: Menu[];
  ignore?: boolean;
  meta?: string;
  permission?: string | Permissions;
}

export interface SideMenuState {
  menu: Array<Menu | "divider">;
}

export const useSideMenuStore = defineStore("sideMenu", {
  state: (): SideMenuState => ({
    menu: [
      {
        icon: "iconamoon:home",
        pageName: "home",
        title: "routes.home",
        permission: Permissions.ViewHomeDashboard,
      },
      {
        icon: "iconoir:internet",
        pageName: "website-home",
        title: "routes.website",
        permission: Permissions.ViewWebsiteMenu,

        subMenu: [
          {
            icon: "iconamoon:home",
            pageName: "website-home",
            title: "routes.home",
            permission: " ",
          },
          {
            icon: "eos-icons:service-outlined",
            pageName: "services",
            title: "routes.services",
            permission: " ",

            subMenu: [
              {
                icon: "iconamoon:home",
                pageName: "services-main",
                title: "routes.services-main",
                permission: " ",
              },
              {
                icon: "healthicons:human-resoruces-outline",
                pageName: "services-hr",
                title: "routes.services-hr",
                permission: " ",
              },
              {
                icon: "ic:outline-assessment",
                pageName: "services-psychometricAssessments",
                title: "routes.services-psychometricAssessments",
                permission: " ",
              },
              {
                icon: "ri:survey-line",
                pageName: "services-salarySurvey",
                title: "routes.services-salarySurvey",
                permission: " ",
              },
              {
                icon: "fluent-mdl2:recruitment-management",
                pageName: "services-recruitment",
                title: "routes.services-recruitment",
                permission: " ",
              },
              {
                icon: "fluent-mdl2:recruitment-management",
                pageName: "services-outsourcing",
                title: "routes.services-outsourcing",
                permission: " ",
              },
            ],
          },
          {
            icon: "mdi:about-circle-outline",
            pageName: "website-aboutus",
            title: "routes.aboutUs",
            permission: " ",
          },
          {
            icon: "lucide:contact-2",
            pageName: "website-contactus",
            title: "routes.contactus",
            permission: " ",
          },
          // {
          //   icon: "lucide:contact-2",
          //   pageName: "website-contactus",
          //   title: "routes.contactUs",
          // },
          {
            icon: "ri:question-answer-line",
            pageName: "website-faqs",
            title: "routes.faqs",
            permission: Permissions.ViewFaqs,
          },
          {
            icon: "fluent-mdl2:assessment-group",
            pageName: "website-assessments",
            title: "routes.assessments",
            permission: " ",
          },
          {
            icon: "material-symbols-light:partner-exchange",
            pageName: "website-partners",
            title: "routes.partners",
            permission: Permissions.ViewPartners,
          },
          {
            icon: "la:users",
            pageName: "website-clients",
            title: "routes.clients",
            permission: Permissions.ViewClients,
          },
          {
            icon: "arcticons:jobstreet",
            pageName: "website-jobs",
            title: "routes.jobs",
            permission: " ",
          },
        ],
      },
      {
        icon: "ph:users-bold",
        pageName: "members",
        title: "routes.members",
        permission: Permissions.ViewMembers,
      },
      {
        icon: "carbon:blog",
        pageName: "website-blogsCategories",
        title: "routes.blogsCategories",
        permission: Permissions.ViewBlogCategories,
      },
      {
        icon: "carbon:blog",
        pageName: "blogs",
        title: "routes.blogs",
        permission: Permissions.ViewBlogs,
      },

      {
        icon: "ri:seo-line",
        pageName: "seo",
        title: "routes.seo",
        permission: Permissions.ViewSEO,
        subMenu: [
          {
            icon: "ri:login-circle-line",
            pageName: "seo-login",
            title: "routes.login",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "material-symbols-light:partner-exchange",
            pageName: "seo-forgetPassword",
            title: "routes.forgetPassword",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "ri:add-circle-line",
            pageName: "seo-createAccount",
            title: "routes.createAccount",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "ri:home-5-line",
            pageName: "seo-home",
            title: "routes.website-home",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "ri:phone-line",
            pageName: "seo-contactus",
            title: "routes.website-contactus",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "ri:information-line",
            pageName: "seo-aboutus",
            title: "routes.website-aboutus",
            permission: Permissions.ViewSEO,
          },

          {
            icon: "ri:settings-3-line",
            pageName: "seo-services",
            title: "routes.website-services",
            permission: Permissions.ViewSEO,
          },

          {
            icon: "ri:settings-5-line",
            pageName: "seo-hr-consultancy",
            title: "routes.services-hr",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "ri:settings-6-line",
            pageName: "seo-psychometric-assessments",
            title: "routes.services-psychometricAssessments",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "ri:survey-line",
            pageName: "seo-salary-survey",
            title: "routes.services-salarySurvey",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "fluent-mdl2:recruitment-management",
            pageName: "seo-recruitment",
            title: "routes.services-recruitment",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "carbon:help-desk",
            pageName: "seo-outsourcing",
            title: "routes.services-outsourcing",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "ri:questionnaire-line",
            pageName: "seo-faqs",
            title: "routes.website-faqs",
            permission: Permissions.ViewSEO,
          },

          {
            icon: "ri:article-line",
            pageName: "seo-blogs",
            title: "routes.blogs",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "solar:case-outline",
            pageName: "seo-jobs",
            title: "routes.jobs",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "ri:star-line",
            pageName: "seo-saved-jobs",
            title: "routes.savedJobs",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "ri:account-circle-line",
            pageName: "seo-profile",
            title: "routes.profile",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "ri:file-list-2-line",
            pageName: "seo-terms-conditions",
            title: "routes.termsConditions",
            permission: Permissions.ViewSEO,
          },
          {
            icon: "ri:shield-line",
            pageName: "seo-privacy-policy",
            title: "routes.privacyPolicy",
            permission: Permissions.ViewSEO,
          },
        ],
      },
      {
        icon: "solar:settings-broken",
        pageName: "settings",
        title: "routes.settings",
        permission: " ",
      },
    ],
  }),
});
