<script setup lang="ts">
import { ref, ComputedRef, inject } from "vue";
import { FormInput, FormLabel } from "@/base-components/Form";

const aboutusData = inject<ComputedRef<IAboutUsPage>>("aboutusData")!;

const objAccessor = inject<ComputedRef<IAboutUsObject>>("objAccessor")!;
</script>

<template>
  <div class="my-20">
    <div class="mb-3 intro-y pb-4 mt-10">
      <h2 class="text-lg md:text-xl font-medium">
        {{ $t("website.aboutUs.vissionMission.title") }}
      </h2>

      <p class="text-slate-400 text-xs mt-1 font-normal">
        {{ $t("website.aboutUs.vissionMission.description") }}
      </p>
    </div>

    <!-- Title and VISION Title -->
    <div class="mt-4">
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("common.title") }}
          </FormLabel>
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].VisionAndMission.title"
          />
        </div>
      </div>
    </div>
    <!-- End Of Title and VISION Title -->

    <!--Vision Paragraph and Mission Title -->
    <div class="mt-4">
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{
              $t("website.aboutUs.vissionMission.fields.visionTitle")
            }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].VisionAndMission.visionTitle"
          />
        </div>
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.aboutUs.vissionMission.fields.visionParagraph") }}
          </FormLabel>
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].VisionAndMission.visionParagraph"
          />
        </div>
      </div>
    </div>
    <!-- End Vision Paragraph and Mission Title -->

    <div class="mt-4">
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.aboutUs.vissionMission.fields.missionTitle") }}
          </FormLabel>
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].VisionAndMission.missionTitle"
          />
        </div>
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.aboutUs.vissionMission.fields.missionParagraph") }}
          </FormLabel>
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].VisionAndMission.missionParagraph"
          />
        </div>
      </div>
    </div>

    <!-- SEO IMAGE INPUT -->
    <div class="mt-4">
      <h4 class="font-medium mb-2">
        {{ $t("website.aboutUs.vissionMission.fields.media") }}
      </h4>
      <div class="mt-3">
        <div>
          <SeoImageInput
            v-model="aboutusData[objAccessor].VisionAndMission.image"
          />
        </div>
      </div>
    </div>
  </div>
</template>
