<script setup lang="ts">
import { useVModel } from "@vueuse/core";
import { cn } from "@/lib/utils";
interface IProps {
  defaultValue?: string | number;
  modelValue?: string | number;
  error?: string;
  type?: string;
}
const props = withDefaults(defineProps<IProps>(), {
  type: "text",
});

const emits = defineEmits<{
  (e: "update:modelValue", payload: string | number): void;
}>();

const modelValue = useVModel(props, "modelValue", emits, {
  passive: true,
  defaultValue: props.defaultValue,
});
</script>

<template>
  <input
    v-model="modelValue"
    :type="type"
    :class="
      cn(
        'flex h-9 w-full rounded-md border  border-gray-200 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:border-gray-800 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300',
        $attrs.class ?? ''
      )
    "
  />

  <div v-if="props.error" class="mt-1 text-red-500 text-sm">
    {{ props.error }}
  </div>
</template>
