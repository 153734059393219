import gql from "graphql-tag";
import { MetaTagsComponent } from "../fragments/seo-fragments";
import { PagesNames } from "@/types/website/seo";

export const SET_META_TAG = gql`
  mutation SET_META_TAG($data: MetaTagsComponentInput!) {
    updateMetaTag(data: $data) {
      ...MetaTagsComponent
    }
  }
  ${MetaTagsComponent}
`;

export type MetaTagsComponentInput = {
  metaTag: IMetaTags;
  uniqueComponentName: PagesNames;
};
