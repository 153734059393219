<script setup lang="ts">
import Button from "@/base-components/Button";
import { FormInput, FormLabel } from "@/base-components/Form";
import { onMounted, ref, computed, provide, watchEffect } from "vue";
import { useLocalize } from "@/locale/i18n";
import { columns, actionsDropdown, filterFields, toolbar } from "./tableConfig";
import AddFaqModal from "@/components/Modals/AddFaqModal.vue";
import Tab from "@/base-components/Headless/Tab";
import LoadingSpinner from "@/components/Loading";
import { GET_FAQCategories } from "@/lib/graphql/queries/faq";
import { GET_FAQ } from "@/lib/graphql/queries/faq";
import { SET_FAQ } from "@/lib/graphql/mutations/faq";
import { useLazyQuery } from "@vue/apollo-composable";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import { DELETE_FAQ_CATEGORY } from "@/lib/graphql/mutations/faq";
import { usePagination } from "@/lib/pagination";
import { initialFAQPage } from "./initialFaq";
import { useQuery, useMutation } from "@vue/apollo-composable";
import SeoMetaFields from "@/base-components/SeoMetaFields/SeoMetaFields.vue";
import { toast } from "vue-sonner";
const { isRTL, i18n, language } = useLocalize();

const faqPage = ref<IFAQPage>(initialFAQPage);

const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IFAQObject>(() =>
  lang.value === "ar" ? "ArFAQPage" : "EnFAQPage"
);

type CategoryApi = {
  getFAQCategories: {
    category: ICategories[];
    totalRecords: number;
  };
};

const {
  loading: getCategoriesLoading,
  result,
  refetch,
  load,
} = useLazyQuery<CategoryApi>(GET_FAQCategories, null, {
  fetchPolicy: "no-cache",
  notifyOnNetworkStatusChange: true,
});

const { getPaginationVariables, pagination } = usePagination<CategoryApi>(
  result,
  "getFAQCategories",
  refetch
);

load(null, { pagination: getPaginationVariables() });

const categories = computed<ICategories[]>(
  () => result.value?.getFAQCategories?.category || []
);

const { result: FaqPage, loading: getFaqLoading } = useQuery(GET_FAQ, null, {
  fetchPolicy: "no-cache",
});

watchEffect(() => {
  if (FaqPage.value?.getFAQPage) {
    faqPage.value = {
      ...faqPage.value,
      ...FaqPage.value?.getFAQPage,
    };
  }
});

const { mutate: setFaqPage, loading: setFaqLoading } = useMutation(SET_FAQ);

const handleSubmit = async () => {
  const data = { ...faqPage.value };
  try {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
    await setFaqPage({ data });
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    toast.error(error.message);
  }
};

const { mutate: deleteFaqCategory, loading: deleteLoading } =
  useMutation(DELETE_FAQ_CATEGORY);

const handleDelete = async (id: string) => {
  await deleteFaqCategory({ id });
  await refetch();
};

const loading = computed<boolean>(
  () => getCategoriesLoading.value || deleteLoading.value
);

const pageLoading = computed(() => setFaqLoading.value || getFaqLoading.value);
onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.faqs");
});
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <!-- Begin Faqs -->
        <div class="my-5">
          <section class="relative">
            <div class="mb-3 intro-y pb-4 border-b-2">
              <h2 class="text-xl font-medium">
                {{ $t("faqs.title") }}
              </h2>

              <p class="text-slate-400 text-xs mt-1 font-normal">
                {{ $t("faqs.description") }}
              </p>
            </div>

            <Tab.Group>
              <Tab.List variant="boxed-tabs">
                <Tab>
                  <Tab.Button
                    class="w-full py-2"
                    as="button"
                    @click="lang = 'en'"
                  >
                    {{ $t("common.english") }}
                  </Tab.Button>
                </Tab>
                <Tab>
                  <Tab.Button
                    class="w-full py-2"
                    as="button"
                    @click="lang = 'ar'"
                  >
                    {{ $t("common.arabic") }}
                  </Tab.Button>
                </Tab>
              </Tab.List>
            </Tab.Group>

            <div
              class="h-full w-full flex items-center justify-center absolute z-50"
              v-if="pageLoading"
            >
              <LoadingSpinner class="" />
            </div>

            <!--FAQS Title And Paragraph -->
            <div>
              <div class="grid-cols-2 gap-3 mt-2 sm:grid">
                <div>
                  <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
                    {{ $t("common.title") }}
                  </FormLabel>
                  <FormInput
                    id="crud-form-1"
                    type="text"
                    class="w-full"
                    v-model="faqPage[objAccessor].title"
                  />
                </div>

                <div>
                  <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
                    {{ $t("common.paragraph") }}
                  </FormLabel>
                  <FormInput
                    id="crud-form-1"
                    type="text"
                    class="w-full"
                    v-model="faqPage[objAccessor].paragraph"
                  />
                </div>
              </div>
            </div>

            <!-- <SeoMetaFields v-model="faqPage.metaTags[language]" /> -->

            <div class="mt-20 justify-end flex gap-2">
              <!-- <Button
                type="button"
                variant="outline-secondary"
                class="w-24 mr-1"
              >
                {{ $t("common.cancel") }}
              </Button> -->
              <Button
                type="button"
                variant="primary"
                class="w-24"
                :disabled="pageLoading"
                :isLoading="pageLoading"
                @click="handleSubmit"
              >
                {{ $t("common.save") }}
              </Button>
            </div>
          </section>
          <!--End FAQS Title And Paragraph -->

          <data-list
            tableTitle="common.categories"
            createLink="website-faqCategory-new"
            :rows="categories"
            :columns="columns"
            :loading="loading"
            :withPagination="true"
            :pagination="pagination"
            class="!px-0 !mt-0"
          >
            <!-- <template #costumeCreateNew>
              <AddFaqModal @faq-added="refetch()" />
            </template> -->

            <template #default="{ row }">
              <router-link
                class="flex items-center gap-2 whitespace-nowrap"
                :to="`website-faqs/${row?.id}`"
              >
                <Icon icon="solar:square-top-down-broken" class="h-5 w-5" />
                <!-- Open -->
              </router-link>
              <DeleteModal @delete="handleDelete(row?.id)" />

              <!-- <AddFaqModal :row="row" @faq-added="refetch()">
                <template #trigger>
                  <Icon
                    icon="solar:pen-new-square-broken"
                    class="h-5 w-5 cursor-pointer"
                  />
                </template>
              </AddFaqModal> -->
              <router-link
                class="flex items-center gap-2 whitespace-nowrap"
                :to="`website-faqCategory/${row?.id}`"
              >
                <Icon
                  icon="solar:pen-new-square-broken"
                  class="h-5 w-5 cursor-pointer"
                />
              </router-link>
            </template>
          </data-list>
        </div>
        <!-- End Faqs -->
      </div>

      <!-- END: Form Layout -->
    </div>
  </div>
</template>
