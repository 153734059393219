import gql from "graphql-tag";
import {
  ButtonFragment,
  ImageFragment,
  MetaTagsFragment,
  StatisticsFragment,
  CardsFragment,
} from "./base-fragments";

export const AssessmentsInfoFragment = gql`
  fragment AssessmentsInfoFragment on AssessmentsInfo {
    title
    paragraph
    image {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const AssessmentsObjectivesFragment = gql`
  fragment AssessmentsObjectivesFragment on AssessmentsObjective {
    title
    paragraphs
  }
`;

export const AssessmentsKeyFeaturesFragment = gql`
  fragment AssessmentsKeyFeaturesFragment on AssessmentsKeyFeatures {
    title
    paragraphs
  }
`;

export const AssessmentsDetailsFragment = gql`
  fragment AssessmentsDetailsFragment on AssessmentsDetails {
    title
    paragraphs
  }
`;

export const AssessmentsSamplesFragment = gql`
  fragment AssessmentsSamplesFragment on AssessmentsSample {
    title
    paragraph
  }
`;

export const AssessmentsReportsFragment = gql`
  fragment AssessmentsReportsFragment on AssessmentsReport {
    title
    paragraphs
  }
`;

export const ContentAssessmentsPageFragment = gql`
  fragment ContentAssessmentsPageFragment on ContentAssessmentsPage {
    title
    paragraph
    slug
    button {
      ...ButtonFragment
    }

    info {
      ...AssessmentsInfoFragment
    }
    objective {
      ...AssessmentsObjectivesFragment
    }
    keyFeatures {
      ...AssessmentsKeyFeaturesFragment
    }
    details {
      ...AssessmentsDetailsFragment
    }
    sample {
      ...AssessmentsSamplesFragment
    }
    report {
      ...AssessmentsReportsFragment
    }
  }

  ${AssessmentsInfoFragment}
  ${AssessmentsObjectivesFragment}
  ${AssessmentsKeyFeaturesFragment}
  ${AssessmentsDetailsFragment}
  ${AssessmentsSamplesFragment}
  ${AssessmentsReportsFragment}
  ${ButtonFragment}
`;

export const AssessmentsPageFragment = gql`
  fragment AssessmentsPageFragment on AssessmentsPage {
    EnAssessmentsPage {
      ...ContentAssessmentsPageFragment
    }
    ArAssessmentsPage {
      ...ContentAssessmentsPageFragment
    }
    metaTags {
      ...MetaTagsFragment
    }
  }
  ${ContentAssessmentsPageFragment}
  ${MetaTagsFragment}
`;

export const AssessmentsListFragment = gql`
  fragment AssessmentsListFragment on AssessmentsPageWithTotalRecords {
    totalRecords
    assessment {
      id
      EnAssessmentsPage {
        title
        paragraph
      }
      ArAssessmentsPage {
        title
        paragraph
      }
    }
  }
`;
