<script setup lang="ts">
import { onMounted, computed } from "vue";
import { useLocalize } from "@/locale/i18n";
import { columns } from "./tableConfig";
import { GET_CLIENTS } from "@/lib/graphql/queries/clients";
import { useLazyQuery, useMutation } from "@vue/apollo-composable";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import { DELETE_CLIENT } from "@/lib/graphql/mutations/clients";
import { usePagination } from "@/lib/pagination";

// Localization
const { i18n } = useLocalize();

type ClientApi = {
  getClients: {
    client: IClient[];
    totalRecords: number;
  };
};

const {
  loading: fetchLoading,
  result,
  refetch,
  load,
} = useLazyQuery<ClientApi>(GET_CLIENTS, null, {
  fetchPolicy: "no-cache",
  notifyOnNetworkStatusChange: true,
});

const { getPaginationVariables, pagination } = usePagination<ClientApi>(
  result,
  "getClients",
  refetch
);

load(null, { pagination: getPaginationVariables() });

const { mutate: deleteClient, loading: deleteLoading } =
  useMutation(DELETE_CLIENT);

const loading = computed<boolean>(
  () => fetchLoading.value || deleteLoading.value
);

const handleDelete = async (id: string) => {
  await deleteClient({ id });
  await refetch();
};

const clients = computed<IClient[]>(
  () => result.value?.getClients?.client || []
);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.website-clients");
});
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <!-- Begin Faqs -->
        <!--services Title En and Ar -->
        <data-list
          tableTitle="routes.clients"
          createLink="website-clients-new"
          :rows="clients"
          :columns="columns"
          :loading="loading"
          :withPagination="true"
          :pagination="pagination"
          class="!px-0 !mt-0"
        >
          <template #default="{ row }">
            <router-link
              class="flex items-center gap-2 whitespace-nowrap"
              :to="`website-clients/${row?.id}`"
            >
              <Icon icon="solar:square-top-down-broken" class="h-5 w-5" />
              <!-- Open -->
            </router-link>
            <DeleteModal @delete="handleDelete(row?.id)" />
          </template>
        </data-list>
      </div>
      <!-- END: Form Layout -->
    </div>
  </div>
</template>
