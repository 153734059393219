import avatar from "@/assets/images/fakers/profile-14.jpg";

// TABLE COLUMNS
export const columns = [
  {
    field: "en.image",
    label: "table.image",
    type: "img",
    align: "center",
  },
  // {
  //   field: "writenBy",
  //   label: "table.author",
  //   type: "string",
  // },
  {
    field: "en.title",
    label: "table.articleTitle",
    type: "string",
  },

  {
    field: "createdAt",
    label: "table.createdAt",
    type: "date",
  },
  {
    field: "action",
    label: "table.actions",
    type: "action",
    ignorePrint: "ignore-0",
    ignorePrintRow: "ignore-00",
  },
];
//ACTIONS DROPDOWN
export const actionsDropdown = ["deleteAll"];

//FILTER
export const filterFields = [
  {
    label: "common.title",
    field: "title",
    type: "text",
    orderBy: "name",
    operator: "Equal",
  },

  // {
  //   label: "common.createdAt",
  //   field: "createdAt",
  //   type: "date",
  //   orderBy: "id",
  //   operator: "Equal",
  // },
];

// TOOLBAR
export const toolbar = {
  maxWidth: 400,
};

// DUMMY ROWS
export const rows = [
  {
    id: "Rn2",
    author: "Girgis nabil",
    status: "enabled",
    title: {
      ar: "عنوان المقالة",
      en: "Article Title test test test test test test",
    },
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
    createdBy: "2023-04-11T10:17:26.993Z",
    createdAt: "2023-04-11T10:17:26.993Z",
    updatedBy: "2023-04-11T10:17:26.993Z",
    updatedAt: "2023-04-11T10:17:26.993Z",
  },
  {
    id: "Rn23",
    author: "Girgis nabil",
    status: "enabled",
    title: {
      ar: "عنوان المقالة",
      en: "Article Title",
    },

    avatar:
      "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
    createdBy: "2023-04-11T10:17:26.993Z",
    createdAt: "2023-04-11T10:17:26.993Z",
    updatedBy: "2023-04-11T10:17:26.993Z",
    updatedAt: "2023-04-11T10:17:26.993Z",
  },
  {
    id: "Rn25",
    author: "Girgis nabil",
    status: "disabled",
    title: {
      ar: "عنوان المقالة",
      en: "Article Title",
    },

    avatar:
      "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
    createdBy: "2023-04-11T10:17:26.993Z",
    createdAt: "2023-04-11T10:17:26.993Z",
    updatedBy: "2023-04-11T10:17:26.993Z",
    updatedAt: "2023-04-11T10:17:26.993Z",
  },
];
// export const rows = [];
