export interface Member {
  authId?: string;
  fullName?: string;
  phoneNumber_1?: string;
  id?: string;
  email: string;
  linkedin?: string;
  profilePic?: IImage;
  title?: string;
  EnBrief?: string;
  ArBrief?: string;
  role?: string;
  isEnabled?: boolean;
  isDeleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
  order?: string;
  metaTags: IMetaTags;
  slug?: string;
}

export type CurrentMemeberApi = {
  memberFetchMe: IMember;
};

export enum SocialType {
  Twitter = "twitter",
  Facebook = "facebook",
}

export interface Social {
  socialNetwork: SocialType;
  description: string;
  title: string;
  image: Image;
}
export interface Image {
  alt: string;
  caption: string;
  description: string;
  title: string;
  url: string;
}
