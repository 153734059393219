<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import Button from "@/base-components/Button";
import { onMounted, watch } from "vue";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const AssessmentPageData = inject<IAssessmentsPage>(
  "AssessmentPageData",
  {} as IAssessmentsPage
);
const objAccessor = inject<
  ComputedRef<IAssessmentsObject> | IAssessmentsObject
>("objAccessor", "EnAssessmentsPage");

const accessor = computed<IAssessmentsObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});
</script>

<template>
  <div>
    <h2 class="text-lg mt-12 mb-4 font-medium">
      {{ $t("common.assessmentSample") }}
    </h2>
  </div>

  <div>
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.title") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="AssessmentPageData[objAccessor].sample.title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.paragraph") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="AssessmentPageData[objAccessor].sample.paragraph"
        />
      </div>
    </div>
  </div>
  <!-- End Hero Title En and Ar -->
</template>
