import gql from "graphql-tag";
import { OutSourcingPageFragment } from "../fragments/outSourcing-fragments";

export const SET_OUTSOURCING = gql`
  mutation UPDATE_OUTSOURCING($data: OutsourcingPageInput!) {
    updateOutsourcingPage(data: $data) {
      ...OutSourcingPageFragment
    }
  }
  ${OutSourcingPageFragment}
`;
