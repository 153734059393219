<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";
import Button from "@/base-components/Button";

const partnersPageData =
  inject<ComputedRef<IPartnersPage>>("partnersPageData")!;

const objAccessor = inject<ComputedRef<IPartnersObject>>("objAccessor")!;

//SLUG
const tawzefDomain = import.meta.env.VITE_WEBSITE_URL;
function generateSlug(title: string) {
  const slug = title
    .toLowerCase()
    .trim()
    .replace(/[^\u0600-\u06FFa-z0-9\s-]/g, "") //  Remove all non-alphanumeric characters except spaces and hyphens, including Arabic characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
  partnersPageData.value[objAccessor.value].slug = slug;
}

function generateBlogLink(slug: string) {
  const blogLink = tawzefDomain + "partners/" + slug;
  window.open(blogLink, "_blank");
}
</script>

<template>
  <div>
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium" htmlFor="crud-form-1">
          {{ $t("common.title") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span>
        </FormLabel>
        <FormInput
          @input="generateSlug(partnersPageData[objAccessor].title)"
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="partnersPageData[objAccessor].title"
        />
      </div>
      <div>
        <FormLabel htmlFor="crud-form-1"> {{ $t("blogs.slug") }}</FormLabel>
        <div class="flex items-center">
          <button
            @click="generateBlogLink(partnersPageData[objAccessor].slug)"
            class="bg-zinc-200 text-xs h-9 text-slate-600 ps-3 pe-1 py-1.5 rounded-s-md border border-slate-300 border-e-0"
          >
            {{ tawzefDomain + "partners/" }}
          </button>
          <FormInput
            @input="generateSlug(partnersPageData[objAccessor].slug)"
            type="text"
            name=""
            id=""
            v-model="partnersPageData[objAccessor].slug"
            class="border-s-0 focus:outline-none h-9 border-slate-300 focus:ring-0 rounded-s-none"
          />
        </div>
      </div>
      <div>
        <FormLabel class="font-medium" htmlFor="crud-form-1">
          {{ $t("common.paragraph") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span></FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="partnersPageData[objAccessor].paragraph"
        />
      </div>
    </div>
  </div>
  <!-- End Hero Title En and Ar -->

  <!--Hero paragraph En and Ar -->

  <div class="mt-4">
    <h4 class="font-medium mb-4">
      {{ $t("common.media") }}

      <span class="text-slate-500 text-[11px] mx-1">
        {{ $t("common.requiredImg") }}
      </span>
    </h4>
    <SeoImageInput v-model="partnersPageData[objAccessor].image" isOptional />
  </div>
</template>
