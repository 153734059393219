import gql from "graphql-tag";
import { ContactusPageFragment } from "../fragments/contactusFragments";

export const SET_CONTACTUS = gql`
  mutation UPDATE_CONTACTUS($data: ContactUsPageInput!) {
    updateContactUs(data: $data) {
      ...ContactusPageFragment
    }
  }
  ${ContactusPageFragment}
`;
