<script setup lang="ts">
import Button from "@/base-components/Button";

import { useLocalize } from "@/locale/i18n";

import BlogInformations from "./Components/BlogInformations.vue";
import {
  FormInput,
  FormLabel,
  FormSwitch,
  InputGroup,
} from "@/base-components/Form";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
import { initBlog } from "./initialblogs";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { GET_BLOG } from "@/lib/graphql/queries/blog";
import LoadingSpinner from "@/components/Loading";
import { UPDATE_BLOG } from "@/lib/graphql/mutations/blog";
import SeoMetaFields from "@/base-components/SeoMetaFields/SeoMetaFields.vue";
import { useRouter } from "vue-router";
import { toast } from "vue-sonner";

const { isRTL, i18n, language } = useLocalize();
const props = defineProps<{ id: string }>();
const router = useRouter();

const isEditing = computed<boolean>(() => !!props.id);

const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IBlog>(() => (lang.value === "ar" ? "ar" : "en"));
provide("objAccessor", objAccessor);

type BlogResult = {
  getBlog: IBlog;
};

const BlogData = ref<IBlog>(initBlog());

let getBlogLoading = ref<boolean>(false);
if (isEditing.value) {
  const { result, loading } = useQuery<BlogResult>(
    GET_BLOG,
    { blogId: props.id },
    {
      fetchPolicy: "no-cache",
    }
  );
  getBlogLoading = loading;

  watch(
    () => result.value,
    (res) => {
      if (res?.getBlog) {
        BlogData.value = {
          ...BlogData.value,
          ...res.getBlog,
        };
      }
    }
  );
}

provide("BlogData", BlogData);

const { mutate: setBlog, loading: setBlogLoading } = useMutation(UPDATE_BLOG);

const handleSubmit = async () => {
  const data: IBlog = { ...BlogData.value };
  delete data.id;
  delete data.en.createdAt;
  delete data.ar.createdAt;
  try {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    isEditing.value
      ? await setBlog({ data, blogId: props.id })
      : await setBlog({ data });
    router.push({ name: "blogs" });
  } catch (error: any) {
    toast.error(error.message);
  }
};
const loading = computed(() => setBlogLoading.value || getBlogLoading.value);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.blogs");
});
watch(
  isRTL,
  () => (document.title = i18n("routes.app") + " | " + i18n("routes.blogs"))
);
</script>

<template>
  <!-- <div class="flex items-center mt-8 intro-y">
    <h2 class="text-lg font-medium">{{ $t("blogs.new") }}</h2>
  </div>
  <br /> -->

  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->

      <!-- BEGIN: Form Layout -->

      <div class="p-5 intro-y box w-full">
        <div
          class="h-2/6 w-full flex items-center justify-center absolute z-50"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>
        <BlogInformations />

        <!-- <BlogsMeta /> -->

        <!-- <SeoMetaFields v-model="BlogData.metaTags[language]" /> -->

        <div class="mt-5 text-right">
          <Button
            type="button"
            variant="outline-secondary"
            class="w-24 mr-1"
            @click="$router.push({ name: 'blogs' })"
          >
            {{ $t("common.cancel") }}
          </Button>
          <Button
            type="button"
            variant="primary"
            class="w-24"
            :disabled="loading"
            :isLoading="loading"
            @click="handleSubmit"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
      <!-- END: Form Layout -->
    </div>
  </div>
</template>

<style>
/* .filepond--wrapper {
  border-radius: 10px !important ;
  overflow: hidden !important;
}
.filepond--root.my-pond.filepond--hopper {
  border-radius: 10px !important ;
  overflow: hidden !important;
} */
/* .filepond--drop-label {
  background-color: white !important;
  border-radius: 6px !important;
  color: rgb(var(--color-primary)) !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  border: 1px solid #e5e7eb;
} */
</style>
