import gql from "graphql-tag";
import {
  ButtonFragment,
  ImageFragment,
  MetaTagsFragment,
} from "./base-fragments";

export const localFragment = gql`
  fragment localFragment on RecruitmentLocal {
    image {
      ...ImageFragment
    }
    title
    description
    paragraphs
    buttons {
      ...ButtonFragment
    }
  }
  ${ImageFragment}
  ${ButtonFragment}
`;

export const overSeasFragment = gql`
  fragment OverSeasFragment on RecruitmentOverSeas {
    title
    description
    paragraphs
    buttons {
      ...ButtonFragment
    }
  }
  ${ButtonFragment}
`;

export const contentRecruitmentPageFragment = gql`
  fragment ContentRecruitmentPageFields on ContentRecruitmentPage {
    paragraph
    title
    button {
      ...ButtonFragment
    }
    local {
      ...localFragment
    }
    overSeas {
      ...OverSeasFragment
    }
  }
  ${ButtonFragment}
  ${localFragment}
  ${overSeasFragment}
`;

export const recruitmentPageFragment = gql`
  fragment recruitmentPageFragment on RecruitmentPage {
    EnRecruitmentPage {
      ...ContentRecruitmentPageFields
    }
    ArRecruitmentPage {
      ...ContentRecruitmentPageFields
    }
    metaTags {
      ...MetaTagsFragment
    }
  }
  ${contentRecruitmentPageFragment}
  ${MetaTagsFragment}
`;
