<script setup lang="ts">
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import Button from "@/base-components/Button";
import { ref } from "vue";

const open = ref(false);

type Emits = {
  (e: "delete"): void;
};

const emit = defineEmits<Emits>();

const closeModal = () => (open.value = false);

const confirm = () => {
  emit("delete");
  closeModal();
};
</script>

<template>
  <Dialog v-model:open="open">
    <DialogTrigger as-child>
      <slot name="trigger">
        <button class="flex items-end gap-2 whitespace-nowrap">
          <Icon
            icon="solar:trash-bin-minimalistic-broken"
            class="h-[22px] w-[22px]"
          /></button
      ></slot>
    </DialogTrigger>
    <DialogContent class="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>
          <Icon icon="ph:x-circle" class="w-16 h-16 text-danger mx-auto mt-3" />
        </DialogTitle>
        <DialogDescription>
          <div class="text-2xl mt-5 text-center">
            {{ $t("common.deleteModal.areYouSure") }}
          </div>
        </DialogDescription>
      </DialogHeader>
      <div class="text-slate-500 mt-2 text-center">
        {{ $t("common.deleteModal.wantToDelete") }} <br />
        {{ $t("common.deleteModal.cantBeUndone") }}
      </div>
      <DialogFooter>
        <div class="w-full text-center flex gap-3 justify-center">
          <Button variant="primary" @click="confirm">
            {{ $t("common.delete") }}</Button
          >
          <Button variant="outline-secondary" @click="closeModal">
            {{ $t("common.cancel") }}</Button
          >
        </div>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
