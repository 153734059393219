<script setup lang="ts">
import  ClassicEditor  from "@/base-components/Ckeditor/ClassicEditor.vue";
import Button from "@/base-components/Button";
import { useLocalize } from "@/locale/i18n";
import { FormInput, FormLabel } from "@/base-components/Form";
import { onMounted, watch, ref, computed, watchEffect } from "vue";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { GET_SETTINGS } from "@/lib/graphql/queries/settings";
import LoadingSpinner from "@/components/Loading";
import { SET_SETTINGS } from "@/lib/graphql/mutations/setting";
import { initSettings } from "./initialSettings";
import { toast } from "vue-sonner";

const editorConfig = {
  language: "ar",
};

// Localization
const { isRTL, i18n } = useLocalize();

const settingsData = ref<ISettings>(initSettings);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.settings");
});
watch(
  isRTL,
  () => (document.title = i18n("routes.app") + " | " + i18n("routes.settings"))
);

const { result: SettingsModel, loading: getSettingsLoading } = useQuery(
  GET_SETTINGS,
  null,
  {
    fetchPolicy: "no-cache",
  }
);

// Watch for the servicePage result and update servicePageData
watchEffect(() => {
  if (SettingsModel.value?.getSettings) {
    settingsData.value = {
      ...settingsData.value,
      ...SettingsModel.value?.getSettings,
    };
  }
});

const { mutate: setSettingPage, loading: setSettingLoading } =
  useMutation(SET_SETTINGS);

const handleSubmit = async () => {
  const data = { ...settingsData.value };
  try {
    window.scrollTo({ top: 120, behavior: "smooth" });
    await setSettingPage({ data });
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    toast.error(error.message);
  }
};

const loading = computed(
  () => setSettingLoading.value || getSettingsLoading.value
);
</script>

<template>
  <div class="flex items-center mt-8 intro-y">
    <h2 class="text-lg font-medium">{{ $t("settings.title") }}</h2>
  </div>

  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->
      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <div class="pb-4 mb-4 intro-y border-b-2 border-b-slate-100">
          <h2 class="text-base">
            {{ $t("settings.contacts") }}
          </h2>
        </div>
        <div
          class="h-screen w-full flex items-center justify-center absolute z-50"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>
        <div class="grid-cols-2 gap-3 mt-3 sm:grid">
          <div>
            <FormLabel htmlFor="crud-form-1">
              {{ $t("common.facebook") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="settingsData.facebook"
            />
          </div>
          <div>
            <FormLabel htmlFor="crud-form-1">
              {{ $t("common.linkedin") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="settingsData.linkedin"
            />
          </div>
        </div>
        <div class="grid-cols-2 gap-3 mt-3 sm:grid">
          <div>
            <FormLabel htmlFor="crud-form-1">
              {{ $t("common.instagram") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="settingsData.instagram"
            />
          </div>
          <div>
            <FormLabel htmlFor="crud-form-1">
              {{ $t("common.phoneNumber") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="settingsData.phoneNumber"
            />
          </div>
        </div>

        <div class="grid sm:grid-cols-2 gap-3 mt-3">
          <div>
            <FormLabel htmlFor="crud-form-1">
              {{ $t("common.twitter") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="settingsData.twitter"
            />
          </div>

          <div>
            <FormLabel htmlFor="crud-form-1">
              {{ $t("common.email") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="settingsData.email"
            />
          </div>
        </div>

        <div class="grid-cols-2 gap-3 mt-3 sm:grid">
          <div>
            <FormLabel htmlFor="crud-form-1">
              {{ $t("common.address") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="settingsData.address"
            />
          </div>
        </div>

        <div class="mb-4 mt-6 intro-y">
          <h2 class="text-base font-medium">
            {{ $t("settings.terms") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("settings.termsDesc") }}
          </p>
        </div>
        <div class="mt-3 gap-3 grid-cols-2 sm:grid">
          <div>
            <label>{{ $t("common.en") }}</label>
            <div class="mt-2" :key="loading">
              <ClassicEditor
                v-model="settingsData.EnTermsConditions"
              />
            </div>
          </div>
          <div class="">
            <label>{{ $t("common.ar") }}</label>
            <div class="mt-2" :key="loading">
              <ClassicEditor
                v-model="settingsData.ArTermsConditions"
              />
            </div>
          </div>
        </div>
        <div class="mb-4 mt-6 intro-y">
          <h2 class="text-base font-medium">
            {{ $t("settings.policy") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("settings.policyDesc") }}
          </p>
        </div>
        <div class="mt-3 gap-3 grid-cols-2 sm:grid">
          <div>
            <label>{{ $t("common.en") }}</label>
            <div class="mt-2" :key="loading">
              <ClassicEditor
                v-model="settingsData.EnPrivacyPolicy"
              />
            </div>
          </div>
          <div class="">
            <label>{{ $t("common.ar") }}</label>
            <div class="mt-2" :key="loading">
              <ClassicEditor
                v-model="settingsData.ArPrivacyPolicy"
              />
            </div>
          </div>
        </div>
        <!-- Blogs Seo Details title -->
        <div class="mt-10 text-right">
          <!-- <Button type="button" variant="outline-secondary" class="w-24 mr-1">
            {{ $t("common.cancel") }}
          </Button> -->
          <Button
            type="button"
            variant="primary"
            :disabled="loading"
            :isLoading="loading"
            @click="handleSubmit"
            class="w-24"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
        
      </div>
      <!-- END: Form Layout -->
    </div>
  </div>
</template>
