<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const contactUsData = inject<ComputedRef<IContactUsPage>>("contactUsData")!;

const objAccessor = inject<ComputedRef<IContactUsObject>>("objAccessor")!;
</script>

<template>
  <div class="mt-4">
    <h4 class="font-medium">
      {{ $t("website.contactUs.hero.fields.location") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("website.contactUs.hero.fields.lat") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="contactUsData[objAccessor].lat"
        />
      </div>
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("website.contactUs.hero.fields.lng") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="url"
          class="w-full"
          v-model="contactUsData[objAccessor].lng"
        />
      </div>
    </div>
  </div>
  <!-- End Hero Title & paragraph-->
</template>
