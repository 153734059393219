import gql from "graphql-tag";
import { ContactusPageFragment } from "../fragments/contactusFragments"

export const GET_CONTACTUS = gql`
  query {
    getContactUs {
      ...ContactusPageFragment
    }
  }
  ${ContactusPageFragment}
`;
