import gql from "graphql-tag";
import { AssessmentsPageFragment } from "../fragments/assessment-fragments";

export const UPDATE_ASSESSMENTS = gql`
  mutation UPDATE_ASSESSMENTS($data: AssessmentsPageInput!, $id: String) {
    updateAssessmentPage(data: $data, id: $id) {
      ...AssessmentsPageFragment
    }
  }
  ${AssessmentsPageFragment}
`;

export const DELETE_ASSESSMENTS = gql`
  mutation DELETE_ASSESSMENT($id: String!) {
    deleteAssessment(id: $id)
  }
`;
