<template>
  <div class="font-medium whitespace-nowrap truncate max-w-[250px]">
    <Tippy
      tag="span"
      class="cursor-pointer"
      :content="GenericModel.present(row, column.field, column.localized)"
    >
      <span :class="{ 'pointer-events-none': !column.link }">{{
        GenericModel.present(row, column.field, column.localized)
      }}</span>
    </Tippy>
    <div
      class="text-slate-400 text-xs whitespace-nowrap mt-0.5"
      v-if="column.subField"
    >
      <Tippy
        tag="span"
        :content="
          GenericModel.present(row, column.subField, column.subLocalized)
        "
        >{{
          GenericModel.present(row, column.subField, column.subLocalized)
        }}</Tippy
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import IColumn from "@/types/TableColumn";
import { GenericModel } from "@/lib/model/generic-model";
import { useLocalize } from "@/locale/i18n";

const { i18n } = useLocalize();

interface IRow {
  [key: string]: any;
}

interface IProps {
  row: IRow;
  column: IColumn;
}

defineProps<IProps>();
</script>
