<script setup lang="ts">
import Button from "@/base-components/Button";
import HomeHero from "./components/HomeHero.vue";
import HomeOurClients from "./components/HomeOurClients.vue";
import HomeServices from "./components/HomeServices.vue";
import HomeAboutUs from "./components/HomeAboutUs.vue";
import HomeOutsourcing from "./components/HomeOutsourcing.vue";
import HomeTestimonials from "./components/HomeTestimonials.vue";
import LoadingSpinner from "@/components/Loading";
import { useLocalize } from "@/locale/i18n";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { GET_HOMEPAGE } from "@/lib/graphql/queries/home";
import { SET_HOMEPAGE } from "@/lib/graphql/mutations/home";
import Tab from "@/base-components/Headless/Tab";
import { initHome } from "./initialHome";
import { toast } from "vue-sonner";

// Localization
const { isRTL, i18n, language } = useLocalize();

const homePageData = ref<IHomePage>(initHome);
const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IHomeObject>(() =>
  lang.value === "ar" ? "ArHomePage" : "EnHomePage"
);

type HomeApi = {
  getHomePage: IHomePage;
};

// Fetch HomePage
const { result: homePage, loading: getHomePageLoading } = useQuery<HomeApi>(
  GET_HOMEPAGE,
  null,
  {
    fetchPolicy: "no-cache",
  }
);

provide("homePageData", homePageData);
provide("objAccessor", objAccessor);

watchEffect(() => {
  if (homePage.value?.getHomePage) {
    homePageData.value = {
      ...homePageData.value,
      ...homePage.value?.getHomePage,
    };
  }
});
// Submit Data
const { mutate: setHomePage, loading: setHomePageLoading } =
  useMutation(SET_HOMEPAGE);
const handleSubmit = async () => {
  window.scrollTo({
    top: 120,
    behavior: "smooth",
  });
  const data = { ...homePageData.value };
  delete data.metaTags;
  try {
    await setHomePage({ data });
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    toast.error(error.message);
  }
};

const loading = computed(
  () => setHomePageLoading.value || getHomePageLoading.value
);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.website-home");
});

watch(
  isRTL,
  () =>
    (document.title = i18n("routes.app") + " | " + i18n("routes.website-home"))
);
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->
      <!-- BEGIN: Form Layout -->
      <div class="p-3 md:p-5 intro-y box w-full">
        <!-- Hero section title -->
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-base font-medium">
            {{ $t("website.home.title") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("website.home.description") }}
          </p>
        </div>
        <!-- end of  hero section title -->
        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>
        <div
          class="h-screen w-full flex items-center justify-center absolute"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>
        <div>
          <!-- Begin home Hero -->
          <HomeHero />
          <!-- End home Hero -->
          <!-- Begin Our Clients Icons -->
          <HomeOurClients />
          <!-- End Our Clients Icons -->
          <!-- Begin About us section -->
          <HomeAboutUs />
          <!-- End about us section -->
          <!-- Begin services section -->
          <HomeServices />
          <!-- End services section -->
          <!-- Begin Outsourcing section -->
          <HomeOutsourcing />
          <!-- End Outsourcing section -->
          <!-- Begin Testimonials section -->
          <HomeTestimonials />
          <!-- End   Testimonials section -->
          <div class="mt-5 text-right">
            <!-- <Button type="button" variant="outline-secondary" class="w-24 mr-1">
              {{ $t("common.cancel") }}
            </Button> -->
            <Button
              type="button"
              variant="primary"
              class="w-24"
              :disabled="loading"
              :isLoading="loading" 
              @click="handleSubmit"
            >
              {{ $t("common.save") }}
            </Button>
          </div>
        </div>
      </div>
      <!-- END: Form Layout -->
    </div>
  </div>
</template>

<style scoped>
.LoadingOverLay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  height: 100%;
  overflow: hidden;
  /* backdrop-filter: blur(1px); */
  background-color: rgb(173, 173, 173);
  opacity: 0.3;
}
</style>
