<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const outsourcingData =
  inject<ComputedRef<IOutsourcingPage>>("outsourcingData")!;

// ! to tell ts iam sure its exist
const objAccessor = inject<ComputedRef<IOutsourcingObject>>("objAccessor")!;
// console.log(objAccessor.value, "its outsourcing Data");

const handleAddItem = () => {
  const lastIndex =
    outsourcingData.value[objAccessor.value]?.manPower?.paragraphs.length;
  const title = `${i18n("common.icon")} ${lastIndex + 1}`;
  outsourcingData.value[objAccessor.value].manPower?.paragraphs.push("");
};

const handleRemoveItem = (index: number) => {
  outsourcingData.value[objAccessor.value].manPower?.paragraphs.splice(
    index,
    1
  );
};

const handleAddImage = () => {
  const lastIndex =
    outsourcingData.value[objAccessor.value]?.manPower.images.length;
  const title = `${i18n("common.icon")} ${lastIndex + 1}`;
  outsourcingData.value[objAccessor.value].manPower.images.push({
    title,
    alt: "",
    caption: "",
    description: "",
    url: "",
  });
};

const handleRemoveImage = (index: number) => {
  outsourcingData.value[objAccessor.value].manPower?.images.splice(index, 1);
};
</script>

<template>
  <div class="mb-5">
    <h2 class="text-lg mt-12 font-medium">
      {{ $t("website.services.outsourcing.manpowerOutsourcing") }}
    </h2>
  </div>

  <div class="mt-4">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.title") }}</FormLabel
        >
        <!-- -->
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].manPower.title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.description") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].manPower.description"
        />
      </div>
    </div>
  </div>
  <div class="mt-3">
    <h4 class="font-medium mb-2">
      {{ $t("website.home.hero.fields.icons") }}
    </h4>
    <DraggableList
      @removeItem="handleRemoveImage"
      @addItem="handleAddImage"
      :theArray="outsourcingData[objAccessor].manPower.images"
      class="mt-10"
    >
      <template v-slot="{ index }">
        <div class="mt-3">
          <div>
            <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
              {{ $t("website.home.services.fields.media") }}</FormLabel
            >
            <SeoImageInput
              v-model="outsourcingData[objAccessor].manPower.images[index]"
            />
          </div></div
      ></template>
    </DraggableList>
  </div>

  <h4 class="font-medium mt-5">
    {{ $t("common.paragraphs") }}
  </h4>
  <DraggableList
    @removeItem="handleRemoveItem"
    @addItem="handleAddItem"
    :theArray="outsourcingData[objAccessor].manPower.paragraphs"
    class="mt-5"
  >
    <template v-slot="{ index }">
      <div class="w-full">
        <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-3 sm:grid">
          <div>
            <FormLabel
              class="!text-slate-400 text-xs font-medium mt-2"
              htmlFor="crud-form-1"
            >
              {{ $t("common.name") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="outsourcingData[objAccessor].manPower.paragraphs[index]"
            />
          </div>
        </div>
      </div>
    </template>
  </DraggableList>
  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.firstCallToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].manPower.buttons[0].text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].manPower.buttons[0].link"
        />
      </div>
    </div>
  </div>

  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.secondCallToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].manPower.buttons[1].text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="outsourcingData[objAccessor].manPower.buttons[1].link"
        />
      </div>
    </div>
  </div>
</template>
