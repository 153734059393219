<template>
  <main v-if="!loadingInit">
    <RouterView />
  </main>
  <div class="h-[100dvh] w-screen flex items-center justify-center" v-else>
    <LoadingSpinner />
  </div>
  <Toaster theme="dark" richColors :expand="false" />
</template>

<script setup lang="ts">
import { Toaster } from "vue-sonner";
import { useAuthStore } from "@/stores/auth/auth-store";
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
const AuthStore = useAuthStore();
const { loadingInit } = storeToRefs(useAuthStore());
import LoadingSpinner from "@/components/Loading";

onMounted(async () => {
  await AuthStore.doInit();
});
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700;800;900&family=Inter:wght@300;400;500;600;700;800;900&display=swap");

html,
* {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body[lang="ar"] {
  font-family: "Cairo", sans-serif !important;
}

body[lang="ar"] .side-nav > ul > li > .side-menu.side-menu--active:after {
  margin-left: -26px;
  margin-right: unset;
  right: unset;
  left: 0px;
  transform: rotate(180deg);
}

.filepond--drop-label {
  background-color: white !important;
  border-radius: 6px !important;
  color: rgb(var(--color-primary)) !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  border: 1px solid #e5e7eb;
}
</style>
