<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const salaryPageData =
  inject<ComputedRef<ISalarySurveyPage>>("salaryPageData")!;

const objAccessor = inject<ComputedRef<ISalarySurveyObject>>("objAccessor")!;

const handleAddItem = () => {
  salaryPageData.value[objAccessor.value].subscriptions.push({
    title: "",
    paragraph: [""],
    button: {
      text: "",
      link: "",
    },
  });
};

const handleRemoveItem = (index: number) => {
  salaryPageData.value[objAccessor.value].subscriptions.splice(index, 1);
};

const handleAddParagraph = (parentIndex: number) => {
  // cards.value[parentIndex].paragraph.push("");
  salaryPageData.value[objAccessor.value].subscriptions[
    parentIndex
  ].paragraph.push("");
};

const handleRemoveParagraph = (childIndex: number, parentIndex: number) => {
  salaryPageData.value[objAccessor.value].subscriptions[
    parentIndex
  ].paragraph.splice(childIndex, 1);
  // cards.value[parentIndex].paragraph.splice(childIndex, 1);
};
</script>

<template>
  <div class="mb-5">
    <h2 class="text-lg mt-12 font-medium">
      {{ $t("website.services.salarySurvey.subscriptions") }}
    </h2>
  </div>

  <DraggableList
    @removeItem="handleRemoveItem"
    @addItem="handleAddItem"
    :theArray="salaryPageData[objAccessor].subscriptions"
    class="mt-5"
  >
    <template v-slot="{ index: parentIndex }">
      <div class="w-full">
        <div class="grid-cols-2 gap-3 mt-2 sm:grid">
          <div>
            <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
              {{ $t("common.title") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="
                salaryPageData[objAccessor].subscriptions[parentIndex].title
              "
            />
          </div>
          <!-- <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("common.paragraph") }}</FormLabel
          >
          <FormInput id="crud-form-1" type="text" class="w-full" />
        </div> -->
        </div>

        <div class="mt-4">
          <h4 class="font-medium">
            {{ $t("website.aboutUs.hero.fields.callToAction") }}
          </h4>
          <div class="grid-cols-2 gap-3 mt-2 sm:grid">
            <div>
              <FormLabel
                class="!text-slate-400 text-xs font-medium mt-2"
                htmlFor="crud-form-1"
              >
                {{ $t("website.aboutUs.hero.fields.callToAction") }}</FormLabel
              >
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="
                  salaryPageData[objAccessor].subscriptions[parentIndex].button
                    .text
                "
              />
            </div>

            <div>
              <FormLabel
                class="!text-slate-400 text-xs font-medium mt-2"
                htmlFor="crud-form-1"
              >
                {{ $t("common.link") }}</FormLabel
              >
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="
                  salaryPageData[objAccessor].subscriptions[parentIndex].button
                    .link
                "
              />
            </div>
          </div>
        </div>

        <!-- Nested Draggable for paragraphs -->
        <h4 class="font-medium mt-5">
          {{ $t("common.paragraphs") }}
        </h4>
        <DraggableList
          @removeItem="
            (childIndex) => handleRemoveParagraph(childIndex, parentIndex)
          "
          @addItem="handleAddParagraph(parentIndex)"
          :theArray="
            salaryPageData[objAccessor].subscriptions[parentIndex].paragraph
          "
          class="mt-5"
        >
          <template v-slot="{ index: childIndex }">
            <!-- <h1>{{ childIndex }} childIndex</h1> -->
            <div class="w-full">
              <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-3 sm:grid">
                <div>
                  <FormLabel
                    class="!text-slate-400 text-xs font-medium mt-2"
                    htmlFor="crud-form-1"
                  >
                    {{ $t("common.name") }}</FormLabel
                  >
                  <FormInput
                    id="crud-form-1"
                    type="text"
                    class="w-full"
                    v-model="
                      salaryPageData[objAccessor].subscriptions[parentIndex]
                        .paragraph[childIndex]
                    "
                  />
                </div>
              </div>
            </div>
          </template>
        </DraggableList>
        <!-- end of nested draggable -->
      </div>
    </template>
  </DraggableList>
</template>
