import { Ref, computed, ref } from "vue";
import { Pagination } from "@/types/Table";
import { ApolloQueryResult } from "@apollo/client/core";

export interface AlgoliaPagination {
  size: number;
  page: number;
}
export interface AlgoliaFilter {
  field: string;
  value: string;
  operator: AlgoliaOperators;
  values: string[];
}

export enum AlgoliaOperators {
  EQUAL = "EQUAL",
  NOTEQUAL = "NOTEQUAL",
  GREATERTHAN = "GREATERTHAN",
  LESSTHAN = "LESSTHAN",
  GREATERTHANEQUAL = "GREATERTHANEQUAL",
  LESSTHANEQUAL = "LESSTHANEQUAL",
  FROM = "FROM",
  TO = "TO",
  IN = "IN",
}

export const usePagination = <T>(
  result: Ref<T | undefined>,
  accessor: string,
  refetch: (variables?: any) => Promise<ApolloQueryResult<T>> | undefined
) => {
  const page = ref(1);
  const size = ref(10);

  const getPaginationVariables = (
    action?: "next" | "previous"
  ): IAlgoliaPagination => {
    if (action === "next") {
      return {
        page: ++page.value,
        size: size.value,
      };
    } else if (action === "previous") {
      return {
        page: --page.value,
        size: size.value,
      };
    }
    return {
      page: page.value,
      size: size.value,
    };
  };

  const pagination = computed<Pagination>(() => {
    // to be fixed
    const totalRecords =
      (result.value as { [key: string]: any })?.[accessor]?.totalRecords || 0;
    return {
      total: totalRecords,
      size: size.value,
      page: page.value,
      hasNext: page.value < (Math.ceil(totalRecords / size.value) || 0),
      hasPrevious: page.value > 1,
      nextPage: () =>
        refetch({
          pagination: getPaginationVariables("next"),
        }),
      previousPage: () =>
        refetch({
          pagination: getPaginationVariables("previous"),
        }),
      changePageSize: (pageSize: number) => {
        size.value = pageSize;
        refetch({
          pagination: {
            page: page.value,
            size: pageSize,
          },
        });
      },
    };
  });

  return {
    pagination,
    getPaginationVariables,
  };
};
