<template>
  <div
    class="flex items-center justify-center gap-2 px-5 py-1 w-fit rounded capitalize"
    :class="column.colorClass[row[column.field]] || 'text-success'"
  >
    {{
      column.localized
        ? i18n(column.status?.[row?.[column?.field]] || "")
        : row[column.field]
    }}
  </div>
</template>

<script setup lang="ts">
import IColumn from "@/types/TableColumn";
import { useLocalize } from "@/locale/i18n";

const { i18n } = useLocalize();

interface IRow {
  [key: string]: any;
}

interface IProps {
  row: IRow;
  column: IColumn;
}

defineProps<IProps>();
</script>
