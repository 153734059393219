import { SettingsModel } from "@/types/website/settings";

export const initSettings: SettingsModel = {
  ArTermsConditions: "",
  EnTermsConditions: "",
  ArPrivacyPolicy: "",
  EnPrivacyPolicy: "",
  email: "",
  phoneNumber: "",
  facebook: "",
  instagram: "",
  linkedin: "",
  address: "",
};

export default initSettings;
