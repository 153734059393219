import gql from "graphql-tag";
import { ImageFragment } from "./base-fragments";

export const ClientsFragment = gql`
  fragment ClientsFragment on Client {
    id
    image {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;
