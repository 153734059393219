<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import Button from "@/base-components/Button";
import { onMounted, watch } from "vue";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";
import { Ref } from "vue";

const { i18n } = useLocalize();

const AssessmentPageData = inject<Ref<IAssessmentsPage>>(
  "AssessmentPageData",
  {} as Ref<IAssessmentsPage>
);
const objAccessor = inject<
  ComputedRef<IAssessmentsObject> | Ref<IAssessmentsObject>
>("objAccessor", "EnAssessmentsPage");

const accessor = computed<IAssessmentsObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});

//SLUG
const tawzefDomain = import.meta.env.VITE_WEBSITE_URL;
function generateSlug(title: string) {
  const slug = title
    .toLowerCase()
    .trim()
    .replace(/[^\u0600-\u06FFa-z0-9\s-]/g, "") //  Remove all non-alphanumeric characters except spaces and hyphens, including Arabic characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
  AssessmentPageData.value[objAccessor.value].slug = slug;
}

function generateBlogLink(slug: string) {
  const blogLink = tawzefDomain + "assesments/" + slug;
  window.open(blogLink, "_blank");
}
</script>

<template>
  <!--Hero Title and paragraph -->
  <div>
    <h4 class="font-medium"></h4>
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.title") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span>
        </FormLabel>
        <FormInput
          @input="generateSlug(AssessmentPageData[objAccessor].title ?? '')"
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="AssessmentPageData[objAccessor].title"
        />
      </div>
      <div>
        <FormLabel htmlFor="crud-form-1"> {{ $t("blogs.slug") }}</FormLabel>
        <div class="flex items-center">
          <button
            @click="generateBlogLink(AssessmentPageData[objAccessor].slug)"
            class="bg-zinc-200 text-xs h-9 text-slate-600 ps-3 pe-1 py-1.5 rounded-s-md border border-slate-300 border-e-0"
          >
            {{ tawzefDomain + "assesments/" }} 
          </button>
          <FormInput
            @input="generateSlug(AssessmentPageData[objAccessor].slug)"
            type="text"
            name=""
            id=""
            v-model="AssessmentPageData[objAccessor].slug"
            class="border-s-0 focus:outline-none h-9 border-slate-300 focus:ring-0 rounded-s-none"
          />
        </div>
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.paragraph") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="AssessmentPageData[objAccessor].paragraph"
        />
      </div>
    </div>
  </div>
  <!-- End Hero Title and paragraph -->

  <!--Call To Action BTN -->
  <div class="mt-4">
    <h4 class="font-medium">
      {{ $t("website.aboutUs.hero.fields.callToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="AssessmentPageData[objAccessor].button.text"
        />
      </div>
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}
          <span class="text-slate-500 text-[11px] mx-1">
            {{ $t("common.required") }}
          </span>
        </FormLabel>
        <FormInput
          id="crud-form-1"
          type="url"
          class="w-full"
          v-model="AssessmentPageData[objAccessor].button.link"
        />
      </div>
    </div>
  </div>
  <!-- End Call To Action BTN -->
</template>
