import gql from "graphql-tag";
import { JobsPageFragment } from "../fragments/job-fragments";

export const SET_JOB = gql`
  mutation UPDATE_JOB($data: JobsPageInput!) {
    updateJob(data: $data) {
      ...JobsPageFragment
    }
  }
  ${JobsPageFragment}
`;

export const UPDATE_JOB_SLUG = gql`
  mutation UPDATE_JOB_SLUG($slug: String!, $id: String!) {
    updateJobSlug(slug: $slug, jobId: $id)
  }
`;
