<template>
  <DropdownMenu>
    <DropdownMenuTrigger
      class="bg-white/20 py-2 px-3.5 rounded-lg !ring-0 flex gap-3 items-center justify-center text-white"
    >
      <span class="capitalize">{{ i18n.locale.value }}</span>
      <Icon class="text-xl" icon="iconoir:language"></Icon
    ></DropdownMenuTrigger>
    <DropdownMenuContent>
      <DropdownMenuLabel>{{ $t("common.language") }}</DropdownMenuLabel>
      <DropdownMenuSeparator />
      <DropdownMenuItem @click="onLocaleChange('en')">
        {{ $t("common.english") }}</DropdownMenuItem
      >
      <DropdownMenuItem @click="onLocaleChange('ar')">
        {{ $t("common.arabic") }}</DropdownMenuItem
      >
    </DropdownMenuContent>
  </DropdownMenu>
</template>

<script setup lang="ts">
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useI18n } from "vue-i18n";
import { Router, useRouter } from "vue-router";
import { Icon } from "@iconify/vue";

const i18n = useI18n();
const router: Router = useRouter();

const onLocaleChange = (value: string) => {
  const newLocale: string = value;
  if (newLocale === i18n.locale.value) {
    return;
  }
  router.push({
    name: router.currentRoute.value.name!,
    params: {
      locale: newLocale,
    },
  });
};
</script>
