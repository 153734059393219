import gql from "graphql-tag";
import { HomePageFragment } from "../fragments/homePage-fragments";

export const GET_HOMEPAGE = gql`
  query {
    getHomePage {
      ...HomePageFragment
    }
  }
  ${HomePageFragment}
`;
