<script setup lang="ts">
import logoUrl from "../assets/images/logo.png";
import runprof from "../assets/images/runprof.svg";
import { ref } from "vue";
import illustrationUrl from "../assets/images/illustration.svg";
import { FormInput, FormCheck } from "../base-components/Form";
import Button from "../base-components/Button";

import * as yup from "yup";
import { useForm } from "vee-validate";
import { useAuthStore } from "@/stores/auth/auth-store";
import { storeToRefs } from "pinia";

const visible = ref(false);

const { doSigninWithEmailAndPassword } = useAuthStore();

const { loading } = storeToRefs(useAuthStore());

const { errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    email: yup
      .string()
      .email(() => i18n("form.enterValidEmail"))
      .required(() => i18n("form.required")),
    password: yup
      .string()
      .min(8, () => i18n("form.minPasswordLength", { length: 8 }))
      .required(() => i18n("form.required")),
    rememberMe: yup.boolean(),
  }),
});
const [email] = defineField("email");
const [password] = defineField("password");
const [rememberMe] = defineField("rememberMe");
const onsubmit = handleSubmit((values) =>
  doSigninWithEmailAndPassword(values as ILogin)
);
import { useLocalize } from "@/locale/i18n";
import { onMounted, watch } from "vue";
// Localization
const { isRTL, i18n } = useLocalize();

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.login");
});
watch(
  isRTL,
  () => (document.title = i18n("routes.app") + " | " + i18n("routes.login"))
);
</script>

<template>
  <div
    :class="[
      'login',
      '-m-3 sm:-mx-8 p-3 sm:px-8 relative h-[100dvh] lg:overflow-hidden bg-primary xl:bg-white dark:bg-darkmode-800 xl:dark:bg-darkmode-600',
      'before:hidden before:xl:block before:content-[\'\'] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%] before:absolute before:inset-y-0 before:left-0 before:transform before:rotate-[-4.5deg] before:bg-primary/20 before:rounded-[100%] before:dark:bg-darkmode-400',
      'after:hidden after:xl:block after:content-[\'\'] after:w-[57%] after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform after:rotate-[-4.5deg] after:bg-primary after:rounded-[100%] after:dark:bg-darkmode-700',
    ]"
  >
    <div
      class="absolute w-fit top-10 ltr:right-3 rtl:left-3 rtl:xl:left-auto md:right-16 bg-primary rounded z-20"
    >
      <langSwitcher />
    </div>
    <div class="container relative z-10 sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <!-- BEGIN: Login Info -->
        <div class="flex-col hidden min-h-[100dvh] xl:flex">
          <a href="" class="flex items-center pt-5 -intro-x w-fit">
            <img alt="Tawzeef CopyRights" class="w-36" :src="logoUrl" />
          </a>
          <div class="my-auto">
            <img
              alt="Tawzeef CopyRights"
              class="w-1/2 -mt-16 -intro-x"
              :src="illustrationUrl"
            />
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="flex h-[100dvh] py-5 xl:h-auto xl:py-0 xl:my-0">
          <div
            class="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-20 dark:bg-darkmode-600 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <form action="" @submit.prevent="onsubmit">
              <h2
                class="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left rtl:xl:text-right"
              >
                {{ $t("auth.signIn") }}
              </h2>
              <div class="mt-8 intro-x">
                <FormInput
                  type="text"
                  class="block px-4 py-3 intro-x login__input min-w-full xl:min-w-[350px] text-left rtl:text-right"
                  :placeholder="$t('auth.email')"
                  v-model="email"
                  :error="errors.email"
                />

                <div class="login__input" style="position: relative">
                  <FormInput
                    :type="visible ? 'text' : 'password'"
                    class="block px-4 py-3 mt-4 intro-x login__input min-w-full xl:min-w-[350px] text-left rtl:text-right"
                    :placeholder="$t('auth.password')"
                    v-model="password"
                    :error="errors.password"
                  />
                  <button
                    @click="visible = !visible"
                    class="toggle-password-btn top-[-10px]"
                    :style="isRTL ? 'top: -10px;' : 'right: 0px;'"
                    type="button"
                  >
                    <Icon icon="mdi:eye-off" v-if="!visible" class="text-xl" />

                    <Icon icon="mdi:eye" class="text-xl" v-else></Icon>
                  </button>
                </div>
              </div>
              <div
                class="flex mt-4 text-xs intro-x text-slate-600 dark:text-slate-500 sm:text-sm"
              >
                <div class="flex items-center mr-auto">
                  <FormCheck.Input
                    id="remember-me"
                    type="checkbox"
                    class="mr-2 border"
                    v-model="rememberMe"
                  />
                  <label
                    class="cursor-pointer select-none"
                    htmlFor="remember-me"
                  >
                    {{ $t("auth.rememberMe") }}
                  </label>
                </div>

                <router-link :to="{ name: 'forget-password-page' }">
                  {{ $t("auth.forgetPassword") }}
                </router-link>
              </div>
              <div
                class="mt-5 text-center intro-x xl:mt-8 xl:text-left rtl:xl:text-right"
              >
                <Button
                  :isLoading="loading"
                  :disabled="loading"
                  variant="primary"
                  class="w-full px-4 py-3 align-top xl:w-32 xl:mr-3"
                  type="submit"
                >
                  {{ $t("auth.login") }}
                </Button>
                <!-- <Button
                variant="outline-secondary"
                class="w-full px-4 py-3 mt-3 align-top xl:w-32 xl:mt-0"
              >
                Register
              </Button> -->
              </div>
            </form>
            <div
              class="mt-10 text-center intro-x xl:mt-14 text-slate-600 dark:text-slate-500 xl:text-left rtl:xl:text-right"
            >
              {{ $t("auth.bySigninUp") }},
              {{ $t("auth.youAgreeToOur") }}
              <a class="text-primary dark:text-slate-200" href="">
                {{ $t("auth.termsAndConditions") }}
              </a>
              &
              <a class="text-primary dark:text-slate-200" href="">
                {{ $t("auth.privacyPolicy") }}
              </a>
            </div>

            <a
              href="https://runprof.com/"
              target="_blank"
              class="flex items-center pt-5 -intro-x mt-10 mx-auto w-fit"
            >
              <img
                alt="Tawzeef CopyRights"
                class="w-24 brightness-0"
                :src="runprof"
              />
            </a>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.login {
  direction: ltr !important;
}

.toggle-password-btn {
  position: absolute !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  z-index: 100 !important;
  margin-top: 16px !important;
  padding: 10px !important;
  width: 3rem;
}

.toggle-password-btn:focus {
  box-shadow: none !important;
}

.login__input {
  box-shadow: none !important;
}
</style>
