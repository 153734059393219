<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ComputedRef } from "vue";

const { i18n } = useLocalize();

const salaryPageData =
  inject<ComputedRef<ISalarySurveyPage>>("salaryPageData")!;
const objAccessor = inject<ComputedRef<ISalarySurveyObject>>("objAccessor")!;

const handleAddCard = () => {
  salaryPageData.value[objAccessor.value].brief.SalarySurveyCardsSection.push({
    title: "",
    paragraph: "",
    image: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
  });
};

const handleRemoveCard = (index: number) => {
  salaryPageData.value[objAccessor.value].brief.SalarySurveyCardsSection.splice(
    index,
    1
  );
};
</script>

<template>
  <div class="mb-5">
    <h2 class="text-lg mt-12 font-medium">
      {{ $t("website.services.salarySurvey.brief") }}
    </h2>
  </div>

  <div class="mt-4">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.title") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="salaryPageData[objAccessor].brief.title"
        />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("common.paragraph") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="salaryPageData[objAccessor].brief.paragraph"
        />
      </div>
    </div>
  </div>

  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.firstCallToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="salaryPageData[objAccessor].brief.buttons[0].text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="salaryPageData[objAccessor].brief.buttons[0].link"
        />
      </div>
    </div>
  </div>

  <div class="mt-6">
    <h4 class="font-medium">
      {{ $t("website.home.hero.fields.secondCallToAction") }}
    </h4>
    <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.name") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="salaryPageData[objAccessor].brief.buttons[1].text"
        />
      </div>

      <div>
        <FormLabel
          class="!text-slate-400 text-xs font-medium mt-2"
          htmlFor="crud-form-1"
        >
          {{ $t("common.link") }}</FormLabel
        >
        <FormInput
          id="crud-form-1"
          type="text"
          class="w-full"
          v-model="salaryPageData[objAccessor].brief.buttons[1].link"
        />
      </div>
    </div>
  </div>

  <h4 class="font-medium mt-5">
    {{ $t("common.surveyCards") }}
  </h4>
  <DraggableList
    @removeItem="handleRemoveCard"
    @addItem="handleAddCard"
    :theArray="salaryPageData[objAccessor].brief.SalarySurveyCardsSection"
    class="mt-5"
  >
    <template v-slot="{ index }">
      <div class="w-full">
        <div class="grid-cols-2 md:grid-cols-2 gap-3 mt-3 sm:grid">
          <div>
            <FormLabel class="font-medium" htmlFor="crud-form-1">
              {{ $t("common.title") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="
                salaryPageData[objAccessor].brief.SalarySurveyCardsSection[
                  index
                ].title
              "
            />
          </div>
          <div>
            <FormLabel class="font-medium" htmlFor="crud-form-1">
              {{ $t("common.paragraph") }}</FormLabel
            >
            <FormInput
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="
                salaryPageData[objAccessor].brief.SalarySurveyCardsSection[
                  index
                ].paragraph
              "
            />
          </div>
        </div>
        <SeoImageInput
          class="my-4"
          v-model="
            salaryPageData[objAccessor].brief.SalarySurveyCardsSection[index]
              .image
          "
        />
      </div>
    </template>
  </DraggableList>

  <!-- <div class="mt-4">
    <h4 class="font-medium mb-4">
      {{ $t("common.media") }}
    </h4>
    <SeoImageInput class="mb-4" />
  </div> -->
</template>
