// TABLE COLUMNS
export const columns = [
  {
    field: "name",
    label: "table.title",
    type: "localized",
    // type: "string",
  },
  {
    field: "createdAt",
    label: "table.createdAt",
    type: "date",
  },

  {
    field: "action",
    label: "table.actions",
    type: "action",
    align: "end",
    ignorePrint: "ignore-0",
    ignorePrintRow: "ignore-00",
  },
];

export const rows = [
  {
    id: "Rn2",
    title: "partner1 title",
    paragraph: "partners paragraph",
    status: "enabled",
    categoryName: "Software 1",
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
  },
  {
    id: "Rn23",
    title: "partner2 title",
    paragraph: "partners paragraph",

    status: "enabled",
    categoryName: "Software 2",
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
  },
  {
    id: "Rn25",
    title: "partners title",
    paragraph: "partners paragraph",

    status: "disabled",
    categoryName: "Software 3",
    linkedIn: "test@test.test",
    phoneNumber: "01228313626",
  },
];
