// TABLE COLUMNS
export const columns = [
  {
    field: "image",
    subField: "title",
    label: "table.client",
    type: "object",
  },
  {
    field: "action",
    label: "table.actions",
    type: "action",
    align: "end",
    ignorePrint: "ignore-0",
    ignorePrintRow: "ignore-00",
  },
];
