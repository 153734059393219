import gql from "graphql-tag";
import { MetaTagsFragment } from "./base-fragments";

export const BlogCategoryFragment = gql`
  fragment BlogCategoryFragment on Category {
    name {
      en
      ar
    }
    slug{
      en
      ar
    }
    id
    createdAt
    updatedAt
    metaTags {
      ...MetaTagsFragment
    }
  }
  ${MetaTagsFragment}
`;
