import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { Icon } from "@iconify/vue";
import { vueI18n } from "./locale/i18n";
import Table from "./base-components/CustomeTable";
import Pagination from "./base-components/Pagination";
import { apolloClient } from "./lib/graphql/ApolloClient";
import langSwitcher from "./base-components/LangSwitcher";
import DraggableList from "./base-components/DraggableList";
import SeoImageInput from "@/base-components/SeoImageInput";
import { DefaultApolloClient } from "@vue/apollo-composable";
import TableFilter from "./base-components/CustomeTable/TableFilter";
import SeoMetaFields from "./base-components/SeoMetaFields/SeoMetaFields.vue";
import "./assets/css/app.css";
import * as Sentry from "@sentry/vue";

import { CkeditorPlugin } from "@ckeditor/ckeditor5-vue";
import "ckeditor5/ckeditor5.css";

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  tracePropagationTargets: ["localhost", "https://tawzef-ecd44.web.app/"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  logErrors: true,
  normalizeDepth: 10,
});

app.use(router);
app.use(createPinia());
app.use(vueI18n);
app.component("Icon", Icon);
app.component("langSwitcher", langSwitcher);
app.component("DataList", Table);
app.component("Pagination", Pagination);
app.component("TableFilter", TableFilter);
app.component("SeoImageInput", SeoImageInput);
app.component("DraggableList", DraggableList);
app.component("SeoMetaFields", SeoMetaFields);

app.use(CkeditorPlugin);

app.provide(DefaultApolloClient, apolloClient);

app.mount("#app");
