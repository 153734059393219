<script setup lang="ts">
import logoUrl from "../assets/images/logo.png";
import illustrationUrl from "../assets/images/illustration.svg";
import { FormInput } from "../base-components/Form";
import Button from "../base-components/Button";
import runprof from "../assets/images/runprof.svg";
import { useLocalize } from "@/locale/i18n";
import { onMounted, watch } from "vue";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { useAuthStore } from "@/stores/auth/auth-store";
import { storeToRefs } from "pinia";

// Localization
const { isRTL, i18n } = useLocalize();

const { doSendPasswordResetEmail } = useAuthStore();

const { loadingPasswordResetEmail: loading } = storeToRefs(useAuthStore());

const { errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    email: yup
      .string()
      .email(() => i18n("form.enterValidEmail"))
      .required(() => i18n("form.required")),
  }),
});
const [email] = defineField("email");

const onsubmit = handleSubmit((values) =>
  doSendPasswordResetEmail(values.email)
);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.forgetPassword");
});
watch(
  isRTL,
  () =>
    (document.title =
      i18n("routes.app") + " | " + i18n("routes.forgetPassword"))
);
</script>

<template>
  <div
    :class="[
      'login',
      '-m-3 sm:-mx-8 p-3 sm:px-8 relative h-screen lg:overflow-hidden bg-primary xl:bg-white dark:bg-darkmode-800 xl:dark:bg-darkmode-600',
      'before:hidden before:xl:block before:content-[\'\'] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%] before:absolute before:inset-y-0 before:left-0 before:transform before:rotate-[-4.5deg] before:bg-primary/20 before:rounded-[100%] before:dark:bg-darkmode-400',
      'after:hidden after:xl:block after:content-[\'\'] after:w-[57%] after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform after:rotate-[-4.5deg] after:bg-primary after:rounded-[100%] after:dark:bg-darkmode-700',
    ]"
  >
    <div
      class="absolute w-fit top-10 ltr:right-3 rtl:left-3 rtl:xl:left-auto md:right-16 bg-primary rounded z-20"
    >
      <langSwitcher />
    </div>
    <div class="container relative z-10 sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <!-- BEGIN: Login Info -->
        <div class="flex-col hidden min-h-screen xl:flex">
          <a href="" class="flex items-center pt-5 -intro-x">
            <img alt="Tawzeef CopyRights" class="w-36" :src="logoUrl" />
          </a>
          <div class="my-auto">
            <img
              alt="Tawzeef CopyRights"
              class="w-1/2 -mt-16 -intro-x"
              :src="illustrationUrl"
            />
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0">
          <div
            class="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-20 dark:bg-darkmode-600 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="text-2xl font-bold text-center intro-x xl:text-3xl ltr:xl:text-left rtl:xl:text-right"
            >
              {{ $t("auth.forgetPassword") }}
            </h2>
            <div
              class="text-center ltr:xl:text-left rtl:xl:text-right intro-x text-slate-400 mt-4"
            >
              {{ $t("auth.forgetPasswordSubTitle1") }} <br />
              {{ $t("auth.forgetPasswordSubTitle2") }}.
            </div>
            <div class="mt-8 intro-x">
              <FormInput
                type="text"
                class="block px-4 py-3 intro-x login__input min-w-full xl:min-w-[350px] text-left rtl:text-right"
                v-model="email"
                :error="errors.email"
                :placeholder="$t('auth.email')"
              />
            </div>
            <div
              class="flex mt-4 text-xs intro-x text-slate-600 dark:text-slate-500 sm:text-sm"
            ></div>
            <div
              class="mt-5 text-center intro-x xl:mt-8 ltr:xl:text-left rtl:xl:text-right"
            >
              <Button
                variant="primary"
                class="w-full px-4 py-3 align-top xl:w-32 xl:mr-3"
                :isLoading="loading"
                :disabled="loading"
                @click="onsubmit"
              >
                {{ $t("auth.sendEmail") }}
              </Button>
              <Button
                variant="outline-secondary"
                class="w-full px-4 py-3 mt-3 align-top xl:w-fit xl:mt-0"
              >
                <router-link :to="{ name: 'login-page' }">
                  {{ $t("auth.backToLogin") }}
                </router-link>
              </Button>
            </div>
            <div
              class="mt-10 text-center intro-x xl:mt-14 text-slate-600 dark:text-slate-500 xl:text-left rtl:xl:text-right"
            >
              {{ $t("auth.bySigninUp") }},
              {{ $t("auth.youAgreeToOur") }}
              <a class="text-primary dark:text-slate-200" href="">
                {{ $t("auth.termsAndConditions") }}
              </a>
              &
              <a class="text-primary dark:text-slate-200" href="">
                {{ $t("auth.privacyPolicy") }}
              </a>
            </div>
            <a
              href="https://runprof.com/"
              target="_blank"
              class="flex items-center pt-5 -intro-x mt-10 mx-auto w-fit"
            >
              <img
                alt="Tawzeef CopyRights"
                class="w-24 brightness-0"
                :src="runprof"
              />
            </a>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>
<style scoped>
.login {
  direction: ltr !important;
}
</style>
