<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { ref, ComputedRef, inject } from "vue";
const aboutusData = inject<ComputedRef<IAboutUsPage>>("aboutusData")!;

const objAccessor = inject<ComputedRef<IAboutUsObject>>("objAccessor")!;
</script>

<template>
  <!-- Begin About us section -->
  <div class="my-20">
    <div class="mb-3 intro-y pb-4 mt-10">
      <h2 class="text-lg md:text-xl font-medium">
        {{ $t("website.home.aboutUs.brief") }}
      </h2>

      <p class="text-slate-400 text-xs mt-1 font-normal">
        {{ $t("website.home.aboutUs.description") }}
      </p>
    </div>

    <!--About us Title and Paragraph -->
    <div>
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.home.aboutUs.fields.title") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].brief.title"
          />
        </div>

        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.home.aboutUs.fields.paragraph") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].brief.paragraph"
          />
        </div>
      </div>
    </div>
    <!-- End About us Title En and Ar -->

    <!--About us call to action  -->
    <div class="mt-4">
      <h4 class="font-medium">
        {{ $t("website.aboutUs.hero.fields.callToAction") }}
      </h4>
      <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.name") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].brief.button.text"
          />
        </div>
        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.link") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="url"
            class="w-full"
            v-model="aboutusData[objAccessor].brief.button.link"
          />
        </div>
      </div>
    </div>
    <!-- End about us call to action -->

    <div class="mt-4">
      <h4 class="font-medium mb-2">
        {{ $t("common.media") }}
      </h4>
      <div class="mt-3">
        <SeoImageInput
          v-model="aboutusData[objAccessor].brief.image"
          acceptedFiles="video/*"
        />
      </div>
    </div>
    <!-- End About us call to action  -->
  </div>
</template>
