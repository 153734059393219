<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import Button from "@/base-components/Button";
import LoadingSpinner from "@/components/Loading";

import { useLocalize } from "@/locale/i18n";
import Tab from "@/base-components/Headless/Tab";
import ServiceSalaryHeroParagraph from "./Components/ServiceSalaryHeroParagraph.vue";
import ServiceSalaryBrief from "./Components/ServiceSalaryBrief.vue";
import ServiceSalaryBenfits from "./Components/ServiceSalaryBenfits.vue";
import ServiceSalaryBirches from "./Components/ServiceSalaryBirches.vue";
import ServiceSalarySubscriptions from "./Components/ServiceSalarySubscriptions.vue";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { GET_SALARYSURVEY } from "@/lib/graphql/queries/salarySurvey";
import { SET_SALARYSURVEY } from "@/lib/graphql/mutations/salarySurvey";
import { initialSalarySurveyPage } from "./initialServiceSalary";
import { toast } from "vue-sonner";

// Localization
const { isRTL, i18n, language } = useLocalize();

const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<ISalarySurveyObject>(() =>
  lang.value === "ar" ? "ArSalarySurveyPage" : "EnSalarySurveyPage"
);

type SalarySurveyResult = {
  getSalarySurveyPage: ISalarySurveyPage;
};

// Fetch SalarySurvey

const { result: SalarySurveyPage, loading: getSalarySurveyLoading } =
  useQuery<SalarySurveyResult>(GET_SALARYSURVEY, null, {
    fetchPolicy: "no-cache",
  });

const salaryPageData = ref<ISalarySurveyPage>(initialSalarySurveyPage);
watchEffect(() => {
  if (SalarySurveyPage.value) {
    salaryPageData.value = {
      ...salaryPageData.value,
      ...SalarySurveyPage.value.getSalarySurveyPage,
    };
  }
});

provide("salaryPageData", salaryPageData);
provide("objAccessor", objAccessor);

//SET DAT
const { mutate: setSalarySurvey, loading: setSalarySurveyLoading } =
  useMutation(SET_SALARYSURVEY);

const loading = computed(
  () => setSalarySurveyLoading.value || getSalarySurveyLoading.value
);

const handleSubmit = async () => {
  const data = { ...salaryPageData.value };
  delete data.metaTags;
  window.scrollTo({
    top: 120,
    behavior: "smooth",
  });
  try {
    await setSalarySurvey({ data });
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    toast.error(error.message);
  }
};

onMounted(async () => {
  document.title =
    i18n("routes.app") + " | " + i18n("routes.services-salarySurvey");
});
watch(
  isRTL,
  () =>
    (document.title =
      i18n("routes.app") + " | " + i18n("routes.services-salarySurvey"))
);
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->

      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <!-- Hero section title -->
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-base font-medium">
            {{ $t("website.services.salarySurvey.title") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("website.services.salarySurvey.description") }}
          </p>
        </div>
        <!-- end of  hero section title -->

        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>

        <div
          class="h-screen w-full flex items-center justify-center absolute"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>

        <ServiceSalaryHeroParagraph />

        <ServiceSalaryBrief />

        <ServiceSalaryBenfits />

        <ServiceSalaryBirches />

        <ServiceSalarySubscriptions />

        <div class="mt-20 justify-end flex gap-2">
          <!-- <Button type="button" variant="outline-secondary" class="w-24 mr-1">
            {{ $t("common.cancel") }}
          </Button> -->
          <Button
            type="button"
            variant="primary"
            class="w-24"
            @click="handleSubmit"
            :disabled="loading"
            :isLoading="loading"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
