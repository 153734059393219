<script setup lang="ts">
import { inject, ComputedRef, computed, ref } from "vue";
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";

const { i18n } = useLocalize();

const handleAddItem = () => {
  const lastIndex =
    homePageData.value[accessor.value]?.aboutUs.statistics.length;
  const title = `${i18n("common.statistics")} ${lastIndex + 1}`;
  homePageData.value[accessor.value]?.aboutUs.statistics.push({
    title: "",
    number: "",
    paragraph: "",
    image: {
      title,
      alt: "",
      caption: "",
      description: "",
      url: "",
    },
  });

};

const handleRemoveItem = (index: number) => {
  homePageData.value[accessor.value]?.aboutUs.statistics.splice(index, 1);
};
// to handle v-model
const homePageData = inject<ComputedRef<IHomePage>>("homePageData")!;
const objAccessor = inject<ComputedRef<IHomeObject> | IHomeObject>(
  "objAccessor",
  "EnHomePage"
);

const accessor = computed<IHomeObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});
</script>

<template>
  <!-- Begin About us section -->
  <div class="my-20">
    <div class="mb-3 intro-y pb-4 mt-10">
      <h2 class="text-base font-medium">
        {{ $t("website.home.aboutUs.title") }}
      </h2>
      <p class="text-slate-400 text-xs mt-1 font-normal">
        {{ $t("website.home.aboutUs.description") }}
      </p>
    </div>
    <!--About us Title & paragraph -->
    <div>
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.home.aboutUs.fields.title") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="homePageData[objAccessor].aboutUs.title"
          />
        </div>
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.home.aboutUs.fields.paragraph") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="homePageData[objAccessor].aboutUs.paragraph"
          />
        </div>
      </div>
    </div>
    <!-- End About us Title En and Ar -->

    <!--About us call to action  -->
    <div class="mt-4">
      <h4 class="font-medium">
        {{ $t("website.home.aboutUs.fields.callToAction") }}
      </h4>
      <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.name") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="homePageData[objAccessor].aboutUs.button.text"
          />
        </div>
        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.link") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="homePageData[objAccessor].aboutUs.button.link"
          />
        </div>
      </div>
    </div>
    <!-- End About us call to action  -->

    <!--About us call to Statistics  -->
    <div class="mt-4">
      <h4 class="font-medium">
        {{ $t("website.home.aboutUs.fields.statistics") }}
      </h4>
      <DraggableList
        @removeItem="handleRemoveItem"
        @addItem="handleAddItem"
        :theArray="homePageData[objAccessor].aboutUs.statistics"
        class="mt-10"
      >
        <template v-slot="{ index }">
          <div class="w-full">
            <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("common.name") }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    homePageData[objAccessor].aboutUs.statistics[index].title
                  "
                />
              </div>
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("common.paragraph") }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    homePageData[objAccessor].aboutUs.statistics[index]
                      .paragraph
                  "
                />
              </div>
            </div>

            <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-3 sm:grid">
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("website.home.aboutUs.fields.number") }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    homePageData[objAccessor].aboutUs.statistics[index].number
                  "
                />
              </div>
              <div :key="accessor">
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("website.home.aboutUs.fields.media") }}</FormLabel
                >
                <SeoImageInput
                  v-model="
                    homePageData[objAccessor].aboutUs.statistics[index].image
                  "
                />
              </div>
            </div></div
        ></template>
      </DraggableList>
    </div>
    <!-- End About us call to Statistics  -->
  </div>
</template>
