export interface HomePage {
  ArHomePage: ContentHomePage;
  EnHomePage: ContentHomePage;
  metaTags?: MetaTags;
}

export interface HomePageData {
  getHomePage: HomePage;
}

export type HomeObject = "ArHomePage" | "EnHomePage";

export interface ContentHomePage {
  title: string;
  paragraph: string;
  buttons: Button[];
  images: Image[];
  ourCustomersTitle: string;
  ourCustomers: string[];
  aboutUs: AboutUs;
  services: Services;
  outSourcing: OutSourcing;
  testimonials: Testimonials[];
}

export interface MetaTagsStructure {
  robots: string;
  canonical: string;
  description: string;
  social: Social[];
  title: string;
  keywords: string[];
}

export interface MetaTags {
  en: MetaTagsStructure;
  ar: MetaTagsStructure;
}

export interface Social {
  socialNetwork: SocialType;
  description: string;
  title: string;
  image: Image;
}

export interface Image {
  alt: string;
  caption: string;
  description: string;
  title: string;
  url: string;
}

export enum SocialType {
  Twitter = "twitter",
  Facebook = "facebook",
}

export interface AboutUs {
  title: string;
  paragraph: string;
  button: Button;
  statistics: Statistics[];
}

export interface Statistics {
  title?: string;
  number?: string;
  paragraph?: string;
  image?: Image;
}

export interface Button {
  text: string;
  link: string;
}

export interface Services {
  title: string;
  paragraph: string;
  cardsInput: Cards[];
}

export interface Cards {
  title: string;
  paragraph: string;
  image: Image;
  button: Button;
}

export interface OutSourcing {
  title: string;
  paragraph: string;
  button: Button;
}

export interface Testimonials {
  review: string;
  reviewerName: string;
  reviewerTitle: string;
  reviewerImage: Image;
  companyImage: Image;
}
