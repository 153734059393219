<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";

const { i18n } = useLocalize();

const servicHRData = inject<IHRPage>("servicHRData", {} as IHRPage);
const objAccessor = inject<ComputedRef<IHRObject> | IHRObject>(
  "objAccessor",
  "EnHRPage"
);

const accessor = computed<IHRObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});

const handleAddItem = () => {
  const lastIndex =
    servicHRData.value[accessor.value]?.assessmentCenter.paragraphs.length;
  const title = `${i18n("common.statistics")} ${lastIndex + 1}`;
  servicHRData.value[accessor.value]?.assessmentCenter.paragraphs.push({
    title: "",
  });
};

const handleRemoveItem = (index: number) => {
  servicHRData.value[accessor.value]?.assessmentCenter.paragraphs.splice(
    index,
    1
  );
};
</script>

<template>
  <div class="my-10">
    <div class="mb-3 intro-y pb-4 mt-10">
      <h2 class="text-base font-medium">
        {{ $t("website.services.hr.assessmentCenter.title") }}
      </h2>

      <p class="text-slate-400 text-xs mt-1 font-normal">
        {{ $t("website.services.hr.assessmentCenter.description") }}
      </p>
    </div>
    <!--About us Title En and Ar -->
    <div class="mt-4">
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("common.title") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="servicHRData[objAccessor].assessmentCenter.title"
          />
        </div>
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("common.description") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="servicHRData[objAccessor].assessmentCenter.description"
          />
        </div>
      </div>
    </div>
    <!-- End About us Title En and Ar -->

    <!--About us paragraph En and Ar -->
    <h4 class="font-medium mt-5">
      {{ $t("common.paragraphs") }}
    </h4>
    <DraggableList
      @removeItem="handleRemoveItem"
      @addItem="handleAddItem"
      :theArray="servicHRData[objAccessor].assessmentCenter.paragraphs"
      class="mt-5"
    >
      <template v-slot="{ index }">
        <div class="w-full">
          <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-3 sm:grid">
            <div>
              <FormLabel
                class="!text-slate-400 text-xs font-medium mt-2"
                htmlFor="crud-form-1"
              >
                {{ $t("common.name") }}</FormLabel
              >
              <FormInput
                id="crud-form-1"
                type="text"
                class="w-full"
                v-model="
                  servicHRData[objAccessor].assessmentCenter.paragraphs[index]
                "
              />
            </div>
          </div>
        </div>
      </template>
    </DraggableList>
    <div class="mt-6">
      <h4 class="font-medium">
        {{ $t("website.home.hero.fields.firstCallToAction") }}
      </h4>
      <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.name") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="servicHRData[objAccessor].assessmentCenter.buttons[0].text"
          />
        </div>

        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.link") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="servicHRData[objAccessor].assessmentCenter.buttons[0].link"
          />
        </div>
      </div>
    </div>

    <div class="mt-6">
      <h4 class="font-medium">
        {{ $t("website.home.hero.fields.secondCallToAction") }}
      </h4>
      <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.name") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="servicHRData[objAccessor].assessmentCenter.buttons[1].text"
          />
        </div>

        <div>
          <FormLabel
            class="!text-slate-400 text-xs font-medium mt-2"
            htmlFor="crud-form-1"
          >
            {{ $t("common.link") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="servicHRData[objAccessor].assessmentCenter.buttons[1].link"
          />
        </div>
      </div>
    </div>
    <!-- End About us call to action  -->
  </div>
</template>
