<script setup lang="ts">
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ref } from "vue";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import FilterFields from "./FilterFields";
import IFilter from "@/types/TableFilter";
import Button from "@/base-components/Button";
dayjs.extend(utc);
// Localization
const emit = defineEmits(["filter", "reset"]);
interface IProps {
  filterFields: IFilter[];
  disabled: boolean;
}
interface IDateRange {
  from: string;
  to: string;
}
const props = withDefaults(defineProps<IProps>(), {
  disabled: false,
});
const selectedFieldString = ref<string>("");
const selectedField = ref<IFilter>(props.filterFields[0]);
const filterValue = ref();
const createFilter = (
  field: string,
  value: any,
  operator?: string | Record<string, string>
) => ({
  field,
  operator,
  value,
});
const filterCreators: any = {
  date: {
    component: FilterFields.FilterFieldDate,
    creator: (value: string) =>
      createFilter(
        selectedField.value.field,
        value,
        selectedField.value.operator
      ),
  },
  dateRange: {
    component: FilterFields.FilterFieldDateRange,
    creator: (value: IDateRange) => {
      return [
        createFilter(selectedField.value.field, value.from, "less"),
        createFilter(selectedField.value.field, value.to, "more"),
      ];
    },
  },

  text: {
    component: FilterFields.FilterFieldText,
    creator: (value: string) =>
      createFilter(
        selectedField.value.field,
        value,
        selectedField.value.operator
      ),
  },
  list: {
    component: FilterFields.FilterFieldList,
    creator: (value: string) =>
      createFilter(
        selectedField.value.field,
        value,
        selectedField.value.operator
      ),
  },
};
const inputValue = ref<string>("");
const doFilter = () => {
  const { type } = selectedField.value;
  const filterCreator =
    filterCreators[type].creator || filterCreators.text.creator;
  const filter = filterCreator(inputValue.value);
  const orderBy = selectedField.value.orderBy || null;
  emit("filter", filter, orderBy);
};
function getFilterComponentName(type: any) {
  // Return the component name based on the Filter type
  return filterCreators[type].component || filterCreators.text.component; // DefaultFilter is the Test Filter
}
const resetAll = () => {
  filterValue.value = "";
};
const parseSelectedField = () => {
  selectedField.value = JSON.parse(selectedFieldString.value);
  resetAll();
};
</script>

<template>
  <form class="flex gap-2 w-full min-[1150px]:w-auto flex-col sm:flex-row">
    <div class="flex items-stretch w-full sm:w-auto">
      <Select v-model="selectedFieldString" @update:modelValue="parseSelectedField"
        class="shadow-none justify-start !py-0">
        <SelectTrigger class="shadow-none border-slate-200 border flex gap-4 !ring-0 h-auto py-0">
          <div class="flex gap-1.5 py-2">
            <Icon icon="basil:settings-adjust-outline" class="text-xl" />
            <SelectValue :placeholder="$t('common.filters')" class="capitalize whitespace-nowrap" />
          </div>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem v-for="(field, index) in filterFields" :key="index" :value="JSON.stringify(field)"
              class="capitalize">
              {{ $t(field.label) }}
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>

    <!-- Begin Filter Component -->
    <div class="sm:flex items-center xl:mt-0 w-full min-[1150px]:w-auto">
      <component class="w-full min-[1150px]:w-auto" :is="getFilterComponentName(selectedField.type)" v-model="inputValue"
        :selectedField="selectedField" @doFilter="doFilter" />
    </div>
    <!-- Ending Filter Component -->

    <!-- Begin Filter Action -->
    <div id="form-actions" class="xl:mt-0 flex gap-2 w-full sm:w-auto">
      <Button variant="primary" class="py-2 px-4 w-full text-xs" :disabled="disabled" @click="doFilter">
        {{ $t("common.apply") }}
      </Button>
      <Button varaint="outline" class="py-2 px-4 w-full text-xs shadow-none border-slate-200" :disabled="disabled"
        @click="emit('reset')">
        {{ $t("common.table.filter.reset") }}
      </Button>
    </div>
    <!-- Ending Filter Action -->
  </form>
</template>
