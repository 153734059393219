import Dropzone, { DropzoneOptions, DropzoneFile } from "dropzone";
import { DropzoneElement } from "./Dropzone.vue";
import { deleteFromStorage, upload } from "@/lib/uploader/file-uploader";

interface CustomDropzoneOptions extends DropzoneOptions {
  storagePath?: string;
  onUploadSuccess?: (downloadURL: string) => void;
}

const init = (
  el: DropzoneElement,
  props: {
    options: CustomDropzoneOptions;
    refKey?: string;
  }
) => {
  Dropzone.autoDiscover = false;
  if (!el.dropzone) {
    const dropzone = new Dropzone(el, {
      ...props.options,
      init: function () {
        this.on("addedfile", async (file: DropzoneFile) => {
          const storagePath = props.options.storagePath || "uploads";
          const { publicUrl } = await upload(storagePath, file, file.name);
          if (props.options.onUploadSuccess) {
            props.options.onUploadSuccess(publicUrl);
            this.emit("queuecomplete");
            this.emit("success", file);
          }
        });
        this.on("removedfile", (file: DropzoneFile) => {
          const storagePath = props.options.storagePath || "uploads";
          deleteFromStorage(storagePath, file, file.name);
        });
        this.on("success", (file) => this.emit("complete", file));
      },
    });
    el.dropzone = dropzone;
  }
};

export { init };
