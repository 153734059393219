<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";

import { ref, inject, ComputedRef } from "vue";

const aboutusData = inject<ComputedRef<IAboutUsPage>>("aboutusData")!;

const objAccessor = inject<ComputedRef<IAboutUsObject>>("objAccessor")!;
const handleAddStats = () => {
  // aboutUsStatistics.value.push({});
  aboutusData.value[objAccessor.value].OurAchievements.statistics.push({
    title: "",
    number: "",
    paragraph: "",
    image: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
  });
};

const handleRemoveStats = (index: number) => {
  aboutusData.value[objAccessor.value].OurAchievements.statistics.splice(
    index,
    1
  );
};
</script>

<template>
  <div class="my-20">
    <div class="mb-3 intro-y pb-4 mt-10">
      <h2 class="text-lg md:text-xl font-medium">
        {{ $t("website.aboutUs.ourAchievements.title") }}
      </h2>

      <p class="text-slate-400 text-xs mt-1 font-normal">
        {{ $t("website.aboutUs.ourAchievements.description") }}
      </p>
    </div>

    <!-- ACHIVMENT TITLE AND PARAGRAPH -->
    <div>
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.aboutUs.ourAchievements.fields.title") }}
          </FormLabel>
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].OurAchievements.title"
          />
        </div>

        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{
              $t("website.aboutUs.ourAchievements.fields.paragraph")
            }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="aboutusData[objAccessor].OurAchievements.paragraph"
          />
        </div>
      </div>
    </div>
    <!-- END ACHIVMENT TITLE AND PARAGRAPH -->

    <div class="mt-4">
      <h4 class="font-medium mb-2">
        {{ $t("website.aboutUs.vissionMission.fields.media") }}
      </h4>

      <div class="mt-3">
        <div>
          <SeoImageInput
            v-model="aboutusData[objAccessor].OurAchievements.image"
          />
        </div>
      </div>
    </div>

    <!-- Statistics Section -->
    <div class="mt-6">
      <h4 class="font-medium">
        {{ $t("website.home.aboutUs.fields.statistics") }}
      </h4>

      <DraggableList
        @removeItem="handleRemoveStats"
        @addItem="handleAddStats"
        :theArray="aboutusData[objAccessor].OurAchievements.statistics"
        class="mt-7"
      >
        <template v-slot="{ index }">
          <div class="w-full">
            <!-- Statistics Name , Paragraph -->
            <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("common.title") }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    aboutusData[objAccessor].OurAchievements.statistics[index]
                      .title
                  "
                />
              </div>

              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("common.paragraph") }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    aboutusData[objAccessor].OurAchievements.statistics[index]
                      .paragraph
                  "
                />
              </div>
            </div>
            <!-- End Statistics Name , Paragraph -->

            <!-- Number and Seo Image -->
            <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-3 sm:grid">
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("website.home.aboutUs.fields.number") }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    aboutusData[objAccessor].OurAchievements.statistics[index]
                      .number
                  "
                />
              </div>
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("website.home.aboutUs.fields.media") }}</FormLabel
                >
                <SeoImageInput
                  v-model="
                    aboutusData[objAccessor].OurAchievements.statistics[index]
                      .image
                  "
                />
              </div>
            </div>
          </div>
        </template>
      </DraggableList>
    </div>
  </div>
</template>
