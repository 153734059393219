import gql from "graphql-tag";
import { MemberFragment } from "../fragments/member-fragments";

export const GET_MEMBERS = gql`
  query GET_MEMBERS {
    getMembers {
      member {
        ...MemberFragment
      }
      totalRecords
    }
  }
  ${MemberFragment}
`;

export const GET_MEMBER = gql`
  query GET_MEMBER($memberId: String!) {
    getMember(memberId: $memberId) {
      ...MemberFragment
    }
  }
  ${MemberFragment}
`;

export const GET_CURRENT_MEMBER = gql`
  query GET_CURRENT_MEMBER {
    memberFetchMe {
      ...MemberFragment
    }
  }
  ${MemberFragment}
`;

export const GET_MEMBERS_DROPMENU = gql`
  query GET_MEMBERS($pagination: Pagination) {
    getMembers(pagination: $pagination) {
      member {
        fullName
        role
        id
        email
      }
      totalRecords
    }
  }
`;
