<script setup lang="ts">
import Button from "@/base-components/Button";
import { FormInput, FormLabel } from "@/base-components/Form";
import { onMounted, ref, computed } from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { useLocalize } from "@/locale/i18n";
import { usePagination } from "@/lib/pagination";
import { GET_FAQ_QUESTIONS } from "@/lib/graphql/queries/faq";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import { DELETE_QUESIOTN } from "@/lib/graphql/mutations/faq";

import {
  columns,
  actionsDropdown,
  filterFields,
  toolbar,
} from "./questionsTableConfig";
import AddQuestionModal from "@/components/Modals/AddQuestionModal.vue";

// Localization
const { isRTL, i18n } = useLocalize();

const props = defineProps<{ id: string }>();
console.log(props.id, "its id in faq page ");

// MODAL TOGGLE
const showDeleteModal = ref(false);
interface LocalizedInput {
  ar: String;
  en: String;
}
interface Question {
  answer: LocalizedInput;
  question: LocalizedInput;
  categoryId: string;
}

type FaqQuestions = {
  getFAQQuestions: {
    question: Question[];
    totalRecords: number;
  };
};

const {
  result,
  loading: getQuestionsLoading,
  refetch,
} = useQuery<FaqQuestions>(
  GET_FAQ_QUESTIONS,
  { id: props.id },
  {
    fetchPolicy: "no-cache",
  }
);

const { mutate: deleteQuestion, loading: deleteLoading } =
  useMutation(DELETE_QUESIOTN);

const handleDelete = async (id: string) => {
  await deleteQuestion({ id });
  await refetch();
};
const loading = computed<boolean>(
  () => getQuestionsLoading.value || deleteLoading.value
);

const questions = computed<Question[]>(
  () => result?.value?.getFAQQuestions?.question || []
);

// FILTER

// FETCH FUNCTIONS

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.faqs");
});
</script>

<template>
  <div class="mt-5">
    <!-- BEGIN: Form Layout -->
    <div class="p-5 intro-y box w-full">
      <div class="col-span-12 intro-y bg-white">
        <!-- <SeoMetaFields /> -->
      </div>
      <!-- Begin Faqs -->
      <div class="">
        <data-list
          tableTitle="common.questions"
          :rows="questions"
          :columns="columns"
          :loading="loading"
          class="!px-0"
        >
          <template #deleteAll="{ selected }">
            <div @click="selected" class="flex items-center gap-2">
              <Icon icon="solar:trash-bin-trash-broken" class="w-4 h-4" />{{
                $t("common.delete")
              }}
            </div>
          </template>

          <template #costumeCreateNew>
            <AddQuestionModal @question-added="refetch" />
          </template>

          <template #default="{ row }">
            <div>
              <AddQuestionModal @question-added="refetch" :row="row">
                <template #trigger>
                  <Icon icon="solar:pen-new-square-broken" class="h-5 w-5 cursor-pointer" />
                </template>
              </AddQuestionModal>
            </div>

            <DeleteModal @delete="handleDelete(row?.id)" />
          </template>
        </data-list>
      </div>
    </div>

    <!-- END: Form Layout -->
  </div>
</template>
