import {
  FAQPage,
  ContentFAQPage,
  Categories,
  Social,
  SocialType,
  Image,
} from "@/types/website/faq";

export const initialFAQPage: FAQPage = {
  ArFAQPage: {
    title: "",
    paragraph: "",
  },
  EnFAQPage: {
    title: "",
    paragraph: "",
  },
  metaTags: {
    ar: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
    en: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
  },
};
