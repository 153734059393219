<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import Button from "@/base-components/Button";
///////////////////////////////////////////////////////////////
import DynamicList from "@/components/ui/dynamic-list";
///////////////////////////////////////////////////////////////
import DraggableList from "@/base-components/DraggableList";
import { useLocalize } from "@/locale/i18n";
import  ClassicEditor  from "@/base-components/Ckeditor/ClassicEditor.vue";
import Dropzone from "@/base-components/Dropzone";
import Tab from "@/base-components/Headless/Tab";
import ServiceHRHeroParagraph from "./Components/ServiceHRHeroParagraph.vue";
import ServiceGapAnalysis from "./Components/ServiceGapAnalysis.vue";
import ServiceBusinessStrategeis from "./Components/ServiceBusinessStrategeis.vue";
import ServiceManagmentSystem from "./Components/ServiceManagmentSystem.vue";
import ServiceAssessmentCenter from "./Components/ServiceAssessmentCenter.vue";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
import { GET_HOMEPAGE } from "@/lib/graphql/queries/home";
import { initialHRPage } from "./initialServiceHR";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { GET_HR } from "@/lib/graphql/queries/serviceHR";
import LoadingSpinner from "@/components/Loading";
import { SET_HR } from "@/lib/graphql/mutations/serviceHR";
import { toast } from "vue-sonner";

// Import styles

// Localization
const { isRTL, i18n, language } = useLocalize();

const servicHRData = ref<IHRPage>(initialHRPage);
const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IHRObject>(() =>
  lang.value === "ar" ? "ArHRPage" : "EnHRPage"
);

// Fetch HomePage
const { result: hrPage, loading: getHRPageLoading } = useQuery(GET_HR, null, {
  fetchPolicy: "no-cache",
});

provide("servicHRData", servicHRData);
provide("objAccessor", objAccessor);

// Watch for the servicePage result and update servicePageData
watchEffect(() => {
  if (hrPage.value?.getHRPage) {
    servicHRData.value = {
      ...servicHRData.value,
      ...hrPage.value?.getHRPage,
    };
  }
});

const { mutate: setHRPage, loading: setHRPageLoading } = useMutation(SET_HR);

const handleSubmit = async () => {
  const data = { ...servicHRData.value };
  delete data.metaTags;
  window.scrollTo({
    top: 120,
    behavior: "smooth",
  });
  try {
    await setHRPage({ data });
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    toast.error(error.message);
  }
};

const loading = computed(
  () => setHRPageLoading.value || getHRPageLoading.value
);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.services-hr");
});
watch(
  isRTL,
  () =>
    (document.title = i18n("routes.app") + " | " + i18n("routes.services-hr"))
);
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->

      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <!-- Hero section title -->
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-base font-medium">
            {{ $t("website.services.hr.title") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("website.services.hr.description") }}
          </p>
        </div>

        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>

        <div
          class="h-screen w-full flex items-center justify-center absolute"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>

        <ServiceHRHeroParagraph />

        <ServiceGapAnalysis />

        <ServiceBusinessStrategeis />

        <ServiceManagmentSystem />

        <ServiceAssessmentCenter />

        <div class="mt-20 justify-end flex gap-2">
          <!-- <Button type="button" variant="outline-secondary" class="w-24 mr-1">
            {{ $t("common.cancel") }}
          </Button> -->
          <Button
            type="button"
            variant="primary"
            class="w-24"
            :disabled="loading"
            :isLoading="loading"
            @click="handleSubmit"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
