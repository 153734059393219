<template>
  <div>
    <div
      class="intro-y py-4 flex flex-wrap sm:flex-row sm:flex-nowrap justify-between items-center"
    >
      <div class="flex items-center gap-3">
        <DropdownMenu v-if="showSizeChanger">
          <DropdownMenuTrigger
            class="w-20 border border-slate-200 py-2 rounded-lg !ring-0 flex gap-3 items-center justify-center"
            >{{ limit }}
            <Icon
              class="text-xl text-slate-500"
              icon="iconamoon:arrow-down-2"
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              class="rtl:justify-end"
              @click="handleLimitClick(limitNumber)"
              v-for="limitNumber in limits"
              :key="limitNumber"
              :value="limitNumber"
            >
              {{ limitNumber }}</DropdownMenuItem
            >
          </DropdownMenuContent>
        </DropdownMenu>
        <span class="hidden min-[450px]:flex">
          {{ $t("common.show") }}
        </span>
      </div>
      <div class="flex items-center border-slate-200 border rounded-lg">
        <button
          class="sm:w-auto text-xs sm:text-sm p-3"
          :class="hasPrev ? ' cursor-pointer' : 'cursor-not-allowed'"
          :disabled="!hasPrev || disabled || loading"
          @click="emit('back')"
        >
          <Icon class="rtl:rotate-180" icon="solar:arrow-left-broken" />
        </button>
        <span
          class="block whitespace-nowrap text-xs sm:text-sm px-5 border-x-gray-200 border-x py-2.5"
          >{{ $t("common.page") }} {{ `${page}` }}</span
        >
        <button
          class="sm:w-auto text-xs sm:text-sm p-3"
          :class="hasNext ? ' cursor-pointer' : 'cursor-not-allowed'"
          :disabled="!hasNext || disabled || loading"
          @click="emit('next')"
        >
          <Icon class="rtl:rotate-180" icon="solar:arrow-right-broken" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useLocalize } from "@/locale/i18n";
import Button from "@/base-components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
// Localization
const { isRTL } = useLocalize();
const props = defineProps({
  page: {
    type: Number,
    default: 1,
  },
  totalNumberOfPages: {
    type: Number,
    default: 1,
  },
  pageSize: {
    type: Number,
    default: 10,
  },
  rowsNumber: {
    type: Number,
  },
  disabled: {
    type: Boolean,
  },
  showSizeChanger: {
    type: Boolean,
    default: true,
  },
  position: {
    type: String,
    default: "center",
  },
  hasNext: {
    type: Boolean,
  },
  hasPrev: {
    type: Boolean,
  },
  style: {
    type: String,
  },
  loading: {
    type: Boolean,
  },
});
let size = ref(props.pageSize);
const defaultLimits = ref([5, 10, 15, 20, 30, 40, 50]);

const emit = defineEmits(["back", "next", "size-changed"]);

const limit = computed({
  get: () => size.value,
  set: (val) => {
    size.value = val;
  },
});

const limits = computed(() => {
  const arr = [...new Set([...defaultLimits.value, props.pageSize])];
  return arr.sort((a, b) => {
    return a - b;
  });
});
const isFirstPage = computed(() => !props.hasPrev);
const isLastPage = computed(() => !props.hasNext);
const handleLimitClick = (limitNumber: number): void => {
  limit.value = limitNumber;
  emit("size-changed", limitNumber);
};
</script>

<style>
[dir="rtl"] .pagination {
  margin-right: auto !important;
  margin-left: unset !important;
}

[dir="rtl"] .pagination {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
