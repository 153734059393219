import gql from "graphql-tag";
import { HRPageFragment } from "../fragments/HRPage-fragment";

export const SET_HR = gql`
  mutation UPDATE_HR($data: HRPageInput!) {
    updateHRPage(data: $data) {
      ...HRPageFragment
    }
  }
  ${HRPageFragment}
`