import gql from "graphql-tag";
import { AboutUsPageFragment } from "../fragments/aboutus-fragments";

export const SET_ABOUTUS = gql`
  mutation UPDATE_ABOUTUS($data: AboutUsPageInput!) {
    updateAboutUsPage(data: $data) {
      ...AboutUsPageFragment
    }
  }
  ${AboutUsPageFragment}
`;
