import gql from "graphql-tag";
import { ImageFragment, MetaTagsFragment } from "./base-fragments";

export const ContentFAQPageFragments = gql`
  fragment ContentFAQPageFragments on ContentFAQPage {
    title
    paragraph
  }
`;

export const FAQPageFragments = gql`
  fragment FAQPageFragments on FAQPage {
    EnFAQPage {
      ...ContentFAQPageFragments
    }
    ArFAQPage {
      ...ContentFAQPageFragments
    }
    metaTags {
      ...MetaTagsFragment
    }
  }
  ${ContentFAQPageFragments}
  ${MetaTagsFragment}
`;

export const CategoriesFragments = gql`
  fragment CategoriesFragments on Categories {
    category {
      en
      ar
    }
    slug {
      en
      ar
    }
    id
  }
`;

export const CategoriesWithTotalRecordsFragments = gql`
  fragment CategoriesWithTotalRecordsFragments on CategoriesWithTotalRecords {
    category {
      ...CategoriesFragments
    }
    totalRecords
  }
  ${CategoriesFragments}
`;
