import gql from "graphql-tag";
import {
  ButtonFragment,
  ImageFragment,
  MetaTagsFragment,
  StatisticsFragment,
  CardsFragment,
} from "./base-fragments";

export const SettingsModelFragment = gql`
  fragment SettingsModelFragment on SettingsModel {
    ArTermsConditions
    EnTermsConditions
    ArPrivacyPolicy
    EnPrivacyPolicy
    email
    twitter
    phoneNumber
    facebook
    instagram
    linkedin
    address
  }
`;
