import { SocialType } from "@/types/website/home";

export interface Localized {
  ar: string;
  en: string;
}

interface Category {
  category: Localized;
  slug: Localized;
  metaTags: IMetaTags;
}
export const createNewCategory = (): Category => ({
  category: {
    en: "",
    ar: "",
  },
  slug: {
    en: "",
    ar: "",
  },
  metaTags: {
    ar: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
    en: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
  },
});
