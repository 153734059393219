import gql from "graphql-tag";

export const CREATE_MEMBER = gql`
  mutation CREATE_MEMBER($data: MemberCreate!) {
    createMember(data: $data) {
      id
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation UPDATE_MEMBER($data: MemberUpdate!, $memberId: String!) {
    updateMember(data: $data, memberId: $memberId) {
      id
    }
  }
`;

export const UPDATE_ME = gql`
  mutation UPDATE_ME($data: MemberUpdateMe!) {
    memberUpdateMe(data: $data) {
      id
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation DELETE_MEMBER($data: MemberDelete!) {
    deleteMembers(data: $data)
  }
`;

export const TOGGLE_MEMBER = gql`
  mutation TOGGLE_MEMBER($memberId: String!) {
    toggleMember(memberId: $memberId) {
      id
    }
  }
`;
