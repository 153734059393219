import FilterFieldDate from "./FilterFieldDate.vue";
import FilterFieldText from "./FilterFieldText.vue";
import FilterFieldDateRange from "./FilterFieldDateRange.vue";
import FilterFieldNumber from "./FilterFieldNumber.vue";
import FilterFieldList from "./FilterFieldList.vue";

const FilterFields = Object.assign(
  {},
  {
    FilterFieldDate,
    FilterFieldText,
    FilterFieldDateRange,
    FilterFieldNumber,
    FilterFieldList,
  }
);

export default FilterFields;
