<template>
  <div
    class="flex gap-2 bg-white rounded-md items-center px-3 border-slate-200 border"
  >
    <Icon icon="iconamoon:search-thin" class="text-xl text-gray-600" />
    <input
      :value="modelValue"
      @input="handleInputChange"
      @keydown.enter.exact.prevent="$emit('doFilter')"
      :placeholder="$t('common.search') + '...'"
      type="text"
      class="min-w-[6rem] placeholder:text-sm py-2 px-0 2xl:w-full shadow-none border-none !ring-0 text-sm"
    />
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(["doFilter", "update:modelValue"]);
const handleInputChange = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  emit("update:modelValue", value);
};

defineProps(["modelValue"]);
</script>
