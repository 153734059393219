import gql from "graphql-tag";
import {
  ButtonFragment,
  ImageFragment,
  MetaTagsFragment,
  StatisticsFragment,
  CardsFragment,
} from "./base-fragments";

export const JobsPageContentFragment = gql`
  fragment JobsPageContentFragment on JobsPageContent {
    title
    paragraph
  }
`;

export const JobsPageFragment = gql`
  fragment JobsPageFragment on JobsPage {
    en {
      ...JobsPageContentFragment
    }
    ar {
      ...JobsPageContentFragment
    }
  }
  ${JobsPageContentFragment}
`;

export const AllJobsFragments = gql`
  fragment AllJobsFragments on Jobs {
    slug
    jobOpeningId
    postingTitle
  }
`;
