import { SocialType } from "@/types/member";
export const getInitialMember = (): IMember => ({
  fullName: "",
  phoneNumber_1: "",
  linkedin: "",
  profilePic: {
    alt: "",
    caption: "",
    description: "",
    title: "",
    url: "",
  },
  title: "",
  ArBrief: "",
  EnBrief: "",
  role: "",
  email: "",
  order: "",
  metaTags: {
    ar: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
    en: {
      robots: "",
      canonical: "",
      description: "",
      social: [
        {
          title: "",
          description: "",
          image: { title: "", alt: "", caption: "", description: "", url: "" },
          socialNetwork: SocialType.Facebook,
        },
      ],
      title: "",
      keywords: [],
    },
  },
});
