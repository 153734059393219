<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { inject, ComputedRef, computed, ref } from "vue";
import { useLocalize } from "@/locale/i18n";

const { i18n } = useLocalize();
const handleAddItem = () => {
  const lastIndex =
    homePageData.value[accessor.value]?.services.cardsInput.length;
  const title = `${i18n("common.card")} ${lastIndex + 1}`;
  homePageData.value[accessor.value]?.services.cardsInput.push({
    title,
    paragraph: "",
    image: { alt: "", caption: "", description: "", title: "", url: "" },
    button: { text: "", link: "" },
  });
};

const handleRemoveItem = (index: number) => {
  homePageData.value[accessor.value]?.services.cardsInput.splice(index, 1);
};

// to handle v-model
const homePageData = inject<ComputedRef<IHomePage>>("homePageData")!;
const objAccessor = inject<ComputedRef<IHomeObject> | IHomeObject>(
  "objAccessor",
  "EnHomePage"
);

const accessor = computed<IHomeObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});
</script>

<template>
  <div class="my-20">
    <div class="mb-3 intro-y pb-4 mt-10">
      <h2 class="text-base font-medium">
        {{ $t("website.home.services.title") }}
      </h2>
      <p class="text-slate-400 text-xs mt-1 font-normal">
        {{ $t("website.home.services.description") }}
      </p>
    </div>
    <!--services Title & paragraph-->
    <div>
      <div class="grid-cols-2 gap-3 mt-2 sm:grid">
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.home.services.fields.title") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="homePageData[objAccessor].services.title"
          />
        </div>
        <div>
          <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
            {{ $t("website.home.services.fields.paragraph") }}</FormLabel
          >
          <FormInput
            id="crud-form-1"
            type="text"
            class="w-full"
            v-model="homePageData[objAccessor].services.paragraph"
          />
        </div>
      </div>
    </div>

    <!--About us Cards -->
    <div class="mt-4">
      <h4 class="font-medium">
        {{ $t("website.home.services.fields.cards") }}
      </h4>
      <DraggableList
        @removeItem="handleRemoveItem"
        @addItem="handleAddItem"
        :theArray="homePageData[objAccessor].services.cardsInput"
        class="mt-10"
      >
        <template v-slot="{ index }">
          <div class="w-full">
            <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("common.title") }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    homePageData[objAccessor].services.cardsInput[index].title
                  "
                />
              </div>
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("common.paragraph") }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    homePageData[objAccessor].services.cardsInput[index]
                      .paragraph
                  "
                />
              </div>
            </div>
            <!-- End of title and paragraph -->

            <div class="mt-3">
              <div :key="accessor">
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("website.home.services.fields.media") }}</FormLabel
                >
                <SeoImageInput
                  v-model="
                    homePageData[objAccessor].services.cardsInput[index].image
                  "
                />
              </div>
            </div>
            <!-- Call To Action -->
            <h4 class="font-medium mt-5">
              {{ $t("website.home.aboutUs.fields.callToAction") }}
            </h4>
            <div class="grid-cols-1 md:grid-cols-2 gap-3 mt-2 sm:grid">
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("common.name") }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    homePageData[objAccessor].services.cardsInput[index].button
                      .text
                  "
                />
              </div>
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("common.link") }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    homePageData[objAccessor].services.cardsInput[index].button
                      .link
                  "
                />
              </div>
            </div></div
        ></template>
      </DraggableList>
    </div>
    <!-- End About us call to Statistics  -->
  </div>
</template>
