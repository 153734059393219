<script setup lang="ts">
import { inject, ComputedRef, computed, ref } from "vue";
import { FormInput, FormLabel } from "@/base-components/Form";
import DraggableList from "@/base-components/DraggableList";
import { useLocalize } from "@/locale/i18n";
import FileUploader from "@/base-components/FileUploader";

const { i18n } = useLocalize();

const handleAddItem = () => {
  const lastIndex = homePageData.value[accessor.value]?.testimonials.length;
  const reviewerName = `${i18n("common.testimonials")} ${lastIndex + 1}`;
  homePageData.value[accessor.value]?.testimonials.push({
    review: "",
    reviewerName: "",
    reviewerTitle: "",
    reviewerImage: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
    companyImage: {
      alt: "",
      caption: "",
      description: "",
      title: "",
      url: "",
    },
  });
};

const handleRemoveItem = (index: number) => {
  homePageData.value[accessor.value]?.testimonials.splice(index, 1);
};

const homePageData = inject<ComputedRef<IHomePage>>("homePageData")!;
const objAccessor = inject<ComputedRef<IHomeObject> | IHomeObject>(
  "objAccessor",
  "EnHomePage"
);

const accessor = computed<IHomeObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});
</script>

<template>
  <div class="my-10 md:my-20">
    <div class="mb-3 intro-y pb-4 mt-10">
      <h2 class="text-base font-medium">
        {{ $t("website.home.testimonials.title") }}
      </h2>
      <p class="text-slate-400 text-xs mt-1 font-normal">
        {{ $t("website.home.testimonials.description") }}
      </p>
    </div>

    <!--About us call to Statistics  -->
    <div class="mt-4">
      <h4 class="font-medium">
        {{ $t("website.home.testimonials.fields.reviews") }}
      </h4>
      <DraggableList
        class="mt-4"
        name="reviewerName"
        @removeItem="handleRemoveItem"
        @addItem="handleAddItem"
        :theArray="homePageData[objAccessor].testimonials"
      >
        <template v-slot="{ index }">
          <div class="w-full">
            <div class="grid-cols-1 md:grid-cols-1 gap-3 mt-2 sm:grid">
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{ $t("website.home.testimonials.fields.review") }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="homePageData[objAccessor].testimonials[index].review"
                />
              </div>
            </div>
            <div class="grid-cols-2 gap-3 mt-3 sm:grid">
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{
                    $t("website.home.testimonials.fields.reviewerName")
                  }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    homePageData[objAccessor].testimonials[index].reviewerName
                  "
                />
              </div>
              <div>
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{
                    $t("website.home.testimonials.fields.reviewerTitle")
                  }}</FormLabel
                >
                <FormInput
                  id="crud-form-1"
                  type="text"
                  class="w-full"
                  v-model="
                    homePageData[objAccessor].testimonials[index].reviewerTitle
                  "
                />
              </div>
            </div>

            <div class="mt-3 grid-cols-2 gap-3 sm:grid">
              <div :key="accessor">
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{
                    $t("website.home.testimonials.fields.reviewerImage")
                  }}</FormLabel
                >
                <SeoImageInput
                  v-model="
                    homePageData[objAccessor].testimonials[index].reviewerImage
                  "
                />
              </div>

              <div :key="accessor">
                <FormLabel
                  class="!text-slate-400 text-xs font-medium mt-2"
                  htmlFor="crud-form-1"
                >
                  {{
                    $t("website.home.testimonials.fields.companyImage")
                  }}</FormLabel
                >
                <SeoImageInput
                  v-model="
                    homePageData[objAccessor].testimonials[index].companyImage
                  "
                />
              </div>
            </div></div
        ></template>
      </DraggableList>
    </div>
    <!-- End About us call to Statistics  -->
  </div>
</template>
