import gql from "graphql-tag";
import { BlogCategoryFragment } from "../fragments/blogs-categories-fragments";
import { MetaTagsFragment } from "@/lib/graphql/fragments/base-fragments";

export const GET_BLOG_CATEGORY = gql`
  query GET_BLOG_CATEGORY($categoryId: String!) {
    getCategory(categoryId: $categoryId) {
      ...BlogCategoryFragment
    }
  }
  ${BlogCategoryFragment}
`;

export const GET_BLOGS_CATEGORIES = gql`
  query GET_BLOGS_CATEGORIES(
    $pagination: AlgoliaPagination
    $filters: AlgoliaFilters
  ) {
    getCategories(pagination: $pagination, filters: $filters) {
      totalRecords
      category {
        ...BlogCategoryFragment
      }
    }
  }
  ${BlogCategoryFragment}
`;
