<script setup lang="ts">
import { onMounted, computed } from "vue";
import { useLocalize } from "@/locale/i18n";
import { columns, rows } from "./tableConfig";
import { GET_BLOGS_CATEGORIES } from "@/lib/graphql/queries/blogsCategories";
import { useLazyQuery, useMutation } from "@vue/apollo-composable";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import { DELETE_BLOG_CATEGORY } from "@/lib/graphql/mutations/blogsCategories";
import { usePagination } from "@/lib/pagination";

// Localization
const { i18n } = useLocalize();

type BlogCategoryApi = {
  getCategories: {
    category: IBlogCategory[];
    totalRecords: number;
  };
};

const {
  loading: fetchLoading,
  result,
  refetch,
  load,
} = useLazyQuery<BlogCategoryApi>(GET_BLOGS_CATEGORIES, null, {
  fetchPolicy: "no-cache",
  notifyOnNetworkStatusChange: true,
});

const { getPaginationVariables, pagination } = usePagination<BlogCategoryApi>(
  result,
  "getCategories",
  refetch
);

load(null, { pagination: getPaginationVariables() });

const { mutate: deleteCategory, loading: deleteLoading } =
  useMutation(DELETE_BLOG_CATEGORY);

const loading = computed<boolean>(
  () => fetchLoading.value || deleteLoading.value
);

const handleDelete = async (categoryId: string) => {
  // console.log(categoryId , 'its catId')
  await deleteCategory({ categoryId: categoryId });
  await refetch();
};

const blogCategories = computed<IBlogCategory[]>(
  () => result.value?.getCategories?.category || []
);

onMounted(async () => {
  document.title =
    i18n("routes.app") + " | " + i18n("routes.website-blogsCategories");
});
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <!-- Begin Faqs -->
        <!--services Title En and Ar -->

        <data-list
          tableTitle="routes.blogsCategories"
          createLink="website-blogsCategories-new"
          :rows="blogCategories"
          :columns="columns"
          :loading="loading"
          :withPagination="true"
          :pagination="pagination"
          class="!px-0 !mt-0"
        >
          <template #default="{ row }">
            <router-link
              class="flex items-center gap-2 whitespace-nowrap"
              :to="`website-blogsCategories/${row?.id}`"
            >
              <Icon icon="solar:square-top-down-broken" class="h-5 w-5" />
              <!-- Open -->
            </router-link>
            <DeleteModal @delete="handleDelete(row?.id)" />
          </template>
        </data-list>
      </div>
      <!-- END: Form Layout -->
    </div>
  </div>
</template>
